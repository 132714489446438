import React from 'react'
import { getReleaseVersionsForView } from 'api/api'
import ReleaseNotes from './ReleaseNotes'
import { IReleaseNotesResponse } from '@fragus/sam-types'
import { formatDateFieldValue } from 'utils/formatDate'
import './ReleaseNotes.css'
import { withReleaseVersions, IWithReleaseVersionsProps } from '../withReleaseVersions'

class ReleaseVersionsPage extends React.Component<IWithReleaseVersionsProps<IReleaseNotesResponse>> {
  render() {
    return (
      <div className="ReleaseNotes">
        <div className="ReleaseNotes__inner">
          <header className="ReleaseNotes__header">
            <div className="ReleaseNotes__header-info">
              <h1 className="ReleaseNotes__title">Release Notes</h1>
            </div>
          </header>
          <div className="ReleaseNotes__wrapper">
            {this.props.versions.map(({ version, releaseDate, releaseNotes }) => (
              <div key={version}>
                <header className="ReleaseNotes__release-header">
                  <span className="ReleaseNotes__release-version">{version}</span>
                  <h3>{formatDateFieldValue(new Date(releaseDate))}</h3>
                </header>
                <ReleaseNotes notes={releaseNotes} />
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }
}
export default withReleaseVersions<IReleaseNotesResponse>(ReleaseVersionsPage, getReleaseVersionsForView)

import React, { ChangeEvent } from 'react'
import { connect } from 'react-redux'
import { IRootState } from '../../../reducers/initialState'
import './PPPrices.css'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
} from '@material-ui/core'
import { IContractProviderResponse } from '@fragus/sam-types'
import { showError, showSuccess } from 'utils/toastify'
import {
  getFileUploadProgress,
  onboardWarranties,
  pricesExportUrl,
  uploadPrices,
  warrantyExternalPricesExportUrl,
  warrantyExternalPricesUpload,
  warrantyPricesExportUrl,
  warrantyPricesUpload,
} from '../../../api/api'
import { addUniqueIdToString } from '../../../utils/fileUpload'

interface IReducerProps {
  providerId: number
  providerDetails: IContractProviderResponse
}

interface IState {
  isWorking: boolean
  waitMessage: string
  successMsg: string
  uploadProcentage?: number
  isShowProgressWindow: boolean
}

interface IResponseData {
  message: string
}

type TapiUploadRequest = typeof warrantyExternalPricesUpload | typeof warrantyPricesUpload | typeof uploadPrices

class PPPrices extends React.Component<IReducerProps, IState> {
  public state: IState = {
    isWorking: false,
    waitMessage: '',
    successMsg: '',
    uploadProcentage: 10,
    isShowProgressWindow: false,
  }

  private progressCheckIntervals: NodeJS.Timer | undefined

  componentWillUnmount(): void {
    this.progressCheckIntervals && clearInterval(this.progressCheckIntervals)
  }
  componentDidCatch(): void {
    // Just in case
    this.progressCheckIntervals && clearInterval(this.progressCheckIntervals)
  }

  public render() {
    const { isWorking, waitMessage, uploadProcentage, isShowProgressWindow } = this.state
    const isChildProvider = this.props.providerDetails.parentProviderId

    return (
      <>
        <div className="TitleAndButtonsContainer">
          <h3>Subscription Agreements</h3>
          <div className="ButtonsContainer">
            <div className="PPPrices">
              {isWorking ? (
                <label className="ActionButton">...</label>
              ) : (
                <label className="ActionButton" onClick={this.downloadPricesClick}>{`Download prices`}</label>
              )}
            </div>
            {!isChildProvider && (
              <div className="PPPrices">
                {isWorking ? (
                  <div>
                    <label className="ActionButton">{waitMessage}</label>
                    {uploadProcentage ? (
                      <div>
                        <span className="ProgressBar__procentage">{uploadProcentage}%</span>
                        <LinearProgress variant="determinate" value={uploadProcentage > 100 ? 100 : uploadProcentage} />
                      </div>
                    ) : (
                      <LinearProgress className="ProgressBar__indeterminate" />
                    )}
                  </div>
                ) : (
                  <label htmlFor="price-upload" className="ActionButton">
                    Upload prices
                    <input
                      id="price-upload"
                      hidden={true}
                      accept={'.xlsx'}
                      onChange={this.handlePriceUploadClick}
                      type="file"
                    />
                  </label>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="TitleAndButtonsContainer">
          <h3>Fragus group warranties</h3>
          <div className="ButtonsContainer">
            {!isChildProvider && (
              <div className="PPPrices">
                {isWorking ? (
                  <label className="ActionButton">...</label>
                ) : (
                  <label
                    className="ActionButton"
                    onClick={this.handleOnboardWarrantiesClick}
                  >{`Onboard warranties (subscription)`}</label>
                )}
              </div>
            )}
          </div>
          <div className="ButtonsContainer">
            <div className="PPPrices">
              {isWorking ? (
                <label className="ActionButton">...</label>
              ) : (
                <label
                  className="ActionButton"
                  onClick={this.downloadWarrantyPricesClick}
                >{`Download subscription warranty prices`}</label>
              )}
            </div>
            {!isChildProvider && (
              <div className="PPPrices">
                {isWorking ? (
                  <label className="ActionButton">...</label>
                ) : (
                  <label htmlFor="warrantyprice-upload" className="ActionButton">
                    Upload subscription warranty prices
                    <input
                      id="warrantyprice-upload"
                      hidden={true}
                      accept={'.xlsx'}
                      onChange={this.handleWarrantyPriceUploadClick}
                      type="file"
                    />
                  </label>
                )}
              </div>
            )}
          </div>
          <div className="ButtonsContainer">
            <div className="PPPrices">
              {isWorking ? (
                <label className="ActionButton">...</label>
              ) : (
                <label
                  className="ActionButton"
                  onClick={this.downloadExternalWarrantyPricesClick}
                >{`Download dealer paid warranty prices`}</label>
              )}
            </div>
            {!isChildProvider && (
              <div className="PPPrices">
                {isWorking ? (
                  <label className="ActionButton">...</label>
                ) : (
                  <label htmlFor="externalwarrantyprice-upload" className="ActionButton">
                    Upload dealer paid warranty prices
                    <input
                      id="externalwarrantyprice-upload"
                      hidden={true}
                      accept={'.xlsx'}
                      onChange={this.handleExternalWarrantyPriceUploadClick}
                      type="file"
                    />
                  </label>
                )}
              </div>
            )}
          </div>
        </div>
        {isShowProgressWindow && this.renderProgressWindow()}
      </>
    )
  }

  private renderProgressWindow = () => {
    const { isWorking, waitMessage, successMsg, uploadProcentage } = this.state

    const strTitle: string = isWorking ? `Uploading Progress... ${uploadProcentage}%` : 'Uploading Done'

    const localStyles = {
      div: {
        paddingTop: '6px',
        paddingBottom: '6px',
      },
      center: {
        textAlign: 'center',
      },
    }

    return (
      <Dialog
        open={true}
        maxWidth="xs"
        disableEscapeKeyDown={false}
        disableBackdropClick={true}
        style={{ cursor: isWorking ? 'wait' : 'pointer' }}
      >
        <DialogTitle>{strTitle}</DialogTitle>

        {isWorking ? (
          <DialogContent>
            <div style={localStyles.div}>{waitMessage}</div>
            <div style={localStyles.div}>
              <b>Please wait for this to be completed, do not close this window until this is done.</b>
            </div>

            <div style={localStyles.div && localStyles.center}>
              <br />
              <br />
              <CircularProgress size={32} color="primary" />
            </div>
            <div style={localStyles.div && localStyles.center}>{`${uploadProcentage}%`}</div>
          </DialogContent>
        ) : (
          <DialogContent>{successMsg}</DialogContent>
        )}

        <DialogActions>
          <Button
            disabled={isWorking}
            variant="contained"
            color={isWorking ? 'secondary' : 'primary'}
            onClick={() => this.setState({ isShowProgressWindow: false })}
          >
            {'OK'}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  private handlePriceUploadClick = async (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({ isShowProgressWindow: true })

    await this.handleFileUpload({
      fileList: event.target.files,
      uploadFileName: 'prices',
      apiUploadRequest: uploadPrices,
      waitMessage:
        'Uploading and processing prices... This may take quite some time, up to many minutes, please be patient...',
      successMssage: 'Finished uploading Prices successfully.',
    })
  }

  private handleWarrantyPriceUploadClick = async (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({ isShowProgressWindow: true })

    await this.handleFileUpload({
      fileList: event.target.files,
      uploadFileName: 'warrantyprices',
      apiUploadRequest: warrantyPricesUpload,
      waitMessage: 'Uploading and processing warranty prices, please wait...',
      successMssage: 'Finished uploading Warranty Prices successfully.',
    })
  }

  private handleExternalWarrantyPriceUploadClick = async (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({ isShowProgressWindow: true })

    await this.handleFileUpload({
      fileList: event.target.files,
      uploadFileName: 'externalwarrantyprices',
      apiUploadRequest: warrantyExternalPricesUpload,
      waitMessage: 'Uploading and processing external warranty prices, please wait...',
      successMssage: 'Finished uploading External Warranty Prices successfully.',
    })
  }

  private handleFileUpload = async (params: {
    fileList: FileList | null
    uploadFileName: string
    apiUploadRequest: TapiUploadRequest
    waitMessage: string
    successMssage: string
  }) => {
    const { fileList, uploadFileName, apiUploadRequest, waitMessage, successMssage } = params
    const { providerId } = this.props

    if (fileList && fileList.length === 1) {
      const formData = new FormData()
      const file: File = fileList[0]
      const uniqueName = addUniqueIdToString(file.name)
      formData.append(uploadFileName, file, uniqueName)
      this.setState({ isWorking: true, waitMessage: waitMessage })
      try {
        // Ask the server for upload status
        this.trackUploadStatus(providerId, uniqueName)
        const response: Response = await apiUploadRequest(providerId, formData)
        this.handleApiReponse(response, successMssage)
      } catch (error) {
        this.setState({ isWorking: false, waitMessage: '' })
        showError((error as Error).toString())
      }
    }
  }

  private trackUploadStatus = async (providerId: number, fileName: string) => {
    const interval = setInterval(async () => {
      if (this.state.isWorking) {
        const progress = await getFileUploadProgress(providerId, fileName)
        if (progress.data || progress.data === 0) {
          this.setState({ uploadProcentage: progress.data })
        }
      } else {
        this.handleUploadStopped(interval)
      }
    }, 10000)

    this.progressCheckIntervals = interval

    // We clear this interval after 30 minutes
    setTimeout(() => {
      this.handleUploadStopped(interval)
    }, 1800000)
  }

  private handleUploadStopped = (interval: NodeJS.Timer) => {
    this.setState({ uploadProcentage: 0 })
    clearInterval(interval)
  }

  private handleOnboardWarrantiesClick = async () => {
    this.setState({ isWorking: true, waitMessage: 'Onboarding warranties, please wait...' })
    const { providerId } = this.props
    try {
      const response: Response = await onboardWarranties(providerId)
      this.handleApiReponse(response)
    } catch (error) {
      this.setState({ isWorking: false, waitMessage: '' })
      showError((error as Error).toString())
    }
  }

  private downloadPricesClick = async () => {
    const { providerId } = this.props
    var a = document.createElement('a')
    a.href = pricesExportUrl(providerId)
    a.target = '_blank'
    a.rel = 'noopener noreferrer'
    a.click()
  }

  private downloadWarrantyPricesClick = async () => {
    const { providerId } = this.props
    var a = document.createElement('a')
    a.href = warrantyPricesExportUrl(providerId)
    a.target = '_blank'
    a.rel = 'noopener noreferrer'
    a.click()
  }

  private downloadExternalWarrantyPricesClick = async () => {
    const { providerId } = this.props
    var a = document.createElement('a')
    a.href = warrantyExternalPricesExportUrl(providerId)
    a.target = '_blank'
    a.rel = 'noopener noreferrer'
    a.click()
  }

  private handleApiReponse = async (response: Response, successMsg?: string) => {
    const parsedResponse: IResponseData = await response.json()
    const message = parsedResponse && parsedResponse.message

    const handleError = (e: string) => {
      this.setState({ isWorking: false, waitMessage: '' })
      showError(e)
    }

    if (response.status !== 200) {
      return handleError(`An error occured: ${message}`)
    }

    this.setState({ isWorking: false, waitMessage: '', successMsg: successMsg || message })
    showSuccess(successMsg || message)
  }
}

const mapStateToProps = (state: IRootState) => ({
  providerId: state.providerPage.providerId,
  providerDetails: state.providerPage.providerDetails,
})

export default connect(mapStateToProps)(PPPrices)

import { IContactPersonResponse } from '@fragus/sam-types'
import { addressValueDefaults, addressModel } from './address'
import IApiModel, { IApiField, createModel } from './apiModel'
import { requiredPrimaryKey, requiredString, emailPattern } from './validations'

// Default Values
export const contactPersonValueDefaults: IContactPersonResponse = {
  contactPersonId: -1,
  address: addressValueDefaults,
  email: '',
  name: '',
  phone: '',
}

// API Model
const model: IApiModel = {
  name: 'contactPersonModel',
  fields: [
    {
      name: 'contactPersonId',
      uiType: 'string',
      title: 'Contact Person ID',
      validation: [requiredPrimaryKey],
    },
    {
      name: 'address',
      uiType: 'string',
      title: 'Address',
      relation: 'Address',
    },
    {
      name: 'email',
      uiType: 'string',
      title: 'Email',
      validation: [requiredString, emailPattern],
    },
    {
      name: 'name',
      uiType: 'string',
      title: 'Name',
    },
    {
      name: 'phone',
      uiType: 'string',
      title: 'Phone Nr.',
      validation: [requiredString],
    },
  ],
  view: {},
}

const addressRelation = addressModel()

export const contactPersonModel = (view?: string | IApiField[], viewContext?: string) =>
  createModel<IContactPersonResponse>(view, viewContext, model, contactPersonValueDefaults, {
    Address: { name: 'Address', model: addressRelation },
  })

import React from 'react'
import { IContractTemplateResponse } from '@fragus/sam-types'
import './PPTemplateListClone.css'

interface IProps {
  templateList: IContractTemplateResponse[]
  handleClick: (event: any) => void
}

class PPTemplateListClone extends React.Component<IProps, {}> {
  public render() {
    const { templateList, handleClick } = this.props

    return (
      <div className="PPTemplateListClone">
        {templateList.length > 0 && (
          <main className="PPTemplateListClone__content">
            {templateList.map((template) => (
              <div className="PPTemplateListClone__template" key={template.id}>
                <div>
                  <input type="checkbox" onClick={handleClick} value={template.id} />
                  <label className="PPTemplateListClone__title" title={'External name: ' + template.description}>
                    {template.name} (ID: {template.id})
                  </label>
                </div>
                {template.properties.map((prop) => (
                  <div className="PPTemplateListClone__option" key={prop.id}>
                    <label>{prop.description}</label>
                    <br />
                  </div>
                ))}
              </div>
            ))}
          </main>
        )}
      </div>
    )
  }
}

export default PPTemplateListClone

import {
  IContractTemplateResponse,
  IGenericContractTemplateResponse,
  IProductContractTemplateResponse,
  PriceSource,
} from '@fragus/sam-types'
import IApiModel, { createModel, IApiField } from './apiModel'
import { autoexpertenConfig } from './autoexpertenContractTemplate'
import { priceSpecificationModel, priceSpecificationValueDefaults } from './priceSpecification'
import { termsOfServiceModel, termsOfServiceValueDefaults } from './termsOfService'
import { numberIsWhole, requiredBoolean, requiredNumber, requiredPrimaryKey, requiredString } from './validations'

export interface IContractTemplateRequestPatch extends IContractTemplateResponse {
  archived?: boolean
}

export interface IProductContractTemplateRequestPatch extends IProductContractTemplateResponse {
  archived?: boolean
}

export interface IContractTemplateRequestPost extends IContractTemplateResponse {}

export interface IProductContractTemplateRequestPost extends IProductContractTemplateResponse {}

export interface IGenericContractTemplateRequestPost extends IGenericContractTemplateResponse {}

export interface IGenericContractTemplateRequestPatch extends IGenericContractTemplateResponse {
  archived?: boolean
}

export interface IContractTemplateRequestGet {
  id?: number
  providerId?: number
}

export interface ITemplateConfig {
  lockedFields: string[]
}

export const templateConfigs: Record<PriceSource, ITemplateConfig | undefined> = {
  Pricelist: undefined,
  Autoexperten: autoexpertenConfig,
  V4PricingTool: undefined,
}

export const genericContractTemplateValueDefaults: IGenericContractTemplateResponse = {
  id: -1,
  providerId: null,
  calculationMethod: 200,
  description: '',
  formattedDescription: '',
  image: '',
  maxAge: 999,
  minAge: 0,
  defaultDuration: 0,
  defaultMileage: 0,
  minimumPaymentsCount: 0,
  name: '',
  options: [],
  properties: [],
  termsOfService: termsOfServiceValueDefaults,
  weight: 0,
  priceSource: 'Pricelist',
  serviceVariantName: '',
  serviceVariantId: '',
  totalPriceFactorInTenthOfCents: null,
  disableServiceInfoFile: false,
}

// Default Values
export const contractTemplateValueDefaults: IContractTemplateResponse = {
  ...genericContractTemplateValueDefaults,
  defaultMileage: 0,
  overdrivenCharge: {
    ...priceSpecificationValueDefaults,
    price: 0.12,
    priceInclVat: 0.15,
    vatShare: 0.03,
  },
  underdrivenCharge: {
    ...priceSpecificationValueDefaults,
    price: 0.08,
    priceInclVat: 0.1,
    vatShare: 0.02,
  },
  showOnWebcalc: true,
  v4ProductType: null,
  v4SupportedPaymentTypes: [],
  filesContainerAlongContract: null,
}

export const contractTemplateBaseValues: Partial<IContractTemplateResponse> = {
  overdrivenCharge: {
    ...priceSpecificationValueDefaults,
    price: 0.12,
    priceInclVat: 0.15,
    vatShare: 0.03,
  },
  underdrivenCharge: {
    ...priceSpecificationValueDefaults,
    price: 0.08,
    priceInclVat: 0.1,
    vatShare: 0.02,
  },
  showOnWebcalc: true,
}

export const productContractTemplateValueDefaults: IProductContractTemplateResponse = {
  ...genericContractTemplateValueDefaults,
  defaultHours: 0,
  defaultYearlyServices: 0,
}

// API Model
const model: IApiModel = {
  name: 'contractTemplateModel',
  fields: [
    {
      name: 'id',
      uiType: 'string',
      title: 'Template ID',
      validation: [requiredPrimaryKey],
    },
    {
      name: 'providerId',
      uiType: 'string',
      title: 'Provider ID',
      validation: [requiredNumber],
    },

    {
      name: 'minimumPaymentsCount',
      uiType: 'number',
      title: 'Minimum payments count',
      validation: [requiredNumber],
    },
    {
      name: 'name',
      uiType: 'string',
      title: 'Internal Name (used in pricefile)',
      validation: [requiredString],
    },
    {
      name: 'description',
      uiType: 'string',
      title: 'External Name (shown on website)',
      validation: [requiredString],
    },
    {
      name: 'formattedDescription',
      uiType: 'string',
      title: 'Formatted Description',
      validation: [],
    },
    {
      name: 'image',
      uiType: 'string',
      title: 'Image',
    },
    {
      name: 'showOnWebcalc',
      uiType: 'boolean',
      title: 'Available on Webcalc',
      validation: [requiredBoolean],
    },
    {
      name: 'calculationMethod',
      uiType: 'number',
      title: 'Calculation Method',
      validation: [
        requiredNumber,
        {
          message: 'Required field',
          type: '',
          valid: ({ value }) => {
            return value > 0
          },
        },
      ],
    },
    {
      name: 'priceSource',
      uiType: 'string',
      title: 'Price Source',
      validation: [requiredString],
    },
    {
      name: 'maxAge',
      uiType: 'number',
      title: 'Max. Age',
      validation: [
        requiredNumber,
        numberIsWhole,
        {
          message: 'Value must be higher than Min. Age',
          type: '',
          valid: ({ value, record }) => {
            const clone: any = record
            return value > clone.minAge
          },
        },
      ],
    },
    {
      name: 'minAge',
      uiType: 'number',
      title: 'Min. Age',
      validation: [
        requiredNumber,
        numberIsWhole,
        {
          message: 'Value must be lower than Max. Age',
          type: '',
          valid: ({ value, record }) => {
            const clone: any = record
            return value < clone.maxAge
          },
        },
      ],
    },
    {
      name: 'weight',
      uiType: 'Number',
      title: 'Weight',
    },
    {
      name: 'overdrivenCharge',
      uiType: 'number',
      title: 'Over Driven Charge',
      relation: 'PriceSpecification',
    },
    {
      name: 'underdrivenCharge',
      uiType: 'number',
      title: 'Under Driven Charge',
      relation: 'PriceSpecification',
    },
    {
      name: 'properties',
      uiType: 'string',
      title: 'Properties',
    },
    {
      name: 'options',
      uiType: 'string',
      title: 'Optionals',
    },
    {
      name: 'termsOfService',
      uiType: 'string',
      title: 'Terms of Service',
      relation: 'TermsOfService',
    },
  ],
  view: {
    providerPage: [
      {
        name: 'id',
      },
      {
        name: 'priceSource',
      },
      {
        name: 'name',
      },
      {
        name: 'description',
      },
    ],
  },
}

const termsOfServiceRelation = termsOfServiceModel()
const priceSpecificationRelation = priceSpecificationModel()

export const contractTemplateModel = (view?: string | IApiField[], context?: string) =>
  createModel<IContractTemplateResponse>(view, context, model, contractTemplateValueDefaults, {
    TermsOfService: { name: 'TermsOfService', model: termsOfServiceRelation },
    PriceSpecification: { name: 'PriceSpecification', model: priceSpecificationRelation },
  })

import React, { ChangeEvent } from 'react'
import { apiFileUploadRequest } from '../../../api/api'
import withStyledField, { IWithStyledFieldProps } from '../../../hoc/withStyledField/withStyledField'
import ActionButton from '../../ActionButton/ActionButton'
import './StripeFileUploadField.css'
import { IStripeFile } from '@fragus/sam-types'

export interface IUploadStripeFieldResponse {
  filename?: string
  filepath?: string
  success?: boolean
}

interface IProps extends IWithStyledFieldProps {
  accept?: string
  buttonLoadingText?: string
  buttonText?: string
  className?: string
  disabled?: boolean
  maxLength?: number
  name: string
  onBlur?: (e: any) => void
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  onUploadComplete?: () => void
  onUploadEnd?: () => void
  onUploadFail?: () => void
  onUploadStart?: () => void
  size?: number
  uploadDescription?: string
  url: string
  value?: string
  stripeFileObj: IStripeFile | undefined
  contextParent?: any
}

interface IState {
  loading: boolean
  stripeFile?: IStripeFile
}

// tslint:disable-next-line interface-name
export class StripeFileUploadField extends React.Component<IProps, IState> {
  public static defaultProps: Partial<IProps> = {
    accept: '.gif, .jpg, .jpeg, .png, .doc, .docx, .pdf',
    buttonLoadingText: 'Uploading...',
    buttonText: 'Upload',
    className: '',
    size: 32,
    uploadDescription: 'Uploaded with OmniCar FileUploadField',
  }

  public state: IState = {
    loading: false,
  }

  static getDerivedStateFromProps(nextProps: IProps, prevState: IState) {
    const { loading, stripeFile } = prevState
    const { stripeFileObj } = nextProps

    if (!stripeFile) {
      return {
        loading: loading,
        stripeFile: stripeFileObj,
      }
    } else {
      return null
    }
  }

  public render() {
    const { handleFileChange, handleUploadClick } = this
    const { loading } = this.state
    const {
      accept,
      buttonLoadingText,
      buttonText,
      className,
      disabled,
      name,
      onBlur,
      onChange,
      size,
      title,
    } = this.props

    return (
      <>
        <div>
          <label htmlFor={name} className={className}>
            {title}
          </label>

          <div>
            <img
              src={
                (this.state.stripeFile && this.state.stripeFile.linkUrl) ||
                'https://dummyimage.com/128x128/000/fff.png&text=Square+%3E+128x128'
              }
              width={'128px'}
              height={'128px'}
              alt=""
            />
          </div>
          <input
            className={className}
            disabled={loading || disabled}
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            size={size}
            type="text"
            value={this.state.stripeFile ? this.state.stripeFile.fileName : ''}
          />
          <div className="StripeFileUploadField__actions">
            <input hidden={true} accept={accept} onChange={handleFileChange} type="file" />
            <ActionButton
              className="StripeFileUploadField__uploadbutton"
              disabled={loading || disabled}
              onClick={handleUploadClick}
              text={loading ? buttonLoadingText : buttonText}
            />
          </div>
        </div>
      </>
    )
  }

  private handleUploadClick = (e: any) => {
    e.currentTarget.previousElementSibling.click()
  }

  private handleUploadStart = () => {
    const { onUploadStart } = this.props

    this.setState({
      loading: true,
    })

    onUploadStart && onUploadStart()
  }

  private handleUploadEnd = () => {
    const { onUploadEnd } = this.props

    this.setState({
      loading: false,
    })

    onUploadEnd && onUploadEnd()
  }

  private updateInputField = (file: IStripeFile) => {
    this.props.contextParent && this.props.contextParent.setFieldValue(this.props.name, file)
  }

  private handleUploadComplete = (response: IStripeFile) => {
    const { onUploadComplete } = this.props
    const { id } = response

    if (id) {
      onUploadComplete && onUploadComplete()
    }
  }

  private handleUploadFail = () => {
    const { onUploadFail } = this.props

    onUploadFail && onUploadFail()
  }

  private handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const { handleUploadComplete, handleUploadEnd, handleUploadFail, handleUploadStart } = this
    const { url } = this.props
    const fileList: FileList | null = event.target.files

    handleUploadStart()

    if (fileList) {
      const formData = new FormData()
      const file: File = fileList[0]

      formData.append('file', file)

      let response: any
      try {
        response = await apiFileUploadRequest(url, formData)
        response = (await response.json()) as IStripeFile
      } catch (error) {
        // tslint:disable-next-line:no-console
        console.error(error)
      }

      if (response) {
        if (response.id) {
          this.setState({
            loading: this.state.loading,
            stripeFile: response,
          })
          // this.props.setData(response)
          this.updateInputField(response)
          handleUploadComplete(response)
          this.forceUpdate()
        } else {
          // No filename = fail
          handleUploadFail()
        }
      } else {
        // No Response = fail
        handleUploadFail()
      }

      // This ensures a uploadEnd for the scope
      handleUploadEnd()
    } else {
      // No FileList = fail
      handleUploadFail()
      handleUploadEnd()
    }
  }
}

export default withStyledField(StripeFileUploadField, 'StripeFileUploadField')

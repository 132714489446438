import React, { ChangeEvent } from 'react'
import { IApiErrors } from '../../../apiModels/apiModel'
import { ICountry } from '@fragus/sam-types'
import FormSection from '../../FormSection/FormSection'
import SelectField, { TSelecetValue } from '../../Field/SelectField/SelectField'
import { BaseOption } from 'types'

interface IProps {
  edit?: boolean
  errors?: IApiErrors
  name?: string
  onBlur: (e: ChangeEvent<any>) => void
  onChange: (e: ChangeEvent<any>) => void
  onFieldChange?: (value: TSelecetValue) => void
  title?: string
  value: ICountry
  options: BaseOption[]
}

const Country: React.SFC<IProps> = ({
  edit,
  errors = {},
  name,
  title,
  value,
  onBlur,
  onChange,
  onFieldChange,
  options,
}) => (
  <div className="Country">
    <FormSection name="general" classNamePrefix="Country" title={''}>
      <SelectField
        className="Country__isoName"
        disabled={!edit}
        defaultValue="DK"
        errors={errors.isoName}
        locked={!edit}
        name={name ? `${name}.isoName` : 'isoName'}
        onBlur={onBlur}
        onChange={onChange}
        onFieldChange={onFieldChange}
        title={!title ? 'Country' : title}
        placeholder="Select a country"
        options={options}
        value={value.isoName}
      />
    </FormSection>
  </div>
)

export default Country

import IApiModel, { IApiField, createModel } from './apiModel'
import { IStripeDeclineChargeOn } from '@fragus/sam-types'
import { requiredBoolean } from './validations'

// Default Values
export const stripeDeclineChargeOnDefaults: IStripeDeclineChargeOn = {
  avsFailure: true, // default true
  cvcFailure: true, // default true
}
// API Model
const model: IApiModel = {
  name: 'declineChargeOn',
  fields: [
    {
      name: 'avsFailure',
      uiType: 'boolean',
      title: 'AVS failure',
      validation: [requiredBoolean],
    },
    {
      name: 'cvcFailure',
      uiType: 'boolean',
      title: 'CVC failure',
      validation: [requiredBoolean],
    },
  ],
  view: {},
}

export const stripeDeclineChargeOnModel = (view?: string | IApiField[], viewContext?: string) =>
  createModel<IStripeDeclineChargeOn>(view, viewContext, model, stripeDeclineChargeOnDefaults)

import { IContractOptionResponse, IProductContractTemplateResponse } from '@fragus/sam-types'
import { Field } from 'formik'
import React, { ChangeEvent } from 'react'
import { IApiErrors } from '../../../apiModels/apiModel'
import FileUploadField, { IOnDidUploadEvent } from '../../Field/FileUploadField/FileUploadField'
import NumberField from '../../Field/NumberField/NumberField'
import SelectField from '../../Field/SelectField/SelectField'
import StringField from '../../Field/StringField/StringField'
import FormSection from '../../FormSection/FormSection'
import ContractTemplateOptions from '../ContractTemplateOptions/ContractTemplateOptions'
import TermsOfService from '../TermsOfService/TermsOfService'

interface IProps {
  edit?: boolean
  errors?: IApiErrors
  name?: string
  onBlur: (e: ChangeEvent<any>) => void
  onChange: (e: ChangeEvent<any>) => void
  options: IContractOptionResponse[]
  title?: boolean | string
  value: IProductContractTemplateResponse
  templateDetails?: IProductContractTemplateResponse
}

interface IState {
  previewUrlForImage: string | null
}

// const ContractTemplate: React.SFC<IProps> = ({ edit, errors = {}, name, onBlur, onChange, options, title, value }) => (
/* tslint:disable jsx-no-lambda */
class ProductContractTemplate extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props)

    if (props.templateDetails) {
      this.state = {
        previewUrlForImage: !props.templateDetails.imageUrl ? null : props.templateDetails.imageUrl,
      }
    }
  }

  private onDidUpload = (event: IOnDidUploadEvent): void => {
    if (event && event.targetID) {
      switch (event.targetID) {
        case 'image':
          this.setState({ previewUrlForImage: event.response.url })
          break
      }
    }
  }

  public render() {
    const { edit, errors = {}, name, onBlur, onChange, options, title, value } = this.props

    const previewUrlForImage = this.state.previewUrlForImage
    const { globalBucket } = value.termsOfService
    const isEditDisabledInternalName: boolean = !edit

    return (
      <div className="ContractTemplate">
        <FormSection
          name="general"
          classNamePrefix="ContractTemplate"
          title={title ? (title === true ? 'Contract Template' : title) : ''}
        >
          <StringField
            className="ContractTemplate__name"
            disabled={isEditDisabledInternalName}
            errors={errors.name}
            locked={isEditDisabledInternalName}
            name={name ? `${name}.name` : 'name'}
            onBlur={onBlur}
            onChange={onChange}
            size={40}
            title="Internal Name (used in pricefile + etc, contact a dev if you REALLY want to update this)"
            value={value.name}
          />
          <StringField
            className="ContractTemplate__description"
            disabled={!edit}
            errors={errors.description}
            locked={!edit}
            name={name ? `${name}.description` : 'description'}
            onBlur={onBlur}
            onChange={onChange}
            size={80}
            title="External Name (shown on website)"
            value={value.description}
          />
          <SelectField
            defaultValue="Pricelist"
            className="ContractTemplate__priceSource"
            disabled={!edit}
            errors={errors.priceSource}
            locked={!edit}
            name={name ? `${name}.priceSource` : 'priceSource'}
            onBlur={onBlur}
            onChange={onChange}
            title="Price Source"
            value={value.priceSource}
            options={[
              { label: 'Price List', value: 'Pricelist' },
              { label: 'Autoexperten', value: 'Autoexperten' },
            ]}
          />
          <StringField
            className="ContractTemplate__formattedDescription"
            disabled={!edit}
            hidden={true}
            errors={errors.formattedDescription}
            locked={!edit}
            name={name ? `${name}.formattedDescription` : 'formattedDescription'}
            onBlur={onBlur}
            onChange={onChange}
            size={80}
            title="Formatted Description"
            value={value.formattedDescription || undefined}
          />
          <Field name={name ? `${name}.image` : 'image'} value={value.image}>
            {({ field, form }: any) => (
              <FileUploadField
                accept=".png, .jpg, .jpeg"
                className="ContractProvider__image"
                contextParent={form}
                disabled={!edit}
                errors={errors.image}
                locked={!edit}
                name={field.name}
                onBlur={onBlur}
                onChange={onChange}
                title="Image"
                serverURL={`contractproviders/${value.providerId}/files`}
                uploadStorage={'General-Storage'}
                onDidUpload={this.onDidUpload}
                previewURL={previewUrlForImage || undefined}
                value={field.value}
              />
            )}
          </Field>
          <SelectField
            defaultValue={null}
            className="ContractTemplate__calculationMethod"
            disabled={!edit}
            errors={errors.calculationMethod}
            locked={!edit}
            name={name ? `${name}.calculationMethod` : 'calculationMethod'}
            onBlur={onBlur}
            onChange={onChange}
            title="Calculation Method"
            value={value.calculationMethod}
            options={[
              { label: 'First Registration Date', value: 100 },
              { label: 'Contract Creation Date', value: 200 },
            ]}
          />
          <NumberField
            className="ContractTemplate__minimumPaymentsCount"
            disabled={globalBucket || !edit}
            errors={errors.minimumPaymentsCount}
            locked={globalBucket || !edit}
            min={0}
            name={name ? `${name}.minimumPaymentsCount` : 'minimumPaymentsCount'}
            onBlur={onBlur}
            onChange={onChange}
            subtitle="Minimum months before cancellation"
            title="Minimum payments count"
            value={value.minimumPaymentsCount === null ? undefined : value.minimumPaymentsCount}
          />
          <NumberField
            className="ContractTemplate__minAge"
            disabled={!edit}
            errors={errors.minAge}
            locked={!edit}
            max={99999}
            min={0}
            name={name ? `${name}.minAge` : 'minAge'}
            onBlur={onBlur}
            onChange={onChange}
            subtitle="Minimum age of the product (at contract creation) [months]"
            title="Min. Age"
            value={value.minAge}
          />
          <NumberField
            className="ContractTemplate__maxAge"
            disabled={!edit}
            errors={errors.maxAge}
            locked={!edit}
            max={99999}
            min={0}
            name={name ? `${name}.maxAge` : 'maxAge'}
            onBlur={onBlur}
            onChange={onChange}
            subtitle="Maximum age of the product (at contract creation) [months]"
            title="Max. Age"
            value={value.maxAge}
          />
          <NumberField
            className="ContractTemplate__defaultDuration"
            disabled={!edit}
            errors={errors.defaultDuration}
            locked={!edit}
            min={0}
            name={name ? `${name}.defaultDuration` : 'defaultDuration'}
            onBlur={onBlur}
            onChange={onChange}
            subtitle="Initial duration to set by default when selecting the template"
            title="Default Duration"
            value={value.defaultDuration || undefined}
          />
          <NumberField
            className="ContractTemplate__defaultHours"
            disabled={!edit}
            errors={errors.defaultHours}
            locked={!edit}
            min={0}
            name={name ? `${name}.defaultHours` : 'defaultHours'}
            onBlur={onBlur}
            onChange={onChange}
            subtitle="Initial hours to set by default when selecting the template"
            title="Default Hours"
            value={value.defaultHours}
          />
          <NumberField
            className="ContractTemplate__defaultYearlyServices"
            disabled={!edit}
            errors={errors.defaultHours}
            locked={!edit}
            min={0}
            name={name ? `${name}.defaultYearlyServices` : 'defaultYearlyServices'}
            onBlur={onBlur}
            onChange={onChange}
            subtitle="Initial yearly services to set by default when selecting the template"
            title="Default Yearly Services"
            value={value.defaultYearlyServices || undefined}
          />
          <NumberField
            className="ContractTemplate__weight"
            disabled={!edit}
            errors={errors.weight}
            locked={!edit}
            max={99999}
            min={0}
            name={name ? `${name}.weight` : 'weight'}
            onBlur={onBlur}
            onChange={onChange}
            title="Weight"
            value={value.weight}
          />
          <TermsOfService
            uploadUrl={`contractproviders/${value.providerId}/files`}
            edit={edit && !globalBucket}
            errors={errors.termsOfService}
            name={name ? `${name}.termsOfService` : 'termsOfService'}
            onBlur={onBlur}
            onChange={onChange}
            title={true}
            value={value.termsOfService}
          />
        </FormSection>
        <ContractTemplateOptions
          edit={edit}
          title="Template Properties"
          name={name ? `${name}.properties` : 'properties'}
          type="properties"
          value={value.properties}
          opposingValue={value.options}
          options={options}
        />
        <ContractTemplateOptions
          edit={edit}
          title="Template Options"
          name={name ? `${name}.options` : 'options'}
          type="options"
          value={value.options}
          opposingValue={value.properties}
          options={options}
        />
      </div>
    )
  }
}

export default ProductContractTemplate

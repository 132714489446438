import { IUserResponse } from '@fragus/sam-types'
import React from 'react'
import { connect, Dispatch } from 'react-redux'
import ActionTypes from '../../../actions/ActionTypes'
import { userListGet } from '../../../actions/providerPageActions'
import { IUserRequestGet, userModel } from '../../../apiModels/user'
import SortableTable from '../../../components/SortableTable/SortableTable'
import { IRootState } from '../../../reducers/initialState'
import './PPUserList.css'

const model = userModel('providerPage')

interface IReducerProps {
  providerId: number
  userList: IUserResponse[]
}

interface IActionProps {
  userListGet: (request: IUserRequestGet) => Promise<void>
}

// tslint:disable-next-line interface-name Because of a lint error we need to investigate
class PPUserList extends React.Component<IReducerProps & IActionProps, {}> {
  public componentDidMount() {
    const { providerId, userListGet } = this.props

    if (providerId) {
      userListGet({
        contractProviderId: providerId,
      })
    }
  }

  public componentDidUpdate(prevProps: IReducerProps & IActionProps) {
    const { providerId, userListGet } = this.props

    if (prevProps.userList === null || (prevProps.providerId !== providerId && providerId)) {
      userListGet({
        contractProviderId: providerId,
      })
    }
  }

  public render() {
    const { userList } = this.props

    return (
      <div className="PPUserList">
        <header className="PPUserList__header" />
        {userList && (
          <main className="PPUserList__content">
            <SortableTable primaryKey="userId" data={userList} model={model} />
          </main>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state: IRootState) => ({
  providerId: state.providerPage.providerId,
  userList: state.providerPage.userList,
})

const mapDispatchToProps = (dispatch: Dispatch<ActionTypes>) => ({
  userListGet: (request: IUserRequestGet) => dispatch(userListGet(request)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PPUserList)

import IApiModel, { IApiField, createModel } from './apiModel'
import { requiredString } from './validations'
import { ITranslationValueResponse } from '@fragus/sam-types'

export const model: IApiModel = {
  name: 'translationValue',
  fields: [
    {
      name: 'id',
      title: 'ID',
      uiType: 'number',
    },
    {
      name: 'locale',
      title: 'Locale',
      uiType: 'string',
      validation: [requiredString],
    },
    {
      name: 'value',
      title: 'Value',
      uiType: 'string',
      validation: [requiredString],
    },
  ],
  view: {},
}

export const translationValueDefaults: ITranslationValueResponse = {
  id: -1,
  locale: 'da-DK',
  value: '',
}

export const translationValueModel = (view?: string | IApiField[], viewContext?: string) =>
  createModel<ITranslationValueResponse>(view, viewContext, model, translationValueDefaults, {})

import {
  IAddressResponse,
  IContactPersonResponse,
  IContractOptionResponse,
  IContractProviderListItemResponse,
  IContractProviderResponse,
  IContractTemplateResponse,
  ICountryResponse,
  IReleaseNoteResponse,
  IReleaseVersionResponse,
  IStripeExternalAccountResponse,
  IStripeOwner,
  ITranslationResponse,
  IUserResponse,
  PriceSpecification,
} from '@fragus/sam-types'
import dot from 'dot-object'
import { isObject } from 'lodash'
import { ReactNode } from 'react'
import uiType from './uiType'

export type TApiRecord =
  | IAddressResponse
  | IContactPersonResponse
  | IContractOptionResponse
  | IContractProviderResponse
  | IContractTemplateResponse
  | ICountryResponse
  | PriceSpecification
  | IStripeExternalAccountResponse
  | IStripeOwner
  | IUserResponse
  | IReleaseVersionResponse
  | IReleaseNoteResponse
  | ITranslationResponse
  | IContractProviderListItemResponse

export interface IApiFieldRendererValue {
  value: Partial<TApiRecord> | null
  record: TApiRecord
  recordIndex: number
  key: string
}

export interface IApiCustomField {
  name: string
  renderer?: (cbArg: IApiFieldRendererValue) => ReactNode
  relation?: string
  title?: string
  uiType?: string
  validation?: IApiFieldValidation[]
  smaller?: boolean
  color?: string
  isShowCheckboxInProviderList?: boolean
  checkboxCaptionShort?: string
}

export interface IApiValidationObject {
  value: Partial<TApiRecord>
  field?: IApiField
  record?: TApiRecord
}

export interface IApiFieldValidation {
  message: string
  type: string
  valid: (validationObj: IApiValidationObject) => boolean
}

export interface IApiBaseField extends IApiCustomField {
  renderer: (cbArg: IApiFieldRendererValue) => ReactNode
  relation?: string
  title: string
  uiType: string
  validation: IApiFieldValidation[]
}

export type IApiField = IApiBaseField | IApiCustomField

export interface IApiFieldMap {
  [key: string]: IApiField
}

export interface IApiView {
  [key: string]: IApiField[]
}

export interface IApiModelView {
  fields: IApiField[]
  filters: {}
}

export default interface IApiModel {
  fields: IApiField[]
  name: string
  view: IApiView
}

const getField = (fieldName: string, fields: IApiField[]): IApiField | undefined => {
  let field

  fields.some((item: IApiField) => {
    if (item.name === fieldName) {
      field = item
    }

    return item.name === fieldName
  })

  return field
}

const fieldsExtend = (parentFields: IApiField[], childFields: IApiField[], viewContext: string) => {
  const fields: IApiField[] = []

  childFields.forEach((childField: IApiField) => {
    const parentField: IApiField | undefined = getField(childField.name, parentFields)

    if (parentField) {
      const dummyField: IApiField = Object.assign({}, parentField)
      const mergedField: IApiField = Object.assign(dummyField || {}, childField)
      const uiTypeName = mergedField.uiType

      if (uiTypeName) {
        const fieldUiType = uiType[uiTypeName]

        if (fieldUiType) {
          const uiContext = fieldUiType[viewContext]

          if (uiContext) {
            mergedField.renderer = uiContext.renderer
          }
        }
      }

      fields.push(mergedField)
    }
  })

  return fields
}

const getModelView = (model: IApiModel, view: string | IApiCustomField[] = '', viewContext: string) => {
  const { fields } = model
  let modelView

  if (view) {
    const viewFields = typeof view === 'string' ? model.view[view] : view

    if (viewFields) {
      modelView = fieldsExtend(fields, viewFields, viewContext)
    }
  } else {
    modelView = fields
  }

  return modelView
}

const getModelDefaultValues = <DefaultValue>(fields: IApiField[], valueDefaults: DefaultValue) => {
  const defaultValues: Partial<DefaultValue> = Object.assign({}, valueDefaults)

  fields.forEach((field: IApiField) => {
    const name = field.name
    const defaultValue = valueDefaults[name]

    if (defaultValue !== undefined) {
      defaultValues[name] = defaultValue
    }
  })

  return defaultValues
}

const createFieldMap = (fields: IApiField[]) => {
  const fieldMap: IApiFieldMap = {}

  fields.forEach((field: IApiField) => {
    const fieldName = field.name
    fieldMap[fieldName] = Object.assign({}, field)
  })

  return fieldMap
}
// TODO: change any to valid type
export const createModel = <DefaultValue>(
  view: string | IApiField[] = '',
  viewContext: string = 'grid',
  model: IApiModel,
  valueDefaults: DefaultValue,
  relationalModels?: any,
) => {
  // @ TODO - Get filters out of here!
  interface IApiRelationModelValue {
    name: string
    model: IModelInstance
  }

  interface IApiRelationModel {
    [key: string]: IApiRelationModelValue
  }

  interface IModelInstance {
    fields: IApiField[]
    fieldMap?: IApiFieldMap
    defaultValues: Partial<DefaultValue>
    getErrors: (values: TApiRecord) => void
    relations?: IApiRelationModel
  }

  const modelInstance: IModelInstance & any = {}

  const fields = getModelView(model, view, viewContext)

  if (fields) {
    const fieldMap = createFieldMap(fields)
    const defaultValues = getModelDefaultValues<DefaultValue>(fields, valueDefaults)
    const relations = relationalModels
    const getErrors = createValidateMethod(fieldMap, relations)

    modelInstance.fields = fields
    modelInstance.fieldMap = fieldMap
    modelInstance.defaultValues = defaultValues
    modelInstance.getErrors = getErrors
    modelInstance.relations = relations
  }

  return modelInstance
}

// export type ApiErrors = string[] & IApiErrors

export interface IApiErrors {
  [key: string]: any
}

const getFieldErrors = (value: TApiRecord, field: IApiField, record: TApiRecord) => {
  const { validation } = field
  const fieldErrors: string[] = []

  if (validation && validation.length) {
    validation.forEach((validation: IApiFieldValidation) => {
      const { message } = validation
      const valid = validation.valid({ value, field, record })

      if (!valid) {
        fieldErrors.push(message)
      }
    })
  }

  return fieldErrors
}

const isRecursivlyEmpty = (testObj: object) => {
  let isEmptyObject: boolean = true
  const dotTestObj: any = dot.dot(testObj)

  Object.keys(dotTestObj).forEach((key: string) => {
    const value: any = dotTestObj[key]

    if (isEmptyObject) {
      if (!isObject(value)) {
        isEmptyObject = false
      }
    }
  })

  return isEmptyObject
}

/**
 * getModelErrors
 * @param values Record values to be validated
 * @param errors Current error model
 * @param fieldMap Map of all model fields
 * @param relations Relational models map
 */
const getModelErrors = <IApiRelationModel>(
  values: TApiRecord,
  errors: IApiErrors | {},
  fieldMap: IApiFieldMap,
  relations?: IApiRelationModel,
) => {
  Object.keys(values).forEach((key: string) => {
    // Field can be relational or not
    const field = fieldMap[key]
    // Value can be object (relational or not) or not
    const value = values[key]

    if (field) {
      // Relational field
      if (field.relation && relations) {
        const relationalModel = relations[field.relation]
        const relationalErrors = getModelErrors(
          value,
          {},
          relationalModel.model.fieldMap,
          relationalModel.model.relations,
        )

        if (relationalErrors) {
          errors[key] = relationalErrors
        }
      } else {
        const fieldErrors = getFieldErrors(value, field, values)

        if (fieldErrors.length) {
          errors[key] = fieldErrors
        }
      }
    }
  })

  return errors
}

// @TODO Why do I have to use any?
const createValidateMethod = <IApiRelationModel>(fieldMap: IApiFieldMap, relations?: IApiRelationModel) => {
  return (values: TApiRecord) => {
    let errors = getModelErrors(values, {}, fieldMap, relations)

    // @TODO Do not just rinse off here - prevent empty objects from being
    // attached - I'm in a hurry though
    const isEmpty = isRecursivlyEmpty(errors)

    if (isEmpty) {
      errors = {}
    }

    return errors
  }
}

/*
  {
    "keyword": "required",
    "dataPath": "",
    "schemaPath": "#/required",
    "params": {
        "missingProperty": "cvrCode"
    },
    "message": "should have required property 'cvrCode'"
  },
  {
    "keyword": "required",
    "dataPath": "",
    "schemaPath": "#/required",
    "params": {
        "missingProperty": "postmarkFromEmail"
    },
    "message": "should have required property 'postmarkFromEmail'"
  },
  {
      "keyword": "pattern",
      "dataPath": ".contactPerson.email",
      "schemaPath": "#/properties/contactPerson/properties/email/pattern",
      "params": {
          "pattern": "^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"
      },
      "message": "should match pattern \"^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$\""
  }
*/

export interface IApiDebugParams {
  missingProperty: string
}

export interface IApiDebug {
  keyword: string
  dataPath: string
  schemaPath: string
  params: IApiDebugParams
  message: string
}

export interface IApiFormikError {
  [key: string]: string[]
}

export const createFormikErrors = (debug: IApiDebug[]) => {
  const formikErrors: IApiFormikError = {}

  debug.forEach((debugItem: IApiDebug) => {
    const { dataPath, message } = debugItem
    const { missingProperty } = debugItem.params

    // Since the JSONSchema4 sucks at delivering dataPath that is useful
    // I have to assume I can client side catch all patterns etc.. and
    // only have to catch missingProperty etc.. because as shown in example
    // debug output above root node fields do not have a name in dataPath
    // impossible to find a pattern validation errors field name if on root node
    if (missingProperty) {
      // Set name either by mangled dataPath or by missingProperty (read above)
      let name: string = missingProperty

      if (dataPath) {
        name = dataPath.replace(/^\./, '')
      }

      if (formikErrors[name]) {
        formikErrors[name] = [message]
      } else {
        formikErrors[name].push(message)
      }
    }
  })

  return formikErrors
}

import React from 'react'
import { IAccessKey } from '@fragus/sam-types'
import FormSection from 'components/FormSection/FormSection'
import { updateAccessKey, createAccessKey, regenerateApiKey } from 'api/api'
import { showSuccess, showWarning } from 'utils/toastify'
import { accessKeyDefaults, accessKeyModel } from 'apiModels/accessKey'
import { AccessKeyPermission } from '@fragus/sam-types'
import SelectField from 'components/Field/SelectField/SelectField'
import { Form, Formik, FormikErrors } from 'formik'
import { AccessKeyService } from '@fragus/sam-types'
import EmailField from 'components/Field/EmailField/EmailField'
import { getContractProviders } from 'api/api'
import { BaseOption } from 'types'
import { isEqual } from 'lodash'
import { FormControlLabel, Checkbox } from '@material-ui/core'
import StringField from 'components/Field/StringField/StringField'

interface IProps {
  currentAccessKey?: IAccessKey
  onReset: () => void
  updateList: (key: IAccessKey, keepCurrentKey?: boolean) => void
}

interface IState {
  accessKey: IAccessKey
  serviceOptions: BaseOption<number>[]
  contractProviderOptions: BaseOption<number>[]
  permissionOptions: BaseOption<number>[]
}

const getOptions = (serviceOption: boolean) => {
  const options = []
  const enumType = serviceOption ? AccessKeyService : AccessKeyPermission

  for (const key in enumType) {
    if (typeof AccessKeyPermission[key] === 'string') {
      options.push({ label: enumType[key], value: Number(key) })
    }
  }
  return options
}

const model = accessKeyModel()

const processError = (errors: FormikErrors<IAccessKey>, attribute: keyof IAccessKey): string[] | undefined => {
  let error = errors[attribute]

  if (error) {
    error = typeof error === 'string' ? [error] : error
  } else {
    error = undefined
  }

  return error
}

const getProvidersOptions = async () => {
  const res = await getContractProviders()
  const providers = (res.statusCode === 200 && res.data) || []
  return providers.map((provider) => ({
    label: `${provider.contractProviderId} - ${provider.administrativeName}`,
    value: provider.contractProviderId,
  }))
}

class AccessKeyForm extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      accessKey: props.currentAccessKey || accessKeyDefaults,
      serviceOptions: getOptions(true),
      contractProviderOptions: [],
      permissionOptions: getOptions(false),
    }
  }

  public async componentDidMount() {
    const contractProviderOptions = await getProvidersOptions()
    this.setState({ contractProviderOptions, serviceOptions: getOptions(true), permissionOptions: getOptions(false) })
  }

  public componentDidUpdate(prevProps: IProps) {
    const { currentAccessKey } = this.props

    if (
      prevProps.currentAccessKey?.accessKeyId !== currentAccessKey?.accessKeyId ||
      prevProps.currentAccessKey?.apiKey !== currentAccessKey?.apiKey
    ) {
      this.setState({
        accessKey: currentAccessKey || accessKeyDefaults,
      })
    }

    if (
      !isEqual(prevProps.currentAccessKey?.contractProviderIds.sort(), currentAccessKey?.contractProviderIds.sort())
    ) {
      this.setState({
        accessKey: currentAccessKey || accessKeyDefaults,
      })
    }
  }

  render() {
    const { onReset } = this.props
    const { accessKey, serviceOptions, permissionOptions, contractProviderOptions } = this.state
    const isNew = accessKey.accessKeyId <= 0
    return (
      <div className="AccessKeys__container">
        <button className="AccessKeys__regenbutton" onClick={this.handleRegenerateKey} disabled={isNew}>
          Regenerate apiKey
        </button>
        {!isNew && (
          <div onClick={this.copyToClipboardApiKey}>
            <StringField
              className="AccessKeys__api-key"
              name="apiKey"
              title="API key (click to copy)"
              value={accessKey.apiKey}
              disabled
            />
          </div>
        )}
        <Formik
          onSubmit={this.handleSubmit}
          initialValues={accessKey}
          enableReinitialize
          validate={(values) => model.getErrors(values)}
        >
          {({ dirty, isValid, handleChange, handleReset, handleBlur, handleSubmit, values, errors, setFieldValue }) => {
            return (
              <Form className="AccessKey" onSubmit={handleSubmit} onReset={handleReset}>
                <FormSection name="general" classNamePrefix="AccessKey">
                  <SelectField
                    defaultValue={values.keyPermission}
                    errors={processError(errors, 'keyPermission')}
                    className="AccessKey__permission"
                    name="keyPermission"
                    onChange={handleChange}
                    title="Permission"
                    value={values.keyPermission}
                    onBlur={handleBlur}
                    options={permissionOptions}
                  />
                  <SelectField
                    defaultValue={values.service}
                    errors={processError(errors, 'service')}
                    className="AccessKey__service"
                    name="service"
                    onChange={handleChange}
                    title="Service"
                    value={values.service}
                    onBlur={handleBlur}
                    options={serviceOptions}
                  />
                  <EmailField
                    className="AccessKey__representativeUserEmail"
                    name="representativeUserEmail"
                    errors={processError(errors, 'representativeUserEmail')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    size={35}
                    title="Representative user email"
                    value={values.representativeUserEmail}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.allProvidersAllowed}
                        name="allProvidersAllowed"
                        onChange={handleChange}
                        color="primary"
                      />
                    }
                    label="Allowed for all providers"
                  />

                  <SelectField
                    defaultValue={[]}
                    errors={processError(errors, 'contractProviderIds')}
                    className="AccessKey__contractProviders"
                    name="contractProviderIds"
                    onChange={(selectedOptions) => {
                      setFieldValue(
                        'contractProviderIds',
                        selectedOptions ? selectedOptions.map((option: BaseOption<number>) => option.value) : [],
                      )
                    }}
                    title="Contract Providers"
                    value={values.contractProviderIds}
                    onBlur={handleBlur}
                    isMulti
                    formik={false}
                    options={contractProviderOptions}
                  />
                </FormSection>
                <button className="AccessKeys__resetbutton" onClick={onReset}>
                  Cancel
                </button>
                <button className="AccessKeys__submitbutton" disabled={this.isDisabled(dirty, isValid)}>
                  {`${accessKey.accessKeyId > 0 ? 'Update' : 'Create'} key`}
                </button>
              </Form>
            )
          }}
        </Formik>
      </div>
    )
  }

  private copyToClipboardApiKey = () => {
    const { accessKey } = this.state
    navigator.clipboard.writeText(accessKey.apiKey)
    showSuccess("'API key' copied to clipboard")
  }

  private isDisabled = (dirty: boolean, isValid: boolean) => {
    // if accessKey is default, then it's a new key
    if (this.state.accessKey.accessKeyId <= 0) {
      return !isValid
    } else {
      return !(dirty || this.isProviderIdsDirty()) || !(isValid && this.isRepresentativeUserValid())
    }
  }

  private isRepresentativeUserValid = () => {
    const { representativeUserEmail } = this.state.accessKey
    return (representativeUserEmail && representativeUserEmail.length > 0) || representativeUserEmail === undefined
  }

  private isProviderIdsDirty = () => {
    const { currentAccessKey: originKey } = this.props
    const { accessKey: currentKey } = this.state
    return !isEqual(originKey?.contractProviderIds.sort(), currentKey.contractProviderIds.sort())
  }

  private handleSubmit = async (values: IAccessKey) => {
    const { updateList } = this.props
    const apiRequest = values.accessKeyId > 0 ? updateAccessKey : createAccessKey
    const { statusCode, data } = await apiRequest(values)
    const keyFromResponse = (statusCode === 200 && data) || undefined

    if (keyFromResponse) {
      showSuccess(values.accessKeyId > 0 ? 'Updated' : 'Created')
      updateList(keyFromResponse)
    } else {
      showWarning('Something went wrong')
    }
  }

  private handleRegenerateKey = async () => {
    const { accessKey } = this.state
    const { statusCode, data } = await regenerateApiKey(accessKey.accessKeyId)
    if (statusCode === 200 && data) {
      showSuccess(`Regenerated (ID: ${data.accessKeyId}). New key: ${data.apiKey}`)
      this.props.updateList(data, true)
    } else {
      showWarning('Something went wrong')
    }
  }
}

export default AccessKeyForm

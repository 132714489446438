import { IContractProviderResponse, ICountryResponse, IVatLookupResponse, TIsoCountry } from '@fragus/sam-types'
import { Field, FieldProps } from 'formik'
import React, { ChangeEvent } from 'react'
import { IApiErrors } from '../../../apiModels/apiModel'
import ActionButton from '../../ActionButton/ActionButton'
import BooleanField from '../../Field/BooleanField/BooleanField'
import EmailField from '../../Field/EmailField/EmailField'
import FileUploadField, { IOnDidUploadEvent } from '../../Field/FileUploadField/FileUploadField'
import NumberField from '../../Field/NumberField/NumberField'
import StringField from '../../Field/StringField/StringField'
import VatLookupField from '../../Field/VatLookupField/VatLookupField'
import FormSection from '../../FormSection/FormSection'
import ContactPerson from '../ContactPerson/ContactPerson'
import Country from '../Country/Country'
import ParentProvider from '../ParentProvider/ParentProvider'
import './ContractProvider.css'
import DownloadButton from 'components/DownloadButton/DownloadButton'
import { BaseOption as ContractProviderParentOption } from 'types'
import { resolveCountryOptionsMap } from 'utils/country'
import { getContractProviders, getOfferExpirationDays } from '../../../api/api'
import RadioGroupField from '../../Field/RadioGroupField/RadioGroupField'
import ProviderPaymentGatewaysField from '../../ProviderPaymentGateways/ProviderPaymentGatewaysField'
import ProviderConnectAddToV4ButtonField from '../../ProviderConnectAddEditV4Dealer/ProviderConnectAddToV4ButtonField'

const t = (s: string) => s

enum WebTitleOptionValue {
  AdminOrCompanyTitle = 'nullSelected',
  EmptyTitle = 'emptySelected',
  CustomTitle = 'customSelected',
}

interface IProps {
  create?: boolean
  edit?: boolean
  errors?: IApiErrors
  name?: string
  onBlur: (e: ChangeEvent<any>) => void
  onChange: (e: ChangeEvent<any>) => void
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
  providerDetails: IContractProviderResponse
  title?: boolean | string
  value: IContractProviderResponse
  contextParent?: any
  countryList: ICountryResponse[]
}

interface IState {
  vatLookup: IVatLookupResponse | null
  isWebTitleStringEnabled: boolean
  webTitleRadioGroupValue: WebTitleOptionValue
  webTitleStringValue: string
  previewUrlForLogo: string | null
  previewUrlForWebLogoBanner: string | null
  previewUrlForWebLogoSquare: string | null
  previewUrlForTermsOfTradeFile: string | null
  termsOfTradeFileName: string | null
  parentOptions: ContractProviderParentOption[]
  offerExpirationDays?: number
  offerExpirationHours?: number
  generalConditions: string
  optionsConditions: string
}

// tslint:disable jsx-no-lambda
class ContractProvider extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props)

    if (props.providerDetails) {
      this.state = {
        vatLookup: null,
        isWebTitleStringEnabled: false,
        webTitleRadioGroupValue: WebTitleOptionValue.AdminOrCompanyTitle,
        webTitleStringValue: '',
        previewUrlForLogo: !props.providerDetails.logoUrl ? null : props.providerDetails.logoUrl,
        previewUrlForWebLogoBanner: !props.providerDetails.webLogoBannerUrl
          ? null
          : props.providerDetails.webLogoBannerUrl,
        previewUrlForWebLogoSquare: !props.providerDetails.webLogoSquareUrl
          ? null
          : props.providerDetails.webLogoSquareUrl,
        previewUrlForTermsOfTradeFile: !props.providerDetails.termsOfTradeRef
          ? null
          : props.providerDetails.termsOfTradeRef,
        termsOfTradeFileName: !props.providerDetails.termsOfTradeRef ? null : props.value.termsOfTradeRef,
        parentOptions: [],
        generalConditions: props.providerDetails.generalConditions,
        optionsConditions: props.providerDetails.generalConditions,
      }
    }
  }

  async componentDidMount() {
    const { contractProviderId } = this.props.providerDetails
    const response = await getContractProviders()

    if (response.data) {
      const allParentOptions = response.data
        .filter((p) => !p.parentProviderId)
        .map((p) => ({
          value: p.contractProviderId,
          label: `#${p.contractProviderId} - ${p.administrativeName}`,
        }))

      const parentOptions =
        contractProviderId > 0 ? allParentOptions.filter((p) => p.value !== contractProviderId) : allParentOptions

      this.setState({ parentOptions })
    }
    this.getOfferExpirationDays()
    this.initWebTitleElements()
  }

  componentDidUpdate(prevProps: Readonly<IProps>): void {
    if (
      (prevProps.edit && !this.props.edit) ||
      this.props.providerDetails.termsOfTradeRef !== prevProps.providerDetails.termsOfTradeRef
    ) {
      this.setState({
        previewUrlForTermsOfTradeFile: this.props.providerDetails.termsOfTradeRef,
        termsOfTradeFileName: this.props.value.termsOfTradeRef,
      })
    }
  }

  private initWebTitleElements = () => {
    const webTitle = this.props.value.webTitle

    let isWebTitleStringEnabled = false
    let webTitleRadioGroupValue = WebTitleOptionValue.AdminOrCompanyTitle
    let webTitleStringValue

    switch (webTitle) {
      case null:
        webTitleRadioGroupValue = WebTitleOptionValue.AdminOrCompanyTitle
        webTitleStringValue = ''
        break
      case '':
        webTitleRadioGroupValue = WebTitleOptionValue.EmptyTitle
        webTitleStringValue = ''
        break
      default:
        isWebTitleStringEnabled = true
        webTitleRadioGroupValue = WebTitleOptionValue.CustomTitle
        webTitleStringValue = this.props.value.webTitle || ''
    }
    this.setState({
      isWebTitleStringEnabled,
      webTitleRadioGroupValue,
      webTitleStringValue,
    })
  }

  private handleWebTitleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.setState({
      webTitleStringValue: event.target.value,
    })
    this.props.onChange(event)
  }

  private onWebTitleRadioGroupChange = (event: ChangeEvent<HTMLInputElement>) => {
    const option = event + ''

    switch (option) {
      case WebTitleOptionValue.AdminOrCompanyTitle:
        this.setState({
          webTitleStringValue: '',
          isWebTitleStringEnabled: false,
        })
        break
      case WebTitleOptionValue.EmptyTitle:
        this.setState({
          webTitleStringValue: '',
          isWebTitleStringEnabled: false,
        })
        break
      case WebTitleOptionValue.CustomTitle:
        this.setState({
          webTitleStringValue: this.props.value.webTitle || 'Title',
          isWebTitleStringEnabled: true,
        })
        break
      default:
        this.setState({ isWebTitleStringEnabled: false })
    }
  }

  private onDidUpload = (event: IOnDidUploadEvent): void => {
    if (event && event.targetID) {
      switch (event.targetID) {
        case 'logo':
          this.setState({ previewUrlForLogo: event.response.url })
          break
        case 'webLogoBanner':
          this.setState({ previewUrlForWebLogoBanner: event.response.url })
          break
        case 'webLogoSquare':
          this.setState({ previewUrlForWebLogoSquare: event.response.url })
          break
        case 'termsOfTradeRef':
          this.setState({
            previewUrlForTermsOfTradeFile: event.response.url,
            termsOfTradeFileName: this.props.value.termsOfTradeRef,
          })
          break
      }
    }
  }

  public render() {
    const { handleVatLookup, handleVatLookupReset } = this
    const { vatLookup, parentOptions } = this.state
    const { create, edit, errors = {}, name, title, value, providerDetails, onBlur, onChange } = this.props

    return (
      <div className="ContractProvider">
        {/* --- General Provider Info ---------------------------------------------------------------------- */}
        <FormSection
          name="general"
          classNamePrefix="ContractProvider"
          title={title ? (title === true ? 'General Provider Info' : title) : 'General Provider Info'}
        >
          <Field name={name ? `${name}.cvrCode` : 'cvrCode'} value={value.cvrCode}>
            {({ field, form }: FieldProps) => {
              return (
                <>
                  <VatLookupField
                    className="ContractProvider__cvrCode"
                    country={value[name ? `${name}.country` : 'country'].isoName}
                    disabled={!edit}
                    errors={errors.cvrCode}
                    locked={!edit}
                    name={field.name}
                    onBlur={onBlur}
                    onChange={onChange}
                    onLookupChange={handleVatLookup}
                    size={25}
                    title="CVR Code"
                    value={field.value}
                  />
                  {vatLookup && (
                    <div className="ContractProvider__vatlookup">
                      <div className="ContractProvider__vatlookup-text">{`${t('Found VAT register entry')}`}</div>
                      <ActionButton
                        className="ContractProvider__vatlookup-button"
                        onClick={() => {
                          const vatValues = vatLookup as any

                          const newFormValues: IContractProviderResponse = {
                            ...form.values,
                            cvrCode: vatValues.vatId,
                            administrativeName: vatValues.administrativeName,
                            contactPerson: {
                              ...form.values.contactPerson,
                              address: {
                                ...form.values.contactPerson.address,
                                address1: vatValues.address1,
                                address2: vatValues.address2 || '',
                                postalCode: vatValues.postal_code,
                                city: vatValues.city,
                              },
                            },
                          }

                          handleVatLookupReset()

                          form.setValues(newFormValues)
                        }}
                        text={`${t('Apply')}?`}
                      />
                    </div>
                  )}
                </>
              )
            }}
          </Field>
          <Field name={name || 'parentProviderId'} value={value.parentProviderId}>
            {({ field }: FieldProps) => (
              <ParentProvider
                edit={edit}
                errors={errors.parentProvider}
                name={field.name}
                onBlur={onBlur}
                onChange={onChange}
                title={false}
                value={field.value}
                optionsList={parentOptions}
                create={create}
              />
            )}
          </Field>
          <Field name={name ? `${name}.country` : 'country'} value={value.country}>
            {({ field, form }: FieldProps) => (
              <Country
                options={resolveCountryOptionsMap(this.props.countryList)}
                edit={create}
                errors={errors.country}
                name={field.name}
                onBlur={onBlur}
                onChange={onChange}
                onFieldChange={(value) => {
                  form.setFieldValue(`${name ? `${name}.country.isoName` : 'country.isoName'}`, value)

                  // change the country for contact person
                  form.setFieldValue(
                    `${
                      name ? `${name}.contactPerson.address.country.isoName` : 'contactPerson.address.country.isoName'
                    }`,
                    value,
                  )

                  if (providerDetails.contractProviderId < 1 && value === 'NO') {
                    form.setFieldValue(`${name ? `${name}.isUsingV4PricingTool` : 'isUsingV4PricingTool'}`, true)
                    form.values.isUsingV4PricingTool = true
                  } else {
                    form.setFieldValue(`${name ? `${name}.isUsingV4PricingTool` : 'isUsingV4PricingTool'}`, false)
                    form.values.isUsingV4PricingTool = false
                  }

                  // providerDetails.contractProviderId < 1
                  //   ? value === 'NO'
                  //     ? form.setFieldValue(`${name ? `${name}.isUsingV4PricingTool` : 'isUsingV4PricingTool'}`, true)
                  //     : form.setFieldValue(`${name ? `${name}.isUsingV4PricingTool` : 'isUsingV4PricingTool'}`, false)
                  //   : form.setFieldValue(`${name ? `${name}.isUsingV4PricingTool` : 'isUsingV4PricingTool'}`, false)

                  // form.setFieldValue(
                  //   `${name ? `${name}.isUsingV4PricingTool` : 'isUsingV4PricingTool'}`,
                  //   value === 'NO' || false,
                  // )

                  //form.values.isUsingV4PricingTool = value === 'NO' || false

                  // form.setFieldValue(
                  //   `${name ? `${name}.isUsingV4PricingTool` : 'isUsingV4PricingTool'}`,
                  //   value === 'NO' || false,
                  // )
                  // form.values.isUsingV4PricingTool = value === 'NO' || false
                }}
                title={'Provider Country'}
                value={field.value}
              />
            )}
          </Field>
          <span title="The full formal company/administrative name of the business">
            <StringField
              className="ContractProvider__administrativeName"
              disabled={!edit}
              errors={errors.administrativeName}
              locked={!edit}
              name={name ? `${name}.administrativeName` : 'administrativeName'}
              onBlur={onBlur}
              onChange={onChange}
              size={64}
              title="Company Name / Administrative Name"
              value={value.administrativeName}
            />
          </span>

          <ProviderConnectAddToV4ButtonField
            isoCountry={providerDetails.country.isoName as TIsoCountry}
            label={
              ['development', 'development_local', 'staging'].includes(process.env.REACT_APP_ENVIRONMENT || '')
                ? 'Connected V4 Dealer (do not affect on non live environments because of the config in API)'
                : 'Connected V4 Dealer ID - Name'
            }
            edit={(!create && edit) || false}
            providerId={providerDetails.contractProviderId}
            providerName={providerDetails.administrativeName}
          />

          <Field name={name ? `${name}.logo` : 'logo'} value={value.logo}>
            {({ field, form }: any) => (
              <FileUploadField
                accept=".png, .jpg, .jpeg"
                className="ContractProvider__logo"
                contextParent={form}
                disabled={!edit || create}
                errors={errors.logo}
                locked={!edit}
                name={field.name}
                onBlur={onBlur}
                onChange={onChange}
                title="Logo"
                serverURL={`contractproviders/${value.contractProviderId}/files`}
                uploadStorage={'General-Storage'}
                onDidUpload={this.onDidUpload}
                previewURL={this.state.previewUrlForLogo || undefined}
                value={field.value}
              />
            )}
          </Field>
          <Field name={name ? `${name}.termsOfTradeRef` : 'termsOfTradeRef'} value={value.termsOfTradeRef}>
            {({ field, form }: any) => (
              <>
                <FileUploadField
                  accept=".pdf"
                  className="ContractProvider__termsOfTradeRef"
                  contextParent={form}
                  disabled={!edit || create || !!value.parentProviderId}
                  errors={errors.termsOfTradeRef}
                  locked={!edit}
                  name={field.name}
                  onBlur={onBlur}
                  onChange={this.handleTermsOfTradeFile}
                  title="Terms of Trade"
                  onDidUpload={this.onDidUpload}
                  onDelete={this.handleDeleteTermsOfTradeFile}
                  canDelete={value.parentProviderId === null}
                  deleteLabel="Delete Terms of Trade file"
                  serverURL={`contractproviders/${value.contractProviderId}/files`}
                  value={field.value}
                  displayPrettyFileName={true}
                />
                {this.state.previewUrlForTermsOfTradeFile && field.value && (
                  <DownloadButton
                    fileRef={this.state.previewUrlForTermsOfTradeFile || this.props.providerDetails.termsOfTradeRef}
                    label="Download Terms of Trade"
                  />
                )}
              </>
            )}
          </Field>
        </FormSection>
        {/* -------------------------------------------------------------------------------------- */}

        {/* --- Options -------------------------------------------------------------------------- */}
        <FormSection name="options" classNamePrefix="ContractProvider" title="Options">
          {!!value.parentProviderId && (
            <BooleanField
              className="ContractProvider__visibleToOtherChildren"
              disabled={!edit}
              errors={errors.visibleToOtherChildren}
              locked={!edit}
              name={name ? `${name}.visibleToOtherChildren` : 'visibleToOtherChildren'}
              onBlur={onBlur}
              onChange={onChange}
              title="Visible to Other Sub/Child Providers"
              value={value.visibleToOtherChildren}
            />
          )}
          <BooleanField
            className="ContractProvider__webCalcEnabled"
            disabled={!edit}
            errors={errors.webCalcEnabled}
            locked={!edit}
            name={name ? `${name}.webCalcEnabled` : 'webCalcEnabled'}
            onBlur={onBlur}
            onChange={onChange}
            title="Enable Webcalc (Webshop web-page)"
            value={value.webCalcEnabled}
          />
          <span title="Enable auto settlement for paid off contracts">
            <BooleanField
              className="ContractProvider__autoSettlement"
              disabled={!edit}
              errors={errors.autoSettlement}
              locked={!edit}
              name={name ? `${name}.autoSettlement` : 'autoSettlement'}
              onBlur={onBlur}
              onChange={onChange}
              title="Enable Auto Settlement for paid off contracts"
              value={value.autoSettlement}
            />
          </span>
          <span title="Disable all (internal) Contract Templates (will still allow external warranties (Dealer Paid Warranties))">
            <BooleanField
              className="ContractProvider__disableContractTemplates"
              disabled={!edit}
              errors={errors.disableContractTemplates}
              locked={!edit}
              name={name ? `${name}.disableContractTemplates` : 'disableContractTemplates'}
              onBlur={onBlur}
              onChange={onChange}
              title="Disable all Contract Templates/Subscriptions (will still allow external contracts (Dealer Paid Warranties))"
              value={value.disableContractTemplates}
            />
          </span>

          <BooleanField
            className="ContractProvider__isForceAllowCustomContracts"
            disabled={!edit || !!value.parentProviderId}
            errors={errors.isForceAllowCustomContracts}
            locked={!edit}
            name={name ? `${name}.isForceAllowCustomContracts` : 'isForceAllowCustomContracts'}
            onBlur={onBlur}
            onChange={onChange}
            title='Force Enable "Free/Custom Contracts"-button (even if there are no (service) contract templates / only subscription warranty templates)'
            value={value.isForceAllowCustomContracts}
          />
          <BooleanField
            className="ContractProvider__productsEnabled"
            disabled={!edit}
            errors={errors.enableProducts}
            locked={!edit}
            name={name ? `${name}.productsEnabled` : 'productsEnabled'}
            onBlur={onBlur}
            onChange={(e: any) => {
              onChange(e)
            }}
            title="Enable Contracts For Product objects (and not only for Vehicles)"
            value={value.productsEnabled}
          />
          <BooleanField
            className="ContractProvider__isUsingV4PricingTool"
            disabled={!edit}
            errors={errors.isUsingV4PricingTool}
            locked={!edit}
            name={name ? `${name}.isUsingV4PricingTool` : 'isUsingV4PricingTool'}
            onBlur={onBlur}
            onChange={(e: any) => {
              onChange(e)
            }}
            title="Use ONLY V4PricingTool products and prices" // info text (any JustGO templates or pricelists will be ignored)
            value={value.isUsingV4PricingTool}
          />
          <BooleanField
            className="ContractProvider__isUseV4PTOnlyForSigning"
            disabled={!edit}
            errors={errors.isUseV4PTOnlyForSigning}
            locked={!edit}
            name={name ? `${name}.isUseV4PTOnlyForSigning` : 'isUseV4PTOnlyForSigning'}
            onBlur={onBlur}
            onChange={(e: any) => {
              onChange(e)
            }}
            title="Use ONLY V4PricingTool for signing contracts" //info text  (templates for contracts will still get fetched and used with whatever PriceSource they have)
            value={value.isUseV4PTOnlyForSigning}
          />
        </FormSection>
        {/* -------------------------------------------------------------------------------------- */}

        {/* --- Monetary ------------------------------------------------------------------------- */}
        <FormSection name="monetary" classNamePrefix="ContractProvider" title="Monetary">
          <NumberField
            className="ContractProvider__omnicarFee"
            disabled={!edit}
            errors={errors.omnicarFee}
            locked={!edit}
            max={100}
            min={0}
            name={name ? `${name}.omnicarFee` : 'omnicarFee'}
            onBlur={onBlur}
            onChange={onChange}
            size={4}
            title="Fragus Fee (in %)"
            value={value.omnicarFee}
          />
          <ProviderPaymentGatewaysField
            label={'Payment Gateways'}
            edit={(!create && edit) || false}
            paymentGateways={value.paymentGateways}
            providerId={providerDetails.contractProviderId || 0}
            providerName={providerDetails.administrativeName}
            callbackSetFieldValue={this.props.setFieldValue}
          />
        </FormSection>
        {/* -------------------------------------------------------------------------------------- */}

        {/* --- E-Mail --------------------------------------------------------------------------- */}
        <FormSection name="email" classNamePrefix="ContractProvider" title="E-Mail">
          <EmailField
            className="ContractProvider__postmarkFromEmail"
            disabled={!edit}
            errors={errors.postmarkFromEmail}
            locked={!edit}
            name={name ? `${name}.postmarkFromEmail` : 'postmarkFromEmail'}
            onBlur={onBlur}
            onChange={onChange}
            size={35}
            title="Postmark from Email"
            value={value.postmarkFromEmail}
          />
          <StringField
            className="ContractProvider__additionalEmails"
            disabled={!edit}
            errors={errors.additionalEmails}
            locked={!edit}
            name={name ? `${name}.additionalEmails` : 'additionalEmails'}
            onBlur={onBlur}
            onChange={onChange}
            size={50}
            title="Additional emails for mailing(expiration, activation, suspended, termination)"
            subtitle="Remember(separator ;): example1@fragus.com;example2@fragus.com"
            value={value.additionalEmails || undefined}
          />
          <BooleanField
            className="ContractProvider__sendWelcomeLetter"
            disabled={!edit}
            errors={errors.sendWelcomeLetter}
            locked={!edit}
            name={name ? `${name}.sendWelcomeLetter` : 'sendWelcomeLetter'}
            onBlur={onBlur}
            onChange={onChange}
            title="Send Email"
            value={value.sendWelcomeLetter}
            hidden={true}
          />
        </FormSection>
        {/* -------------------------------------------------------------------------------------- */}

        {/* --- Contact Info --------------------------------------------------------------------- */}
        <FormSection name="contact" classNamePrefix="ContractProvider" title="Contact Info">
          <StringField
            className="ContractProvider__contactFormUrl"
            disabled={!edit}
            errors={errors.contactFormUrl}
            locked={!edit}
            name={name ? `${name}.contactFormUrl` : 'contactFormUrl'}
            onBlur={onBlur}
            onChange={onChange}
            size={50}
            title="Contact URL"
            subtitle="Remember http(s)://"
            value={value.contactFormUrl}
          />
          <ContactPerson
            countryOptions={resolveCountryOptionsMap(this.props.countryList)}
            countryReadonly={true}
            edit={edit}
            errors={errors.contactPerson}
            name={name ? `${name}.contactPerson` : 'contactPerson'}
            onBlur={onBlur}
            onChange={onChange}
            title={false}
            value={value.contactPerson}
          />
        </FormSection>
        {/* -------------------------------------------------------------------------------------- */}

        {/* --- Internal Fragus Group information ------------------------------------------------ */}
        <FormSection name="contact" classNamePrefix="ContractProvider" title="Internal Fragus Group information">
          <StringField
            className="ContractProvider__fragusContactPersonName"
            disabled={!edit}
            errors={errors.fragusContactPersonName}
            locked={!edit}
            name={name ? `${name}.fragusContactPersonName` : 'fragusContactPersonName'}
            onBlur={onBlur}
            onChange={onChange}
            size={50}
            title="Fragus Contact person name"
            value={value.fragusContactPersonName || undefined}
          />
          <StringField
            className="ContractProvider__providerGroup"
            disabled={!edit}
            errors={errors.providerGroup}
            locked={!edit}
            name={name ? `${name}.providerGroup` : 'providerGroup'}
            onBlur={onBlur}
            onChange={onChange}
            size={50}
            title="Group"
            value={value.providerGroup || undefined}
          />
          <StringField
            className="ContractProvider__providerCategory"
            disabled={!edit}
            errors={errors.providerCategory}
            locked={!edit}
            name={name ? `${name}.providerCategory` : 'providerCategory'}
            onBlur={onBlur}
            onChange={onChange}
            size={50}
            title="Category"
            value={value.providerCategory || undefined}
          />
        </FormSection>
        {/* -------------------------------------------------------------------------------------- */}
        {/* --- Conditions --------------------------------------------------------------------- */}
        <FormSection name="conditions" classNamePrefix="ContractProvider" title="Conditions">
          <StringField
            className="ContractProvider__generalConditions"
            disabled={!edit}
            locked={!edit}
            name={name ? `${name}.generalConditions` : 'generalConditions'}
            onBlur={onBlur}
            onChange={onChange}
            title="General Conditions"
            value={value.generalConditions}
            asTextarea={true}
            maxLength={512}
          />
          <StringField
            className="ContractProvider__optionsConditions"
            disabled={!edit}
            locked={!edit}
            name={name ? `${name}.optionsConditions` : 'optionsConditions'}
            onBlur={onBlur}
            onChange={onChange}
            title="Options Conditions"
            value={value.optionsConditions}
            asTextarea={true}
            maxLength={512}
          />
        </FormSection>
        {/* -------------------------------------------------------------------------------------- */}
        {/* --- Web Styling, Theming & Customization --------------------------------------------- */}
        <FormSection
          name="general"
          classNamePrefix="Theming"
          title={
            title
              ? title === true
                ? 'Web Styling, Theming & Customization'
                : title
              : 'Web Styling, Theming & Customization'
          }
        >
          <FormSection
            name="general"
            classNamePrefix="WebTitle"
            title={title ? (title === true ? 'WebTitle' : title) : ''}
          >
            <span title="Select Web Title (The title to be shown at top left corner on the SAM-Admin website)">
              <RadioGroupField
                className="ContractProvider__webTitle_radion_group"
                disabled={!edit}
                errors={errors.webTitle_radion_group}
                locked={!edit}
                name={name ? `${name}.webTitle_radion_group` : 'webTitle_radion_group'}
                onBlur={onBlur}
                onChange={this.onWebTitleRadioGroupChange}
                title="Web Title (The title to be shown at top left corner on SAM-Admin website)"
                placeholder="Select title to be shown"
                options={[
                  {
                    label: 'Show the full company name: (' + value.administrativeName + ')',
                    value: WebTitleOptionValue.AdminOrCompanyTitle,
                  },
                  {
                    label: 'Show no title at all (--HIDE IT-- completely)',
                    value: WebTitleOptionValue.EmptyTitle,
                  },
                  {
                    label:
                      'Use custom title (entered below): (' + (!value.webTitle ? '--empty--' : value.webTitle) + ')',
                    value: WebTitleOptionValue.CustomTitle,
                  },
                ]}
                value={this.state.webTitleRadioGroupValue}
              />
            </span>

            <StringField
              className="ContractProvider__webTitle"
              disabled={!edit || !this.state.isWebTitleStringEnabled}
              errors={errors.webTitle}
              locked={!edit || !this.state.isWebTitleStringEnabled}
              name={'webTitle'}
              onBlur={onBlur}
              onChange={this.handleWebTitleChange}
              size={64}
              title={!this.state.isWebTitleStringEnabled ? '' : 'Custom Web Title'}
              value={this.state.webTitleStringValue}
            />
          </FormSection>
          {/* -------------------------------------------------------------------------------------- */}

          <span title="Enable White-BG-theme (Instead of Fragus/Blue-BG-theme) on SAM-Admin">
            <BooleanField
              className="ContractProvider__isUseWhiteBGOnWeb"
              disabled={!edit}
              errors={errors.isUseWhiteBGOnWeb}
              locked={!edit}
              name={name ? `${name}.isUseWhiteBGOnWeb` : 'isUseWhiteBGOnWeb'}
              onBlur={onBlur}
              onChange={onChange}
              title="Enable White-BG-theme"
              value={value.isUseWhiteBGOnWeb}
            />
          </span>
          <span title="Enable Web Banner Logo on SAM-Admin">
            <BooleanField
              className="ContractProvider__isShowLogoOnWeb"
              disabled={!edit}
              errors={errors.isShowLogoOnWeb}
              locked={!edit}
              name={name ? `${name}.isShowLogoOnWeb` : 'isShowLogoOnWeb'}
              onBlur={onBlur}
              onChange={onChange}
              title="Enable Web Banner Logo"
              value={value.isShowLogoOnWeb}
            />
          </span>
          <span title="The logo to be shown if you have selected the Enable Web Banner Logo (Think about that this logo will have to match the current theme used on the website)">
            <Field name={name ? `${name}.webLogoBanner` : 'webLogoBanner'} value={value.webLogoBanner}>
              {({ field, form }: any) => (
                <FileUploadField
                  accept=".png, .jpg, .jpeg"
                  className="ContractProvider__webLogoBanner"
                  contextParent={form}
                  disabled={!edit || create}
                  errors={errors.webLogoBanner}
                  locked={!edit}
                  name={field.name}
                  onBlur={onBlur}
                  onChange={onChange}
                  title="Web Logo Banner"
                  serverURL={`contractproviders/${value.contractProviderId}/files`}
                  uploadStorage={'Logo-Banner-Storage'}
                  onDidUpload={this.onDidUpload}
                  previewURL={this.state.previewUrlForWebLogoBanner || undefined}
                  value={field.value}
                />
              )}
            </Field>
          </span>
          <span title="A logo that is squared, having ALL SIDES OF EQUAL LENGTH">
            <Field name={name ? `${name}.webLogoSquare` : 'webLogoSquare'} value={value.webLogoSquare}>
              {({ field, form }: any) => (
                <FileUploadField
                  accept=".png, .jpg, .jpeg"
                  className="ContractProvider__webLogoSquare"
                  contextParent={form}
                  disabled={!edit || create}
                  errors={errors.webLogoSquare}
                  locked={!edit}
                  name={field.name}
                  onBlur={onBlur}
                  onChange={onChange}
                  title="Web Logo Square (ALL SIDES OF EQUAL LENGTH)"
                  serverURL={`contractproviders/${value.contractProviderId}/files`}
                  uploadStorage={'Logo-Square-Storage'}
                  onDidUpload={this.onDidUpload}
                  previewURL={this.state.previewUrlForWebLogoSquare || undefined}
                  value={field.value}
                />
              )}
            </Field>
          </span>
          <span title="Enable Parent's Styling in Webcalc / Webshop (This provider will inherit the theme/styling from its main/parent provider)">
            <BooleanField
              className="ContractProvider__inheritParentStylingWebcalc"
              disabled={!edit}
              errors={errors.inheritParentStylingWebcalc}
              locked={!edit}
              name={name ? `${name}.inheritParentStylingWebcalc` : 'inheritParentStylingWebcalc'}
              onBlur={onBlur}
              onChange={onChange}
              title="Enable Parent's Styling in Webcalc"
              value={
                value.parentProviderId && !!value!.inheritParentStylingWebcalc
                  ? value.inheritParentStylingWebcalc
                  : false
              }
              hidden={!value.parentProviderId}
            />
          </span>
        </FormSection>
      </div>
    )
  }

  private getOfferExpirationDays = async () => {
    const res = await getOfferExpirationDays()
    if (res.data) {
      this.setState({ offerExpirationDays: res.data, offerExpirationHours: res.data * 24 })
    }
  }

  private handleTermsOfTradeFile = (e: React.ChangeEvent<any>) => {
    const value = e.target.value
    if (!value || !value.trim() || (this.state.termsOfTradeFileName && value !== this.state.termsOfTradeFileName)) {
      this.setState({ previewUrlForTermsOfTradeFile: '', termsOfTradeFileName: '' })
    }
    this.props.onChange(e)
  }

  private handleDeleteTermsOfTradeFile = () => {
    const { setFieldValue } = this.props
    this.setState({ previewUrlForTermsOfTradeFile: '', termsOfTradeFileName: '' })
    setFieldValue('termsOfTradeRef', '')
  }

  private handleVatLookup = (vatLookup: IVatLookupResponse | null) => {
    this.setState({ vatLookup })
  }

  private handleVatLookupReset = () => {
    this.setState({ vatLookup: null })
  }
}

export default ContractProvider

import { IContractOptionResponse } from '@fragus/sam-types'
import { Field, FieldProps } from 'formik'
import React from 'react'
import { contractOptionModel } from '../../../apiModels/contractOption'
import getUniqueId from '../../../utils/uniqueId'
import ActionButton from '../../ActionButton/ActionButton'
import DataList from '../../DataList/DataList'
import SelectField from '../../Field/SelectField/SelectField'
import FormSection from '../../FormSection/FormSection'
import './ContractTemplateOptions.css'

export interface IContractTemplateOptionsPayload {
  options?: IContractOptionResponse[]
  properties?: IContractOptionResponse[]
}

interface IProps {
  title: string
  name: string
  type: string
  edit?: boolean
  options: IContractOptionResponse[]
  value: IContractOptionResponse[]
  opposingValue: IContractOptionResponse[]
}

interface IState {
  selectedOption: {
    label: string
    value: number
  } | null
  value: IContractOptionResponse[]
  opposingValue: IContractOptionResponse[]
}

// tslint:disable-next-line interface-name Because of a lint error we need to investigate
class ContractTemplateOptions extends React.Component<IProps, IState> {
  // private uniqueId: string = getUniqueId('ContractTemplateOptions')

  private model: any
  private uniqueId: string

  constructor(props: IProps) {
    super(props)

    this.state = {
      selectedOption: null,
      value: [],
      opposingValue: [],
    }

    this.uniqueId = getUniqueId('ContractTemplateOptions')

    this.model = contractOptionModel([{ name: 'description' }, { name: 'internalName' }])
  }

  static getDerivedStateFromProps({ value, opposingValue }: IProps) {
    return {
      value: value,
      opposingValue: opposingValue,
    }
  }

  public render() {
    const { getSelectOptions, model, handleRemove, handleSelectChange, uniqueId, handleAdd } = this
    const { edit, type, title, name } = this.props
    const { selectedOption, value } = this.state
    const selectOptions = getSelectOptions()

    // tslint:disable jsx-no-lambda
    return (
      <FormSection name={type} classNamePrefix="ContractTemplateOptions" title={title}>
        <Field name={name}>
          {({ field, form }: FieldProps) => (
            <>
              <input name={field.name} id={field.name} value={field.value} type="hidden" />
              <div className="ContractTemplateOptions">
                {edit && (
                  <header className="ContractTemplateOptions__header">
                    <SelectField
                      defaultValue={null}
                      formik={false}
                      className="ContractTemplateOptions__select"
                      name={`${uniqueId}-contractTemplateOptionsSelect`}
                      options={selectOptions}
                      onChange={handleSelectChange}
                      value={selectedOption && selectedOption.value}
                    />
                    <ActionButton
                      disabled={selectedOption === null ? true : false}
                      className="ContractTemplateOptions__addbutton"
                      onClick={() => handleAdd(field, form)}
                      text="Add"
                    />
                  </header>
                )}
                <main className="ContractTemplateOptions__content">
                  {value && value.length ? (
                    <DataList
                      actionColumns={[
                        {
                          name: 'remove',
                          renderer: ({ record }) => (
                            <>
                              {edit && (
                                <ActionButton
                                  className="ContractTemplateOptions__removebutton"
                                  onClick={() => handleRemove(record, field, form)}
                                />
                              )}
                            </>
                          ),
                        },
                      ]}
                      primaryKey="id"
                      model={model}
                      data={value}
                    />
                  ) : (
                    <div className="ContractTemplateOptions__nodata">No {title} added</div>
                  )}
                </main>
              </div>
            </>
          )}
        </Field>
      </FormSection>
    )
    // tslint:enable jsx-no-lambda
  }

  private handleSelectChange = (option: any) => {
    const selectedOption = option || null

    this.setState({ selectedOption })
  }

  private handleAdd = (field: any, form: any) => {
    const { getOptionById } = this
    const { selectedOption, value } = this.state

    if (selectedOption) {
      const option = getOptionById(selectedOption.value)

      if (option) {
        const newValue = [...value, ...[option]]

        form.setFieldValue(field.name, newValue)

        this.setState({ selectedOption: null })
      }
    }
  }

  // @TODO Using TApiRecord instead of any yields weird error - investigage
  private handleRemove = (record: any, field: any, form: any) => {
    const { value } = this.state

    const newValue = value.filter((item) => item.id !== record.id)

    form.setFieldValue(field.name, newValue)
  }

  private getSelectOptions = () => {
    const { isOptionAdded } = this
    const { options } = this.props
    const selectOptions: any = []

    if (options) {
      options.forEach((option) => {
        const isAdded = isOptionAdded(option)

        if (!isAdded) {
          const label = option.internalName ? `${option.description} (${option.internalName})` : option.description
          selectOptions.push({ label, value: option.id })
        }
      })
    }

    return selectOptions
  }

  private isOptionAdded = (checkOption: IContractOptionResponse) => {
    const { value, opposingValue } = this.state
    let isAdded = false

    if (value) {
      isAdded = value.some((option: IContractOptionResponse) => option.id === checkOption.id)
    }

    if (opposingValue && !isAdded) {
      isAdded = opposingValue.some((option: IContractOptionResponse) => option.id === checkOption.id)
    }

    return isAdded
  }

  private getOptionById = (id: number) => {
    const { options } = this.props
    let foundOption = null

    options.some((option: IContractOptionResponse) => {
      if (option.id === id) {
        foundOption = option
      }

      return option.id === id
    })

    return foundOption
  }
}

export default ContractTemplateOptions

import { IContractProviderResponse, IContractTemplateResponse } from '@fragus/sam-types'
import React, { KeyboardEvent, MouseEvent } from 'react'
import { connect, Dispatch } from 'react-redux'
import { IProviderPageState } from 'reducers/providerPage/providerPageInitialState'
import ActionTypes from '../../../actions/ActionTypes'
import { IProviderPageUpdate, providerPageUpdate, templateListGet } from '../../../actions/providerPageActions'
import { contractTemplateModel, IContractTemplateRequestGet } from '../../../apiModels/contractTemplate'
import { IRootState } from '../../../reducers/initialState'
import PPClone from '../PPClone/PPClone'
import './PPTemplateList.css'
import { Dialog, DialogContent } from '@material-ui/core'
import SortableTable from '../../../components/SortableTable/SortableTable'

const model = contractTemplateModel('providerPage')

interface IReducerProps {
  providerId: number
  templateId: number
  templateList: IContractTemplateResponse[]
  providerDetails: IContractProviderResponse
}

interface IActionProps {
  templateListGet: (request: IContractTemplateRequestGet) => Promise<void>
  providerPageUpdate: (props: IProviderPageState) => IProviderPageUpdate
}

interface IPPTemplateListState {
  showModal: boolean
}

// tslint:disable-next-line interface-name Because of a lint error we need to investigate
class PPTemplateList extends React.Component<IReducerProps & IActionProps, IPPTemplateListState> {
  public state = {
    showModal: false,
  }

  public componentDidMount() {
    const { providerId, templateListGet } = this.props

    if (providerId) {
      // @TODO - Need IContractTemplateRequestGet changed -
      templateListGet({
        providerId,
      })
    }
  }

  public componentDidUpdate(prevProps: IReducerProps & IActionProps) {
    const { providerId, templateListGet } = this.props

    if (prevProps.providerId !== providerId && providerId) {
      templateListGet({ providerId })
    }
  }

  public render() {
    const { templateList, providerDetails } = this.props
    const isChildProvider = providerDetails.parentProviderId
    const disableContractTemplates = providerDetails.disableContractTemplates

    const { showModal } = this.state

    return (
      <div className="PPTemplateList">
        <header className="PPTemplateList__header">
          {!isChildProvider && (
            <div className="PPTemplateList__features">
              <button
                className="PPTemplateList__createbutton"
                onClick={this.handleCreateMouse}
                onKeyUp={this.handleCreateKeyboard}
              >
                Create New Template
              </button>
              {this.props.providerDetails.productsEnabled && (
                <button
                  className="PPTemplateList__createbutton"
                  onClick={this.handleCreateForProductMouse}
                  onKeyUp={this.handleCreateForProductKeyboard}
                >
                  Create New Template For Products
                </button>
              )}

              <button className="PPTemplateList__clonebutton" onClick={this.handleClone}>
                Clone
              </button>
            </div>
          )}
        </header>

        <div className="PPTemplateList__header_sub">
          {disableContractTemplates && (
            <div>
              <div className="PPTemplateList__warning_icon" />
              <span className="PPTemplateList__warning_text">
                Templates are currently disabled (to activate them again, uncheck the "Disable all Contract Templates"
                checkbox in Provider settings)
              </span>
            </div>
          )}
        </div>

        <main className="PPTemplateList__content">
          <Dialog open={showModal} onClose={this.handleClone}>
            <DialogContent>
              <PPClone onCancel={this.handleClone} />
            </DialogContent>
          </Dialog>
          <SortableTable primaryKey="id" data={templateList} model={model} onRowClick={this.handleRowClick} />
        </main>
      </div>
    )
  }

  private handleCreateForProductMouse = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    event.preventDefault()

    this.props.providerPageUpdate({ route: 'product-template-create' })
  }

  private handleCreateForProductKeyboard = (event: KeyboardEvent<HTMLButtonElement>) => {
    // keyCode 13 is the enter key
    if (event.keyCode === 13) {
      this.props.providerPageUpdate({ route: 'product-template-create' })
    }
  }

  private handleCreateMouse = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    event.preventDefault()

    this.props.providerPageUpdate({ route: 'template-create' })
  }

  private handleCreateKeyboard = (event: KeyboardEvent<HTMLButtonElement>) => {
    // keyCode 13 is the enter key
    if (event.keyCode === 13) {
      this.props.providerPageUpdate({ route: 'template-create' })
    }
  }

  private handleClone = () => {
    this.setState({ showModal: !this.state.showModal })
  }

  private handleRowClick = (record: any) => {
    if (record && record.id) {
      const { id } = record

      this.props.providerPageUpdate({
        templateId: id,
        route: 'template-details',
      })
    }
  }
}

const mapStateToProps = (state: IRootState) => ({
  providerId: state.providerPage.providerId,
  templateId: state.providerPage.templateId,
  templateList: state.providerPage.templateList,
  providerDetails: state.providerPage.providerDetails,
})

const mapDispatchToProps = (dispatch: Dispatch<ActionTypes>) => ({
  templateListGet: (request: IContractTemplateRequestGet) => dispatch(templateListGet(request)),
  providerPageUpdate: (props: IProviderPageState) => dispatch(providerPageUpdate(props)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PPTemplateList)

import React, { ChangeEvent } from 'react'
import withStyledField, { IWithStyledFieldProps } from '../../../hoc/withStyledField/withStyledField'
import { getVatInformation } from '../../../api/api'
import { IVatLookupResponse } from '@fragus/sam-types'
import './VatLookupField.css'

interface IProps extends IWithStyledFieldProps {
  country: string
  className: string
  disabled?: boolean
  maxLength?: number
  hidden?: boolean
  name: string
  onBlur?: (e: any) => void
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  onLookupChange?: (vatLookup: IVatLookupResponse | null) => void
  size?: number
  value?: string
}

interface IState {}

class VatLookupField extends React.Component<IProps, IState> {
  private timeout: any

  public render() {
    const { className, disabled, hidden, maxLength, name, onBlur, size, value } = this.props
    const { handleChange } = this

    return (
      <input
        type="text"
        className={className}
        disabled={disabled}
        hidden={hidden}
        id={name}
        maxLength={maxLength}
        name={name}
        onBlur={onBlur}
        onChange={handleChange}
        size={size}
        value={value}
      />
    )
  }

  private handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { getVatInformation } = this
    const { value } = e.target

    this.props.onChange && this.props.onChange(e)

    // We don't want to overflow the getVatInformation method - so a
    // simple debounce is added to prevent this from happening
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      getVatInformation(value)
    }, 150)
  }

  private getVatInformation = async (vatId: string) => {
    const { country, onLookupChange } = this.props

    const vatInformation = await getVatInformation({ vatId, country })

    if (vatInformation && vatInformation.data && vatInformation.data.vatId) {
      onLookupChange && onLookupChange(vatInformation.data)
    } else {
      onLookupChange && onLookupChange(null)
    }
  }
}

export default withStyledField(VatLookupField, 'VatLookupField')

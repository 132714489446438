import { IContractProviderResponse, ICountryResponse } from '@fragus/sam-types'
import equal from 'fast-deep-equal'
import { Form } from 'formik'
import React from 'react'
import { connect, Dispatch } from 'react-redux'
import ActionTypes from '../../../actions/ActionTypes'
import {
  countryListGet,
  IProviderPageProviderDetailsReset,
  IProviderPageProviderIdUpdate,
  IProviderPageRouteUpdate,
  providerDetailsGet,
  providerDetailsReset,
  providerIdUpdate,
  providerPageRouteUpdate,
  providerPatch,
  providerPost,
  providerUpdateState,
} from '../../../actions/providerPageActions'
import { IApiErrors } from '../../../apiModels/apiModel'
import {
  contractProviderModel,
  contractProviderValueDefaults,
  IContractProviderRequestGet,
  IContractProviderRequestPatch,
  IContractProviderRequestPost,
} from '../../../apiModels/contractProvider'
import ActionButton from '../../../components/ActionButton/ActionButton'
import ContractProvider from '../../../components/Form/ContractProvider/ContractProvider'
import Omnik from '../../../components/Omnik/Omnik'
import { IRootState } from '../../../reducers/initialState'
import { getScroll, IScroll } from '../../../utils/getScroll'
import scrollDelta from '../scrollDelta'
import './PPProviderDetails.css'
import { deleteContractProvider } from 'api/api'
import AlertDialog from 'components/Dialog/AlertDialog'

interface IProps {
  create?: boolean
}

interface IReducerProps {
  providerDetails: IContractProviderResponse
  providerId: number
  countryList: ICountryResponse[]
  isActiveOutage: boolean
}

interface IActionProps {
  providerDetailsGet: (request: IContractProviderRequestGet) => Promise<void>
  providerDetailsReset: () => IProviderPageProviderDetailsReset
  providerPageRouteUpdate: (route: string) => IProviderPageRouteUpdate
  providerPatch: (request: IContractProviderRequestPatch) => Promise<void>
  providerStateUpdate: (providerId: string | number) => Promise<void>
  providerPost: (request: IContractProviderRequestPost) => Promise<void>
  countryListGet: () => Promise<void>
  providerIdUpdate: (id: number) => IProviderPageProviderIdUpdate
}

interface IState {
  edit: boolean
  providerDetails: IContractProviderResponse | null
  scroll: IScroll
  formSubmitEvent: IFormSubmitEvent | undefined
  deleteDialogOpen: boolean
  activeDialogOpen: boolean
  saveWithoutToTDialogOpen: boolean
}

const model = contractProviderModel()

interface IFormSubmitEvent {
  values: any
  resetForm: any
  encodeValues: any
}

class PPProviderDetails extends React.Component<IProps & IReducerProps & IActionProps, IState> {
  constructor(props: IProps & IReducerProps & IActionProps) {
    super(props)

    this.state = {
      edit: !!this.props.create,
      providerDetails: this.props.create ? contractProviderValueDefaults : null,
      scroll: { x: 0, y: 0 },
      formSubmitEvent: undefined,
      deleteDialogOpen: false,
      activeDialogOpen: false,
      saveWithoutToTDialogOpen: false,
    }
  }

  public async componentDidMount() {
    const { providerId, providerDetailsGet, countryListGet } = this.props

    if (providerId) await Promise.all([providerDetailsGet({ contractProviderId: providerId }), countryListGet()])

    document.addEventListener('scroll', this.handleScroll)
    this.handleScroll()
  }

  static getDerivedStateFromProps(nextProps: IProps & IReducerProps & IActionProps, prevState: IState) {
    const { providerDetails, create } = nextProps
    const { edit, providerDetails: prevProviderDetails } = prevState

    return create || edit || equal(providerDetails, prevProviderDetails) ? null : { edit: false, providerDetails }
  }

  public componentWillUnmount() {
    document.removeEventListener('scroll', this.handleScroll)
  }

  // tslint:disable jsx-no-lambda
  public render() {
    const self = this
    const { handleFormSubmit, handleFormReset, handleEdit, handleProviderStateUpdate, initValues } = this
    const { providerDetails, formSubmitEvent } = this.state
    const { countryList, isActiveOutage } = this.props

    return providerDetails !== null ? (
      <>
        <Omnik
          model={model}
          initialValues={initValues(providerDetails)}
          onSubmit={handleFormSubmit}
          onReset={handleFormReset}
          validate={(values) => {
            let errors: IApiErrors | {} = {}

            if (model) {
              errors = model.getErrors(values)
            }

            return errors
          }}
          enableReinitialize
        >
          {({ dirty, errors, handleBlur, handleChange, handleReset, handleSubmit, setFieldValue, isValid, values }) => {
            const { edit, scroll } = self.state
            const { create } = self.props
            const isTest = process.env.REACT_APP_ENVIRONMENT !== 'production'
            const providerIsInactive = this.props.providerDetails && this.props.providerDetails.state === 'Inactive'

            return (
              <Form className="PPProviderDetails" onSubmit={handleSubmit} onReset={handleReset}>
                <header
                  className={`PPProviderDetails__header ${
                    scroll.y > scrollDelta ? 'PPProviderDetails__header--sticky' : ''
                  } ${isActiveOutage && scroll.y > scrollDelta ? 'PPProviderDetails__header-padding-top' : ''}`}
                >
                  <div className="PPProviderDetails__info">
                    <h1 className="PPProviderDetails__title">
                      {create
                        ? '-- New Provider --'
                        : providerDetails.providerIdentifier + ' - ' + providerDetails.administrativeName}
                    </h1>
                  </div>
                  <div className="PPProviderDetails__actions">
                    {!edit && !create ? (
                      <>
                        {isTest && (
                          <ActionButton
                            className="PPProviderDetails__deletebutton"
                            onClick={this.handleOpenAlertDialog}
                            text="Delete"
                          />
                        )}
                        <ActionButton
                          className={
                            providerIsInactive
                              ? 'PPProviderDetails__activatebutton'
                              : 'PPProviderDetails__inactivatebutton'
                          }
                          onClick={() =>
                            providerIsInactive ? handleProviderStateUpdate() : this.handleOpenInactivationDialog()
                          }
                          text={providerIsInactive ? 'Activate' : 'Deactivate'}
                        />
                        <ActionButton className="PPProviderDetails__editbutton" onClick={handleEdit} text="Edit" />
                      </>
                    ) : (
                      <>
                        <button className="PPProviderDetails__resetbutton" type="reset">
                          Cancel
                        </button>
                        <button className="PPProviderDetails__submitbutton" disabled={!dirty || !isValid} type="submit">
                          Save
                        </button>
                      </>
                    )}
                  </div>
                </header>
                <main className="PPProviderDetails__content">
                  <ContractProvider
                    create={create}
                    edit={edit}
                    errors={errors}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    setFieldValue={setFieldValue}
                    value={values}
                    providerDetails={this.state.providerDetails || providerDetails}
                    countryList={countryList}
                  />
                </main>
              </Form>
            )
          }}
        </Omnik>
        <AlertDialog
          open={this.state.deleteDialogOpen}
          onCancel={this.handleCloseAlertDialog}
          onOk={this.handleDelete}
          titleText="Delete Provider?"
          mainText="Are you sure you want to delete this provider?"
          okText="Delete"
          cancelText="Close"
        />
        <AlertDialog
          open={this.state.activeDialogOpen}
          onCancel={this.handleCloseActiveDialog}
          onOk={this.handleProviderStateUpdate}
          titleText="Deactivate Provider?"
          mainText="Are you sure you want to deactivate this provider?"
          okText="Deactivate"
          cancelText="Close"
        />
        <AlertDialog
          open={this.state.saveWithoutToTDialogOpen}
          onOk={async () => {
            this.doSave(formSubmitEvent!.values, {
              resetForm: formSubmitEvent?.resetForm,
              encodeValues: formSubmitEvent?.encodeValues,
            })
          }}
          onCancel={() => {
            this.setState({ saveWithoutToTDialogOpen: false })
          }}
          titleText="Save without Terms of Trade?"
          mainText='The "Terms of Trade" PDF-file is missing, save provider settings anyway?'
          okText="Yes, save"
          cancelText="No, cancel"
        />
      </>
    ) : (
      <div />
    )
  }

  // tslint:enable jsx-no-lambda
  private initValues = (initialValues: IContractProviderResponse) => {
    let values = initialValues

    if (initialValues) {
      values = { ...initialValues }

      // Clean the image and terms of service paths
      const logo = initialValues.logo.split('/').pop() || ''
      const ref = initialValues.termsOfTradeRef.split('/').pop() || ''

      values.logo = logo
      values.termsOfTradeRef = ref
    }

    return values
  }

  private handleEdit = () => {
    const { edit } = this.state
    this.setState({ edit: !edit })
  }

  private handleProviderStateUpdate = async () => {
    this.props.providerStateUpdate(this.props.providerId || this.props.providerDetails.contractProviderId)
    this.setState({ activeDialogOpen: false })
  }

  private handleFormReset = () => {
    const { create, providerPageRouteUpdate } = this.props

    if (create) {
      providerPageRouteUpdate('provider')
    } else {
      this.setState({ edit: false })
    }
  }

  private handleFormSubmit = (values: any, { resetForm, encodeValues }: any) => {
    if ((values.termsOfTradeRef ?? '').trim() === '') {
      const formSubmitEvent: IFormSubmitEvent = { values, resetForm, encodeValues }
      this.setState({ formSubmitEvent: formSubmitEvent, saveWithoutToTDialogOpen: true })
    } else {
      this.doSave(values, {
        resetForm: resetForm,
        encodeValues: encodeValues,
      })
    }
  }

  private doSave = (values: any, { resetForm, encodeValues }: any) => {
    const { create } = this.props
    const edit = create ? true : !this.state.edit

    this.setState({ edit, saveWithoutToTDialogOpen: false })

    // --- Start -------------------------------------------
    // In future perhaps revamp this into a remapping function (if needed).
    let cleanValues = JSON.parse(JSON.stringify(values))
    switch (values.webTitle_radion_group) {
      case 'nullSelected':
        // cleanValues.webTitle = null          // Note: Doesn't appear as null over in API eh, or?
        cleanValues.webTitle = '__force-null__' // Note: webTitle makes difference between null and ''
        break
      case 'emptySelected':
        cleanValues.webTitle = ''
        break
    }
    delete cleanValues.webTitle_radion_group
    delete cleanValues.logoUrl
    delete cleanValues.webLogoBannerUrl
    delete cleanValues.webLogoSquareUrl
    // -----------------------------------------------------
    if (create) {
      this.props.providerPost(cleanValues)
    } else {
      this.props.providerPatch(cleanValues)
      const newValues = encodeValues(values)
      resetForm({ values: newValues })
    }
  }

  private handleScroll = () => {
    const scroll = getScroll()
    this.setState({ scroll })
  }

  private handleCloseActiveDialog = () => {
    this.setState({ activeDialogOpen: false })
  }

  private handleOpenInactivationDialog = () => {
    this.setState({ activeDialogOpen: true })
  }

  private handleCloseAlertDialog = () => {
    this.setState({ deleteDialogOpen: false })
  }

  private handleOpenAlertDialog = () => {
    this.setState({ deleteDialogOpen: true })
  }

  private handleDelete = async () => {
    const { providerId, providerDetailsReset, providerIdUpdate, providerPageRouteUpdate } = this.props
    await deleteContractProvider(providerId)
    providerDetailsReset()
    // set the current providerId to 0
    providerIdUpdate(0)
    providerPageRouteUpdate('provider')
  }
}

const mapStateToProps = (state: IRootState) => ({
  providerDetails: state.providerPage.providerDetails,
  providerId: state.providerPage.providerId,
  countryList: state.providerPage.countryList,
  isActiveOutage: !!state.outage,
})

const mapDispatchToProps = (dispatch: Dispatch<ActionTypes>) => ({
  providerDetailsGet: (request: IContractProviderRequestGet) => dispatch(providerDetailsGet(request)),
  providerDetailsReset: () => dispatch(providerDetailsReset()),
  providerPageRouteUpdate: (route: string) => dispatch(providerPageRouteUpdate(route)),
  providerPatch: (request: IContractProviderRequestPatch) => dispatch(providerPatch(request)),
  providerStateUpdate: (providerId: number) => dispatch(providerUpdateState(providerId)),
  providerPost: (request: IContractProviderRequestPost) => dispatch(providerPost(request)),
  countryListGet: () => dispatch(countryListGet()),
  providerIdUpdate: (id: number) => dispatch(providerIdUpdate(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PPProviderDetails)

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Paper } from '@material-ui/core'
import { TIsoCountry } from '@fragus/sam-types'
import {
  IAddNewV4DealerRequestFromClient,
  IAddNewV4DealerResponseToClient,
  ICheckIfProviderConnectedRequestFromClient,
  ICheckIfProviderConnectedResponseToClient,
  IConnectProviderToV4RequestFromClient,
  IConnectProviderToV4ResponseToClient,
  IDisconnectV4DealerFromProviderRequestFromClient,
  IDisconnectV4DealerFromProviderResponseToClient,
  IListV4DealersRequestFromClient,
  IListV4DealersResponseToClient,
} from '@fragus/sam-types/types/v4PricingTool/dealerAndProvider'
import React, { ChangeEvent, KeyboardEvent } from 'react'
import ActionButton from '../ActionButton/ActionButton'
import './index.css'
import {
  // KeyboardArrowLeftTwoTone as BackIcon,
  Check as CheckIcon,
  DeleteOutline as ClearIcon,
  SearchTwoTone as SearchIcon,
} from '@material-ui/icons'
import classNames from 'classnames'
import SortableTable, { createStandardTableButton, IAdditionalColumn } from 'components/SortableTable/SortableTable'
import { showError, showSuccess, showWarning } from 'utils/toastify'
import {
  v4PTAddProviderToV4,
  v4PTCheckIfProviderConnected,
  v4PTConnectProviderToV4Dealer,
  v4PTDiconnectV4DealerFromProvider,
  v4PTListV4Dealers,
} from '../../api/api'
import IApiModel from '../../apiModels/apiModel'
import { requiredNumber, requiredString } from '../../apiModels/validations'
import StringField from '../Field/StringField/StringField'
import { dealerIdAndNameToJSX } from '../ProviderConnectAddEditV4Dealer/ProviderConnectAddToV4ButtonField'

interface IOwnProps {
  isoCountry: TIsoCountry
  isOpen: boolean
  onClose: () => void
  samProviderId: number
  samProviderName: string
}

type TProps = IOwnProps

interface IState {
  isSearching: boolean
  v4DealerIdFromV4: number | null
  v4DealerNameFromV4: string
  isV4InfoUndefined: boolean
  dealerItem: null | TDealerItem[]
  searchString: string
}

type TDealerItem = { id: number; name: string }

const initialState: IState = {
  v4DealerIdFromV4: null,
  v4DealerNameFromV4: '',
  isV4InfoUndefined: true,
  dealerItem: null,
  isSearching: false,
  searchString: '',
}

export const model: IApiModel = {
  name: 'v4Dealers',
  fields: [
    {
      name: 'id', // Key of object.
      title: 'Dealer ID',
      uiType: 'number',
      validation: [requiredNumber],
    },
    {
      name: 'name', // Key of object.
      title: 'Dealer Name',
      uiType: 'string',
      validation: [requiredString],
    },
  ],
  view: {},
}

class ProviderConnectAddToV4Dialog extends React.Component<TProps, IState> {
  state = initialState

  componentDidMount = async () => {
    await this.fetchAndSetConnectedDealer()

    const isConnected: boolean = !!this.state.v4DealerIdFromV4
    if (!isConnected) {
      // If not connected at init. for this class, prefill the search field
      // with the provider name.
      this.setState({ searchString: this.props.samProviderName.trim() })
    }
  }

  render = () => {
    const { isOpen, onClose } = this.props
    const { isSearching } = this.state

    return (
      <Dialog
        className={classNames('ConnectProvider__dialog_container', isSearching && 'ConnectProvider__is_loading')}
        fullWidth={true}
        open={isOpen}
        onClose={onClose}
      >
        <DialogTitle>Edit Connected V4 Dealer{this.renderPartProviderAndDealer()}</DialogTitle>
        <DialogContent className={'ConnectProvider__dialog_content_container'}>
          {this.renderPartSearchV4DealerTable()}
          {this.renderPartAddNewProviderToV4()}
        </DialogContent>
        <DialogActions>
          <Button className={classNames('ConnectProvider__button_in_dialog')} variant="contained" onClick={onClose}>
            <CheckIcon className={'ConnectProvider__icon'} />
            OK
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  renderPartProviderAndDealer = (): JSX.Element => {
    const { v4DealerIdFromV4 } = this.state

    const isConnected: boolean = !!v4DealerIdFromV4

    return (
      <div>
        <Paper className="ConnectProvider__dialog_part_paper" elevation={1}>
          <>
            {this.renderSAMProviderInfo()}
            <div>
              <br />
              <label className={'Field__title SelectField__title'}>Is connected to:</label>
            </div>
            {this.renderV4DealerInfo()}
            <br />
            <div className="ConnectProvider__button_container">
              <ActionButton
                className="ConnectProvider__disconnect_provider_v4_button"
                disabled={!isConnected}
                type="button"
                onClick={this.disconnectV4Dealer}
                text={'Disconnect Provider from V4 Dealer'}
              />
            </div>
          </>
        </Paper>
      </div>
    )
  }

  renderSAMProviderInfo = (): JSX.Element => {
    const { samProviderId, samProviderName } = this.props

    return (
      <div>
        <label className={'Field__title SelectField__title'}>JustGO Provider ID - Name:</label>
        <b>
          {samProviderId} - {samProviderName}
        </b>
      </div>
    )
  }

  renderV4DealerInfo = (): JSX.Element => {
    const { v4DealerIdFromV4, v4DealerNameFromV4, isV4InfoUndefined } = this.state

    return (
      <Paper className={'ConnectProvider__dialog_part_subpaper'} elevation={1}>
        <label className={'Field__title SelectField__title'}>V4 Dealer ID - Name:</label>
        <b>{dealerIdAndNameToJSX(v4DealerIdFromV4, v4DealerNameFromV4, isV4InfoUndefined)}</b>
      </Paper>
    )
  }

  renderPartSearchV4DealerTable = (): JSX.Element => {
    const { isoCountry } = this.props
    const { isSearching, dealerItem, searchString, v4DealerIdFromV4 } = this.state

    const isConnectedToV4: boolean = !!v4DealerIdFromV4

    const tableButtons: IAdditionalColumn[] = [
      {
        title: '',
        processRecord: createStandardTableButton(
          'Connect',
          this.handleConnect,
          'ConnectProvider__dialog_connect_button',
        ),
      },
    ]

    let tableData: TDealerItem[] | null = null

    if (isSearching) {
      tableData = [{ id: 0, name: '-- Loading/Searching... --' }]
    } else {
      if (dealerItem && dealerItem.length > 0) {
        tableData = dealerItem
      } else {
        tableData = [{ id: 0, name: '-- Empty --' }]
      }
    }

    return (
      <Paper className="ConnectProvider__dialog_part_paper" elevation={1}>
        <label className={'Field__title SelectField__title'}>Search V4 Dealer:</label>

        <div className="ConnectProvider__dialog_search_container">
          <StringField
            className={isSearching ? 'ConnectProvider__is_loading' : ''}
            name={'searchString'}
            disabled={false}
            locked={false}
            size={36}
            value={searchString}
            placeholder={`Search V4 dealer (${isoCountry}) to connect to/with...`}
            onKeyDown={this.handleKeyPress}
            onChange={this.handleSearchFieldChange}
          />
          <div className="ConnectProvider__dialog_search_button_container">
            <Button
              className={classNames(
                'ConnectProvider__dialog_search_button',
                isSearching ? 'ConnectProvider__is_loading' : '',
              )}
              variant="contained"
              onClick={this.onSearch}
            >
              <SearchIcon className={'ConnectProvider__icon'} />
              Search
            </Button>
          </div>
          <div className="ConnectProvider__dialog_clear_button_container">
            <Button className="ConnectProvider__dialog_clear_button" variant="contained" onClick={this.onClear}>
              <ClearIcon className={'ConnectProvider__icon'} />
            </Button>
          </div>
        </div>
        <div className="ConnectProvider__dialog_sortable_table_container">
          <SortableTable
            customStyling={'ConnectProvider__dialog_content'}
            primaryKey="releaseVersionId"
            data={tableData}
            model={model}
            additionalColumns={!isConnectedToV4 && dealerItem && dealerItem.length ? tableButtons : null}
            defaultSortColumn={'dealerId'}
            defaultSortingOrder={'asc'}
          />
        </div>
      </Paper>
    )
  }

  renderPartAddNewProviderToV4 = (): JSX.Element => {
    return (
      <div>
        <Paper className="ConnectProvider__dialog_part_paper" elevation={1}>
          <label className={'Field__title SelectField__title'} style={{ marginLeft: '8px' }}>
            If corresponding Provider doesn't Exist in V4, add it to V4:
          </label>
          <div className="ConnectProvider__button_container">
            <ActionButton
              className="ConnectProvider__add_provider_to_v4_button"
              type="button"
              onClick={this.addProviderToV4}
              text={`Add this JustGO Provider to V4`}
            />
          </div>
        </Paper>
      </div>
    )
  }

  onSearch = async () => {
    const { isoCountry } = this.props
    const { searchString } = this.state

    const request: IListV4DealersRequestFromClient = {
      isoCountry: isoCountry,
      searchString: searchString,
    }

    this.setState({ isSearching: true, dealerItem: null })

    const response = await v4PTListV4Dealers(request)
    const reply: IListV4DealersResponseToClient | undefined = response.data

    this.setState({ isSearching: false, dealerItem: (reply?.dealerItem as TDealerItem[]) || null })
  }

  onClear = async () => {
    this.setState({ isSearching: false, dealerItem: null, searchString: '' })
  }

  handleKeyPress = async (event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const KEY_CODE = { enter: 13 }

    if (event.charCode === KEY_CODE.enter) {
      await this.onSearch()
    }
  }

  handleSearchFieldChange = (event: ChangeEvent<any>) => {
    const value = (event.target && event.target.value) || ''
    this.setState({ searchString: value })
  }

  fetchAndSetConnectedDealer = async () => {
    const { samProviderId, isoCountry } = this.props

    const request: ICheckIfProviderConnectedRequestFromClient = {
      isoCountry: isoCountry || 'NO',
      providerId: samProviderId,
    }
    this.setState({ isV4InfoUndefined: true, v4DealerNameFromV4: '', v4DealerIdFromV4: 0 })
    const response = await v4PTCheckIfProviderConnected(request)

    let v4DealerName = 'n/a'
    let v4DealerId: number | null = null

    if (response?.data) {
      const reply: ICheckIfProviderConnectedResponseToClient = response?.data
      v4DealerId = reply.v4DealerId
      v4DealerName = reply.v4DealerName || ''
    }

    this.setState({ isV4InfoUndefined: false, v4DealerNameFromV4: v4DealerName, v4DealerIdFromV4: v4DealerId })
  }

  handleConnect = async (dealerItem: any) => {
    if (!dealerItem || !dealerItem.id) {
      throw Error('handleConnect(..): dealerItem is empty')
    }

    const { isoCountry, samProviderId } = this.props
    const item = dealerItem as TDealerItem

    const request: IConnectProviderToV4RequestFromClient = {
      isoCountry: isoCountry,
      providerId: samProviderId,
      v4DealerId: item.id,
    }
    const response: any = await v4PTConnectProviderToV4Dealer(request)
    const reply: IConnectProviderToV4ResponseToClient = response.data

    if (reply?.isSuccess) {
      showSuccess('Successfully connected provider to V4 dealer')
      await this.fetchAndSetConnectedDealer()
    } else {
      showError('Failed connecting provider to V4 dealer')
    }
  }

  disconnectV4Dealer = async () => {
    const { isoCountry } = this.props
    const { v4DealerIdFromV4 } = this.state

    if (!v4DealerIdFromV4) {
      throw Error('disconnectV4Dealer(..): No v4DealerIdFromV4 ')
    }

    const request: IDisconnectV4DealerFromProviderRequestFromClient = {
      isoCountry: isoCountry,
      v4DealerId: v4DealerIdFromV4,
    }
    const response = await v4PTDiconnectV4DealerFromProvider(request)
    const reply: IDisconnectV4DealerFromProviderResponseToClient | undefined = response.data

    if (reply?.isSuccess) {
      showSuccess('Successfully disconnected provider')
      await this.fetchAndSetConnectedDealer()
    } else {
      showWarning('Failed disconnecting provider')
    }
  }

  addProviderToV4 = async () => {
    const { isoCountry, samProviderId } = this.props

    if (!samProviderId) {
      throw Error('addProviderToV4(..): No samProviderId ')
    }

    const request: IAddNewV4DealerRequestFromClient = { isoCountry: isoCountry, providerId: samProviderId }
    const response = await v4PTAddProviderToV4(request)
    const reply: IAddNewV4DealerResponseToClient | undefined = response.data

    if (reply?.isSuccess) {
      showSuccess('Successfully added provider to V4')
      await this.fetchAndSetConnectedDealer()
    } else {
      if (response?.statusCode === 200) {
        showWarning('' + (reply?.message || response?.data?.message))
      } else {
        showError('Failed adding provider to V4: ' + (reply?.message || response?.data?.message))
      }
    }
  }
}

export default ProviderConnectAddToV4Dialog

import React from 'react'
import { releaseVersionValueDefaults } from 'apiModels/releaseVersion'
import { getAllReleaseVersions, postReleaseVersion, patchReleaseVersion, publishReleaseVersion } from 'api/api'
import ReleaseVersion from 'components/Form/ReleaseVersion/ReleaseVersion'
import { formatDateFieldValue } from 'utils/formatDate'
import { IReleaseVersionResponse } from '@fragus/sam-types'
import ReleaseVersions from 'components/ReleaseNotePage/ReleaseVersions'
import ReleaseNotes from 'components/ReleaseNotePage/ReleaseNotes'
import { withReleaseVersions, IWithReleaseVersionsProps } from '../withReleaseVersions'

interface IState {
  currentVersion?: IReleaseVersionResponse
  showForm: boolean
}

type TApiRequest = typeof postReleaseVersion | typeof patchReleaseVersion

const handleApiRequest = (requestApi: TApiRequest) => async (
  releaseVersion: IReleaseVersionResponse,
): Promise<IReleaseVersionResponse | undefined> => {
  const { statusCode, data } = await requestApi({
    ...releaseVersion,
    releaseDate: formatDateFieldValue(new Date(releaseVersion.releaseDate)),
  })

  return statusCode === 200 && data ? data : undefined
}

class ReleaseVersionsPage extends React.Component<IWithReleaseVersionsProps<IReleaseVersionResponse>, IState> {
  constructor(props: IWithReleaseVersionsProps<IReleaseVersionResponse>) {
    super(props)
    this.state = { currentVersion: undefined, showForm: false }
  }

  render() {
    const { versions } = this.props
    const { showForm, currentVersion } = this.state

    return (
      <div className="ReleaseNotes">
        <div className="ReleaseNotes__inner">
          <header className="ReleaseNotes__header">
            <div className="ReleaseNotes__header-info">
              <h1 className="ReleaseNotes__title">Edit Release Notes/Versions</h1>
            </div>
          </header>
          {showForm && currentVersion ? (
            <>
              <ReleaseVersion version={currentVersion} onSubmit={this.handleSubmit} onReset={this.handleReset} />
              {currentVersion.releaseVersionId > 0 && <ReleaseNotes versionId={currentVersion.releaseVersionId} />}
            </>
          ) : (
            <ReleaseVersions
              onPublish={this.handlePublish}
              onEdit={this.handleEdit}
              onCreate={this.handleCreate}
              itemsList={versions}
            />
          )}
        </div>
      </div>
    )
  }

  handleCreate = () => {
    const { showLoadMore } = this.props
    showLoadMore && showLoadMore(false)
    this.setState({
      showForm: true,
      currentVersion: releaseVersionValueDefaults,
    })
  }

  handleEdit = (currentVersion: IReleaseVersionResponse) => {
    const { showLoadMore } = this.props
    this.setState({ showForm: true, currentVersion })
    showLoadMore && showLoadMore(false)
  }

  handlePublish = async (releaseVersion: IReleaseVersionResponse) => {
    const { updateList } = this.props
    const { statusCode, data } = await publishReleaseVersion(releaseVersion)
    statusCode === 200 && data && updateList && updateList(data)
  }

  handleReset = () => {
    const { showLoadMore } = this.props
    this.setState({ showForm: false, currentVersion: undefined })
    showLoadMore && showLoadMore(true)
  }

  handleSubmit = async (version: IReleaseVersionResponse) => {
    const { updateList } = this.props
    const current = { ...version }
    const isNew = current.releaseVersionId <= 0
    const apiFunction = isNew ? handleApiRequest(postReleaseVersion) : handleApiRequest(patchReleaseVersion)

    isNew && delete current.releaseVersionId

    const result = await apiFunction(current)

    if (result) {
      updateList && updateList(result, isNew)
      this.setState({ currentVersion: result })
    }
  }
}

export default withReleaseVersions<IReleaseVersionResponse>(ReleaseVersionsPage, getAllReleaseVersions)

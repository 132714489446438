import { IStripeExternalAccount } from '@fragus/sam-types'
import IApiModel, { IApiField, createModel } from './apiModel'
import { IBANAccount, requiredNumber, requiredString } from './validations'

// Default Values
export const stripeExternalAccountCreateDefaults: IStripeExternalAccount = {
  providerId: -1,
  accountNumber: '',
  country: 'DK',
  currency: 'dkk',
  accountHolderName: '',
  accountHolderType: 'company',
  defaultForCurrency: true,
}

// API Model
const model: IApiModel = {
  name: 'stripeExternalAccountModel',
  fields: [
    {
      name: 'providerId',
      uiType: 'number',
      title: 'Provider ID',
      validation: [requiredNumber],
    },
    {
      name: 'accountNumber',
      uiType: 'string',
      title: 'IBAN Account ID',
      validation: [requiredString, IBANAccount],
    },
    {
      name: 'country',
      uiType: 'string',
      title: 'Country',
      validation: [requiredString],
    },
    {
      name: 'currency',
      uiType: 'string',
      title: 'Currency',
      validation: [requiredString],
    },
    {
      name: 'accountHolderName',
      uiType: 'string',
      title: 'Account Holder Name',
      validation: [requiredString],
    },
    {
      name: 'accountHolderType',
      uiType: 'string',
      title: 'Account Holder Type',
      validation: [requiredString],
    },
    {
      name: 'defaultForCurrency',
      uiType: 'boolean',
      title: 'Default For Currency',
    },
  ],
  view: {},
}

export const stripeExternalAccountCreateModel = (view?: string | IApiField[], viewContext?: string) =>
  createModel<IStripeExternalAccount>(view, viewContext, model, stripeExternalAccountCreateDefaults)

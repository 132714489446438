import { IContractTemplateResponse } from '@fragus/sam-types'
import IApiModel, { IApiField, createModel } from './apiModel'
import { contractTemplateValueDefaults } from '../apiModels/contractTemplate'
import { requiredPrimaryKey, requiredString, requiredNumber, numberIsWhole, requiredBoolean } from './validations'
import { termsOfServiceModel } from './termsOfService'
import { priceSpecificationModel } from './priceSpecification'

export const autoexpertenBaseValues: Partial<IContractTemplateResponse> = {
  defaultDuration: 36,
  defaultMileage: 60000,
  // 10 years
  maxAge: 120,
}

export const autoexpertenConfig = {
  lockedFields: ['maxAge', 'defaultDuration', 'defaultMileage'],
}

const model: IApiModel = {
  name: 'autoexpertenContractTemplateModel',
  fields: [
    {
      name: 'id',
      uiType: 'string',
      title: 'Template ID',
      validation: [requiredPrimaryKey],
    },
    {
      name: 'providerId',
      uiType: 'string',
      title: 'Provider ID',
      validation: [requiredNumber],
    },

    {
      name: 'minimumPaymentsCount',
      uiType: 'number',
      title: 'Minimum payments count',
      validation: [requiredNumber],
    },
    {
      name: 'name',
      uiType: 'string',
      title: 'Internal Name (used in pricefile)',
      validation: [requiredString],
    },
    {
      name: 'description',
      uiType: 'string',
      title: 'External Name (shown on website)',
      validation: [requiredString],
    },
    {
      name: 'formattedDescription',
      uiType: 'string',
      title: 'Formatted Description',
      validation: [],
    },
    {
      name: 'image',
      uiType: 'string',
      title: 'Image',
    },
    {
      name: 'showOnWebcalc',
      uiType: 'boolean',
      title: 'Available on Webcalc',
      validation: [requiredBoolean],
    },
    {
      name: 'calculationMethod',
      uiType: 'number',
      title: 'Calculation Method',
      validation: [
        requiredNumber,
        {
          message: 'Required field',
          type: '',
          valid: ({ value }) => {
            return value > 0
          },
        },
      ],
    },
    {
      name: 'priceSource',
      uiType: 'string',
      title: 'Price Source',
      validation: [requiredString],
    },
    {
      name: 'maxAge',
      uiType: 'number',
      title: 'Max. Age',
      validation: [
        requiredNumber,
        numberIsWhole,
        {
          message: 'Value must be higher than Min. Age',
          type: '',
          valid: ({ value, record }) => {
            const clone: any = record
            return value > clone.minAge
          },
        },
      ],
    },
    {
      name: 'minAge',
      uiType: 'number',
      title: 'Min. Age',
      validation: [
        requiredNumber,
        numberIsWhole,
        {
          message: 'Value must be lower than Max. Age',
          type: '',
          valid: ({ value, record }) => {
            const clone: any = record
            return value < clone.maxAge
          },
        },
      ],
    },
    {
      name: 'weight',
      uiType: 'Number',
      title: 'Weight',
    },
    {
      name: 'totalPriceFactorInTenthOfCents',
      uiType: 'number',
      title: 'Total Price Factor',
      validation: [
        requiredNumber,
        {
          message: 'Value cannot be zero',
          type: '',
          valid: ({ value }) => {
            return value > 0
          },
        },
      ],
    },
    {
      name: 'overdrivenCharge',
      uiType: 'number',
      title: 'Over Driven Charge',
      relation: 'PriceSpecification',
    },
    {
      name: 'underdrivenCharge',
      uiType: 'number',
      title: 'Under Driven Charge',
      relation: 'PriceSpecification',
    },
    {
      name: 'properties',
      uiType: 'string',
      title: 'Properties',
    },
    {
      name: 'options',
      uiType: 'string',
      title: 'Optionals',
    },
    {
      name: 'termsOfService',
      uiType: 'string',
      title: 'Terms of Service',
      relation: 'TermsOfService',
    },
  ],
  view: {
    providerPage: [
      {
        name: 'id',
      },
      {
        name: 'priceSource',
      },
      {
        name: 'name',
      },
      {
        name: 'description',
      },
    ],
  },
}

const termsOfServiceRelation = termsOfServiceModel()
const priceSpecificationRelation = priceSpecificationModel()

export const autoexpertenContractTemplateModel = (view?: string | IApiField[], context?: string) =>
  createModel<IContractTemplateResponse>(view, context, model, contractTemplateValueDefaults, {
    TermsOfService: { name: 'TermsOfService', model: termsOfServiceRelation },
    PriceSpecification: { name: 'PriceSpecification', model: priceSpecificationRelation },
  })

import { Field } from 'formik'
import React, { ChangeEvent } from 'react'
import { ITermsOfServiceResponse } from '@fragus/sam-types'
import { IApiErrors } from '../../../apiModels/apiModel'
import FileUploadField from '../../Field/FileUploadField/FileUploadField'
import StringField from '../../Field/StringField/StringField'
import FormSection from '../../FormSection/FormSection'

interface IProps {
  edit?: boolean
  errors?: IApiErrors
  name?: string
  onBlur: (e: ChangeEvent<any>) => void
  onChange: (e: ChangeEvent<any>) => void
  title?: boolean | string
  uploadUrl: string
  value: ITermsOfServiceResponse
}

// tslint:disable jsx-no-lambda
const TermsOfService: React.SFC<IProps> = ({ edit, errors = {}, name, title, value, onBlur, onChange, uploadUrl }) => (
  <div className="TermsOfService">
    <FormSection
      name="general"
      classNamePrefix="TermsOfService"
      title={title ? (title === true ? 'Terms of Service' : title) : ''}
    >
      <StringField
        className="TermsOfService__name"
        disabled={!edit}
        errors={errors.name}
        locked={!edit}
        name={name ? `${name}.name` : 'name'}
        onBlur={onBlur}
        onChange={onChange}
        size={40}
        title="Terms of Service Name"
        value={value.name}
      />
      <Field name={name ? `${name}.ref` : 'ref'} value={value.ref}>
        {({ field, form }: any) => (
          <FileUploadField
            accept=".pdf"
            className="TermsOfService__ref"
            contextParent={form}
            disabled={!edit}
            errors={errors.ref}
            locked={!edit}
            name={field.name}
            onBlur={onBlur}
            onChange={onChange}
            title="Terms of Service Document"
            serverURL={uploadUrl}
            value={field.value}
          />
        )}
      </Field>
    </FormSection>
  </div>
)
// tslint:emable jsx-no-lambda

export default TermsOfService

import { Form } from 'formik'
import React from 'react'
import { connect, Dispatch } from 'react-redux'
import equal from 'fast-deep-equal'
import {
  IContractOptionResponse,
  IContractTemplateResponse,
  ApiError,
  IContractProviderResponse,
} from '@fragus/sam-types'
import ActionTypes from '../../../actions/ActionTypes'
import {
  IProviderPageOptionDetailsReset,
  IProviderPageOptionDetailsUpdate,
  IProviderPageRouteUpdate,
  optionDetailsGet,
  optionDetailsReset,
  optionDetailsUpdate,
  optionPatch,
  optionPost,
  providerPageRouteUpdate,
  templateListGet,
} from '../../../actions/providerPageActions'
import { IApiErrors } from '../../../apiModels/apiModel'
import {
  contractOptionModel,
  contractOptionValueDefaults,
  IContractOptionRequestGet,
  IContractOptionRequestPatch,
  IContractOptionRequestPost,
} from '../../../apiModels/contractOption'
import { IContractTemplateRequestGet } from '../../../apiModels/contractTemplate'
import ActionButton from '../../../components/ActionButton/ActionButton'
import ContractOption from '../../../components/Form/ContractOption/ContractOption'
import Omnik from '../../../components/Omnik/Omnik'
import { IRootState } from '../../../reducers/initialState'
import { getScroll, IScroll } from '../../../utils/getScroll'
import scrollDelta from '../scrollDelta'
import './PPOptionDetails.css'
import { getWarranties } from '../../../api/api'
import { IWarrantyResponse } from '@fragus/sam-types/types/warranty'
import { BaseOption as Option } from 'types'
import { IJsonStatus } from '@omnicar/sam-tfetch'
import { trimString } from 'utils/formatString'

interface IProps {
  create?: boolean
}

interface IReducerProps {
  optionDetails: IContractOptionResponse
  optionId: number
  providerId: number
  templateList: IContractTemplateResponse[]
  providerDetails: IContractProviderResponse
}

interface IActionProps {
  optionDetailsGet: (request: IContractOptionRequestGet) => Promise<void>
  optionDetailsReset: () => IProviderPageOptionDetailsReset
  optionDetailsUpdate: (details: IContractOptionResponse) => IProviderPageOptionDetailsUpdate
  optionPatch: (request: IContractOptionRequestPatch) => Promise<void>
  optionPost: (request: IContractOptionRequestPost) => Promise<void>
  providerPageRouteUpdate: (route: string) => IProviderPageRouteUpdate
  templateListGet: (request: IContractTemplateRequestGet) => Promise<void>
}

interface IState {
  edit: boolean
  optionDetails: IContractOptionResponse
  scroll: IScroll
  warrantyOptions: Option<number | null>[]
}

const model = contractOptionModel()

// tslint:disable-next-line interface-name Because of a lint error we need to investigate
class PPOptionDetails extends React.Component<IProps & IReducerProps & IActionProps, IState> {
  constructor(props: IProps & IReducerProps & IActionProps) {
    super(props)

    const { create, providerId } = this.props
    const edit = create ? true : false
    const optionDetails = create ? contractOptionValueDefaults : this.props.optionDetails

    // We need to attach the contractProviderId value when we are creating a new record
    if (create) {
      optionDetails.contractProviderId = providerId
    }

    this.state = {
      edit,
      optionDetails,
      scroll: { x: 0, y: 0 },
      warrantyOptions: [],
    }
  }

  public async componentDidMount() {
    const { providerId, templateListGet } = this.props

    templateListGet({
      providerId,
    })

    document.addEventListener('scroll', this.handleScroll)

    this.handleScroll()
    try {
      const options = await this.getAvailableWarranties()
      this.setState({ warrantyOptions: options })
    } catch (error) {
      console.log(error)
    }
  }

  public componentWillUnmount() {
    document.removeEventListener('scroll', this.handleScroll)
  }

  static getDerivedStateFromProps(nextProps: IProps & IReducerProps & IActionProps, prevState: IState) {
    const { optionDetails, create } = nextProps
    let state = null

    if (!create && !equal(optionDetails, prevState.optionDetails)) {
      state = {
        edit: false,
        optionDetails,
      }
    }
    return state
  }

  // tslint:disable jsx-no-lambda
  public render() {
    const self = this
    const { handleFormSubmit, handleFormReset, handleDelete, handleEdit } = this
    const { optionDetails, scroll } = this.state
    const isChildProvider = this.props.providerDetails.parentProviderId

    return optionDetails != null ? (
      <Omnik
        model={model}
        initialValues={optionDetails}
        onSubmit={handleFormSubmit}
        onReset={handleFormReset}
        validate={(values) => {
          let errors: IApiErrors | {} = {}

          if (model) {
            errors = model.getErrors(values)
          }

          return errors
        }}
      >
        {({ dirty, errors, handleBlur, handleChange, handleReset, handleSubmit, isValid, values }) => {
          const { edit } = self.state
          const { create } = self.props
          const isAdded = self.isAddedToTemplate()
          const { vatPct, countryName, currency } = this.props.providerDetails.country

          const title = self.state.optionDetails.description

          return (
            <Form className="PPOptionDetails" onSubmit={handleSubmit} onReset={handleReset}>
              <header
                className={`PPOptionDetails__header ${scroll.y > scrollDelta ? 'PPOptionDetails__header--sticky' : ''}`}
              >
                <div className="PPOptionDetails__info">
                  <h1 className="PPOptionDetails__title">{create ? '' : trimString(title, 60)}</h1>
                </div>
                {!isChildProvider && (
                  <div className="PPOptionDetails__actions">
                    {!isAdded && !create && (
                      <ActionButton className="PPOptionDetails__deletebutton" onClick={handleDelete} text="Delete" />
                    )}
                    {!edit && !create ? (
                      <ActionButton className="PPOptionDetails__editbutton" onClick={handleEdit} text="Edit" />
                    ) : (
                      <>
                        <button className="PPOptionDetails__resetbutton" type="reset">
                          Cancel
                        </button>
                        <button className="PPOptionDetails__submitbutton" disabled={!dirty || !isValid} type="submit">
                          Save
                        </button>
                      </>
                    )}
                  </div>
                )}
              </header>
              <main className="PPOptionDetails__content">
                <ContractOption
                  edit={edit}
                  errors={errors}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values}
                  warrantyOptions={this.state.warrantyOptions}
                  vatPct={vatPct}
                  countryName={countryName}
                  currency={currency}
                />
              </main>
            </Form>
          )
        }}
      </Omnik>
    ) : (
      <div />
    )
  }

  private getAvailableWarranties = async () => {
    let response: IJsonStatus<IWarrantyResponse[], ApiError> = await getWarranties()
    let warranties: IWarrantyResponse[] = response.data || []
    let options: Option<number | null>[] = [{ label: '==== No Warranty ====', value: null }]
    warranties.forEach((value: IWarrantyResponse) => {
      options.push({
        label: value.warrantyType,
        value: value.warrantyId,
      })
    })
    return options
  }

  private handleEdit = () => {
    const { edit } = this.state
    this.setState({ edit: !edit })
  }

  private handleFormSubmit = (values: IContractOptionResponse, { resetForm, encodeValues }: any) => {
    const { create } = this.props
    const edit = create ? true : !this.state.edit

    this.setState({ edit })

    if (create) {
      this.props.optionPost(values)
    } else {
      this.props.optionPatch(values)
      const newValues = encodeValues(values)
      resetForm({ values: newValues })
    }

    // @TODO Make the actions above async so we can await - and get outcome
  }

  private handleFormReset = () => {
    const { create, providerPageRouteUpdate } = this.props

    if (create) {
      providerPageRouteUpdate('option')
    } else {
      this.setState({ edit: false })
    }
  }

  private handleDelete = () => {
    const { edit, optionDetails } = this.state
    const { providerId } = this.props
    let found: IContractOptionResponse | undefined

    this.props.templateList.some((template) => {
      found = template.options.find((option) => {
        return option.id === optionDetails.id
      })

      return found ? true : false
    })

    if (!found) {
      this.props.optionPatch({
        ...optionDetails,
        ...{
          contractProviderId: providerId,
          archived: true,
        },
      })
    }

    this.setState({ edit: !edit })
  }

  private isAddedToTemplate = () => {
    const { templateList } = this.props
    const { optionDetails } = this.state
    // Default isAdded is true to avoid having delete button show as default and then be removed
    let isAdded = true

    if (templateList && templateList.length && optionDetails) {
      templateList.some((template: IContractTemplateResponse) => {
        const { options, properties } = template

        // Iterate each options array till we possibly find a match
        options.some((option: IContractOptionResponse) => {
          isAdded = option.id === optionDetails.id ? true : false

          return option.id === optionDetails.id
        })

        if (!isAdded) {
          // Iterate each properties array till we possibly find a match
          properties.some((option: IContractOptionResponse) => {
            isAdded = option.id === optionDetails.id ? true : false

            return option.id === optionDetails.id
          })
        }

        return isAdded ? true : false
      })
    }

    return isAdded
  }

  private handleScroll = () => {
    const scroll = getScroll()

    this.setState({ scroll })
  }
}

const mapStateToProps = (state: IRootState) => ({
  optionDetails: state.providerPage.optionDetails,
  optionId: state.providerPage.optionId,
  providerId: state.providerPage.providerId,
  templateList: state.providerPage.templateList,
  providerDetails: state.providerPage.providerDetails,
})

const mapDispatchToProps = (dispatch: Dispatch<ActionTypes>) => ({
  optionDetailsGet: (request: IContractOptionRequestGet) => dispatch(optionDetailsGet(request)),
  optionDetailsReset: () => dispatch(optionDetailsReset()),
  optionDetailsUpdate: (details: IContractOptionResponse) => dispatch(optionDetailsUpdate(details)),
  optionPatch: (request: IContractOptionRequestPatch) => dispatch(optionPatch(request)),
  optionPost: (request: IContractOptionRequestPost) => dispatch(optionPost(request)),
  providerPageRouteUpdate: (route: string) => dispatch(providerPageRouteUpdate(route)),
  templateListGet: (request: IContractTemplateRequestGet) => dispatch(templateListGet(request)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PPOptionDetails)

import React from 'react'
import SortableTable, { createButton } from 'components/SortableTable/SortableTable'
import { translationModel } from 'apiModels/translation'
import { ITranslationResponse, ITranslationApplication } from '@fragus/sam-types'
import { TApiRecord } from 'apiModels/apiModel'
import { trimString } from 'utils/formatString'

interface IProps {
  show: boolean
  translations: ITranslationResponse[]
  onEdit: (record: ITranslationResponse) => void
  onDelete: (record: ITranslationResponse) => void
}

const model = translationModel()
const tableFieldsMapper = (record: TApiRecord, fieldName: string): string | undefined => {
  let value = undefined
  switch (fieldName) {
    case 'missedValues':
      value = record[fieldName] ? 'yes' : 'no'
      break
    case 'applications':
      value = (record[fieldName] as Array<ITranslationApplication>).map((a) => a.name).join(', ')
      break
    case 'key':
      value = trimString(record[fieldName] as string, 25)
      break
    default:
      value = undefined
      break
  }
  return value
}

const TranslationsList: React.SFC<IProps> = ({ show, translations, onDelete, onEdit }) => (
  <div className={`Translations__list-${show ? 'active' : 'hidden'}`}>
    {!!translations.length && (
      <SortableTable
        primaryKey={'id'}
        data={translations}
        model={model}
        defaultSortColumn={'entityUpdated'}
        defaultSortingOrder={'desc'}
        customMapper={tableFieldsMapper}
        additionalColumns={[
          { title: '', processRecord: createButton(onEdit, 'Translations__createbutton', true) },
          { title: '', processRecord: createButton(onDelete, 'Translations__deletebutton') },
        ]}
      />
    )}
  </div>
)

export default TranslationsList

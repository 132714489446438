import { IApiFieldValidation } from './apiModel'
import { IContractProviderResponse } from '@fragus/sam-types'

export const validateUrl = (value: string) => {
  return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
    value,
  )
}

export const validateEmail = (value: string) => {
  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return regex.test(value)
}

export const requiredStringFn = ({ value }: any) => {
  let isValid = value !== ''

  if (value && value.replace) {
    isValid = value.replace(/ /g, '') !== ''
  }

  return isValid
}

export const stringMinLengthFn = (len: number) => ({ value }: any) => requiredStringFn({ value }) && value.length >= len

export const stringMaxLengthFn = (len: number) => ({ value }: any) => (value ? value.length <= len : true)

export const requiredNumberFn = ({ value }: any) => {
  let isValid = value !== null
  isValid = value !== ''

  return isValid
}

export const requiredBooleanFn = ({ value }: any) => {
  const isValid = value != null

  return isValid
}

export const validateWholeNumberFn = ({ value }: any) => {
  let isValid = true

  if (value) {
    const regex = /^[0-9]*$/
    isValid = regex.test(value)
  }

  return isValid
}

export const IBANAccountFn = ({ value }: any) => {
  const iban = new RegExp(/[a-zA-Z]{2}[0-9]{2}[a-zA-Z0-9]{4}[0-9]{7}([a-zA-Z0-9]?){0,16}/)
  return iban.test(value)
}

export const IBANAccount: IApiFieldValidation = {
  message: 'Must be a valid IBAN account',
  type: 'pattern',
  valid: IBANAccountFn,
}

export const requiredPrimaryKey: IApiFieldValidation = {
  message: 'Required primary key',
  type: 'required',
  valid: requiredNumberFn,
}

export const requiredNumber: IApiFieldValidation = {
  message: 'Required field',
  type: 'required',
  valid: requiredNumberFn,
}

export const numberIsWhole: IApiFieldValidation = {
  message: 'Number must be whole',
  type: 'pattern',
  valid: validateWholeNumberFn,
}

export const requiredString: IApiFieldValidation = {
  message: 'Required field',
  type: 'required',
  valid: requiredStringFn,
}

export const stringMinLength = (len: number): IApiFieldValidation => ({
  message: 'Too short',
  type: 'pattern',
  valid: stringMinLengthFn(len),
})

export const stringMaxLength = (len: number): IApiFieldValidation => ({
  message: 'Too long',
  type: 'pattern',
  valid: stringMaxLengthFn(len),
})

export const requiredBoolean: IApiFieldValidation = {
  message: 'Required field',
  type: 'required',
  valid: requiredBooleanFn,
}

export const urlPattern: IApiFieldValidation = {
  message: 'Must be a valid URL',
  type: 'pattern',
  valid: ({ value }: any) => validateUrl(value),
}

export const emailPattern: IApiFieldValidation = {
  message: 'Must be a valid email address',
  type: 'pattern',
  valid: ({ value }: any) => validateEmail(value),
}

export const validateAdditionalEmails = (additionalEmails: string): boolean =>
  additionalEmails ? additionalEmails.split(';').every((email) => validateEmail(email)) : true

export const providerCheckBoxesCompatibilityError =
  "Only one of the checkboxes can be selected ('V4PTOnlyForSigning', 'UseV4PricingTool', 'ForceEnableCustomContracts')"

export const providerCheckBoxesCompatibilityValidation: IApiFieldValidation['valid'] = ({ record, value }) => {
  const {
    isForceAllowCustomContracts,
    isUseV4PTOnlyForSigning,
    isUsingV4PricingTool,
  } = record as IContractProviderResponse

  return (
    [isUseV4PTOnlyForSigning, isUsingV4PricingTool, isForceAllowCustomContracts].filter(Boolean).length <= 1 || !value
  )
}

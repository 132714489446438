import React from 'react'
import { Dispatch, connect } from 'react-redux'
import { IContractOptionResponse, IContractProviderResponse } from '@fragus/sam-types'
import ActionTypes from '../../../actions/ActionTypes'
import { IProviderPageState } from 'reducers/providerPage/providerPageInitialState'
import {
  IProviderPageRouteUpdate,
  IProviderPageUpdate,
  optionListGet,
  providerPageRouteUpdate,
  providerPageUpdate,
} from '../../../actions/providerPageActions'
import { IContractOptionRequestGet, contractOptionModel } from '../../../apiModels/contractOption'
import ActionButton from '../../../components/ActionButton/ActionButton'
import { IRootState } from '../../../reducers/initialState'
import './PPOptionList.css'
import SortableTable from '../../../components/SortableTable/SortableTable'

const model = contractOptionModel('providerPage')

interface IProps {
  optionList: IContractOptionResponse[]
  optionId: number
  providerId: number
  providerDetails: IContractProviderResponse
}

interface IActionProps {
  optionListGet: (request?: IContractOptionRequestGet) => Promise<void>
  providerPageUpdate: (props: IProviderPageState) => IProviderPageUpdate
  providerPageRouteUpdate: (route: string) => IProviderPageRouteUpdate
}

// tslint:disable-next-line interface-name Because of a lint error we need to investigate
class PPOptionList extends React.Component<IProps & IActionProps, {}> {
  public componentDidMount() {
    const { providerId, optionListGet } = this.props

    if (providerId) {
      optionListGet({
        contractProviderId: providerId,
      })
    }
  }

  public componentDidUpdate(prevProps: IProps & IActionProps) {
    const { providerId, optionListGet } = this.props

    if (prevProps.providerId !== providerId && providerId) {
      optionListGet({ contractProviderId: providerId })
    }
  }

  public render() {
    const { optionList } = this.props
    const isChildProvider = this.props.providerDetails.parentProviderId

    return (
      <div className="PPOptionList">
        <header className="PPOptionList__header">
          <div className="PPOptionList__features">
            {!isChildProvider && (
              <ActionButton
                className="PPOptionList__createbutton"
                onClick={this.handleCreateMouse}
                text="Create New Option"
              />
            )}
          </div>
        </header>
        <main className="PPOptionList__content">
          <SortableTable primaryKey="id" data={optionList} model={model} onRowClick={this.handleRowClick} />
        </main>
      </div>
    )
  }

  private handleCreateMouse = () => {
    this.props.providerPageRouteUpdate('option-create')
  }

  private handleRowClick = (record: any) => {
    if (record && record.id) {
      const { id } = record

      this.props.providerPageUpdate({
        optionDetails: record,
        optionId: id,
        route: 'option-details',
      })
    }
  }
}

const mapStateToProps = (state: IRootState) => ({
  optionId: state.providerPage.optionId,
  optionList: state.providerPage.optionList,
  providerId: state.providerPage.providerId,
  providerDetails: state.providerPage.providerDetails,
})

const mapDispatchToProps = (dispatch: Dispatch<ActionTypes>) => ({
  optionListGet: (request: IContractOptionRequestGet) => dispatch(optionListGet(request)),
  providerPageUpdate: (props: IProviderPageState) => dispatch(providerPageUpdate(props)),
  providerPageRouteUpdate: (route: string) => dispatch(providerPageRouteUpdate(route)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PPOptionList)

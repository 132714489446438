import {
  IContractOptionResponse,
  IContractProviderResponse,
  IContractTemplateResponse,
  ICountryResponse,
  IProductContractTemplateResponse,
  IStripeRequest,
  IStripeResponse,
  IUserResponse,
} from '@fragus/sam-types'
import { IProviderPageState } from 'reducers/providerPage/providerPageInitialState'
import { showError, showSuccess } from 'utils/toastify'
import * as api from '../api/api'
import {
  IContractOptionRequestGet,
  IContractOptionRequestPatch,
  IContractOptionRequestPost,
} from '../apiModels/contractOption'
import {
  IContractProviderRequestGet,
  IContractProviderRequestPatch,
  IContractProviderRequestPost,
} from '../apiModels/contractProvider'
import {
  IContractTemplateRequestGet,
  IContractTemplateRequestPatch,
  IGenericContractTemplateRequestPatch,
  IGenericContractTemplateRequestPost,
  IProductContractTemplateRequestPatch,
} from '../apiModels/contractTemplate'
import { IStripeRequestGet } from '../apiModels/stripe'
import { IUserRequestGet } from '../apiModels/user'
import store from '../utils/store'
import ActionTypeKeys from './ActionTypeKeys'
import { applicationTransactionReset, applicationTransactionUpdate } from './applicationActions'

const t = (k: string) => k

/*************************************************************
 * PAGE ACTIONS
 * First API actions, then actions for pure only reducers
 *
 *************************************************************/

export interface IProviderPageUpdate {
  readonly type: ActionTypeKeys.PROVIDERPAGE_UPDATE
  readonly props: IProviderPageState
}
export const providerPageUpdate = (props: IProviderPageState) => {
  return {
    type: ActionTypeKeys.PROVIDERPAGE_UPDATE,
    props,
  }
}

export interface IProviderPageReset {
  readonly type: ActionTypeKeys.PROVIDERPAGE_RESET
}
export const providerPageReset = (): IProviderPageReset => {
  return {
    type: ActionTypeKeys.PROVIDERPAGE_RESET,
  }
}

export interface IProviderPageRouteUpdate {
  readonly type: ActionTypeKeys.PROVIDERPAGE_ROUTE_UPDATE
  readonly route: string
}
export const providerPageRouteUpdate = (route: string): IProviderPageRouteUpdate => {
  return {
    type: ActionTypeKeys.PROVIDERPAGE_ROUTE_UPDATE,
    route,
  }
}

/*************************************************************
 * STRIPE ACTIONS
 * First API actions, then actions for pure only reducers
 *
 *************************************************************/

// STRIPE API - DETAILS GET - Get a specific providers stripe details
// => providerPage.stripeDetails)
export const stripeDetailsGet = (request: IStripeRequestGet) => async () => {
  store.dispatch(applicationTransactionUpdate(t(`Loading Stripe`)))

  const response = await api.getStripe(request)

  if (response.data) {
    store.dispatch(stripeDetailsUpdate(response.data))
  }

  store.dispatch(applicationTransactionReset())
}

// STRIPE API - PATCH - Update a specific providers stripe details
// => providerPage.stripeDetails
export const stripePatch = (request: IStripeRequest) => async () => {
  store.dispatch(applicationTransactionUpdate(t(`Updating Stripe`)))

  const response = await api.patchStripe(request)

  if (response.data) {
    store.dispatch(stripeDetailsUpdate(response.data))
  }

  store.dispatch(applicationTransactionReset())
}

// STRIPE DETAILS UPDATE - Update provider stripe details
// => providerPage.stripeDetails
export interface IProviderPageStripeDetailsUpdate {
  readonly type: ActionTypeKeys.STRIPE_DETAILS_UPDATE
  readonly stripeDetails: IStripeResponse | null
}
export const stripeDetailsUpdate = (stripeDetails: IStripeResponse): IProviderPageStripeDetailsUpdate => {
  return {
    type: ActionTypeKeys.STRIPE_DETAILS_UPDATE,
    stripeDetails,
  }
}

// STRIPE DETAILS RESET - Reset stripe details
// => providerPage.stripeDetails
export interface IProviderPageStripeDetailsReset {
  readonly type: ActionTypeKeys.STRIPE_DETAILS_RESET
}
export const stripeDetailsReset = (): IProviderPageStripeDetailsReset => {
  return {
    type: ActionTypeKeys.STRIPE_DETAILS_RESET,
  }
}

/*************************************************************
 * PROVIDER ACTIONS
 * First API actions, then actions for pure only reducers
 *
 *************************************************************/

// PROVIDER API - DETAILS GET - Get a specific provider
// => providerPage.providerDetails
export const providerDetailsGet = (request: IContractProviderRequestGet) => async () => {
  store.dispatch(applicationTransactionUpdate(t('Loading Contract Provider')))

  const response = await api.getContractProviderById(request)

  if (response.data) {
    store.dispatch(providerDetailsUpdate(response.data))
  }

  store.dispatch(applicationTransactionReset())
}

// PROVIDER API - LIST GET - Get a list of providers
// => providerPage.providerList
export const providerListGet = (request?: IContractProviderRequestGet) => async () => {
  store.dispatch(applicationTransactionUpdate(t('Loading Contract Providers')))

  const response = await api.getContractProviders(request)

  if (response.data) {
    store.dispatch(providerListUpdate(response.data))
  }

  store.dispatch(applicationTransactionReset())
}

// PROVIDER API - PATCH - Update a specific provider
// => providerPage.providerDetails
export const providerPatch = (request: IContractProviderRequestPatch) => async () => {
  store.dispatch(applicationTransactionUpdate(`${t('Updating Contract Provider')} ${request.administrativeName}`))

  const response = await api.patchContractProvider(request)

  if (response.data) {
    showSuccess('Saved successfully')
    store.dispatch(providerDetailsUpdate(response.data))
  } else if (response.errorData) {
    showError(response.errorData.message)
  }

  store.dispatch(applicationTransactionReset())
}

// PROVIDER API - Update State - Update the acitve state of a specific provider
// => providerPage.providerDetails
export const providerUpdateState = (request: number) => async () => {
  store.dispatch(applicationTransactionUpdate(`${t('Updating Contract Provider state')} ${request}`))

  const response = await api.updateContractProviderState(request)

  if (response.data) {
    showSuccess('Saved successfully')
    store.dispatch(providerDetailsUpdate(response.data))
  } else if (response.errorData) {
    showError(response.errorData.message)
  }

  store.dispatch(applicationTransactionReset())
}

// PROVIDER API - POST - Create a new provider
// => providerPage.providerDetails
export const providerPost = (request: IContractProviderRequestPost) => async () => {
  store.dispatch(applicationTransactionUpdate(`${t('Creating Contract Provider')} ${request.administrativeName}`))

  const response = await api.postContractProvider(request)

  if (response.data) {
    showSuccess('Saved successfully')

    response.data &&
      store.dispatch(
        providerPageUpdate({
          providerDetails: response.data,
          providerId: response.data.contractProviderId,
          route: 'provider-details',
        }),
      )
  } else if (response.errorData) {
    showError(response.errorData.message)
  }
  store.dispatch(applicationTransactionReset())
}

// PROVIDER DETAILS RESET - Reset provider details
// => providerPage.providerDetails
export interface IProviderPageProviderDetailsReset {
  readonly type: ActionTypeKeys.CONTRACT_PROVIDER_DETAILS_RESET
}
export const providerDetailsReset = (): IProviderPageProviderDetailsReset => {
  return {
    type: ActionTypeKeys.CONTRACT_PROVIDER_DETAILS_RESET,
  }
}

// PROVIDER DETAILS UPDATE - Update provider details
// => providerPage.providerDetails
export interface IProviderPageProviderDetailsUpdate {
  readonly type: ActionTypeKeys.CONTRACT_PROVIDER_DETAILS_UPDATE
  readonly providerDetails: IContractProviderResponse | null
}
export const providerDetailsUpdate = (
  providerDetails: IContractProviderResponse,
): IProviderPageProviderDetailsUpdate => {
  return {
    type: ActionTypeKeys.CONTRACT_PROVIDER_DETAILS_UPDATE,
    providerDetails,
  }
}

// PROVIDER ID UPDATE - Update currently selected provider
// => providerPage.providerId
export interface IProviderPageProviderIdUpdate {
  readonly type: ActionTypeKeys.CONTRACT_PROVIDER_ID_UPDATE
  readonly providerId: number
}
export const providerIdUpdate = (providerId: number): IProviderPageProviderIdUpdate => {
  return {
    type: ActionTypeKeys.CONTRACT_PROVIDER_ID_UPDATE,
    providerId,
  }
}

// PROVIDER LIST RESET - Reset the reducer
// => providerPage.providerList
export interface IProviderPageProviderListReset {
  readonly type: ActionTypeKeys.CONTRACT_PROVIDER_LIST_RESET
}
export const providerListReset = (): IProviderPageProviderListReset => {
  return {
    type: ActionTypeKeys.CONTRACT_PROVIDER_LIST_RESET,
  }
}

// PROVIDER LIST UPDATE - Update the reducer
// => providerPage.providerList
export interface IProviderPageProviderListUpdate {
  readonly type: ActionTypeKeys.CONTRACT_PROVIDER_LIST_UPDATE
  readonly providerList: IContractProviderResponse[]
}
export const providerListUpdate = (providerList: IContractProviderResponse[]): IProviderPageProviderListUpdate => {
  return {
    type: ActionTypeKeys.CONTRACT_PROVIDER_LIST_UPDATE,
    providerList,
  }
}

/*************************************************************
 * COUNTRY ACTIONS
 * First API actions, then actions for pure only reducers
 *
 *************************************************************/
// COUNTRY API - COUNTRY LIST GET - Get a list of countries
// => providerPage.countryList

export const countryListGet = () => async () => {
  store.dispatch(applicationTransactionUpdate(t('Loading Countries List')))

  const response = await api.getAllCountries()

  if (response.data) {
    store.dispatch(countryListUpdate(response.data))
  }

  store.dispatch(applicationTransactionReset())
}

// COUNTRY LIST UPDATE - Update the reducer
// => providerPage.countryList
export interface IProviderPageCountryListUpdate {
  readonly type: ActionTypeKeys.COUNTRY_LIST_UPDATE
  readonly countryList: ICountryResponse[]
}
export const countryListUpdate = (countryList: ICountryResponse[]): IProviderPageCountryListUpdate => {
  return {
    type: ActionTypeKeys.COUNTRY_LIST_UPDATE,
    countryList,
  }
}

/*************************************************************
 * TEMPLATE ACTIONS
 * First API actions, then actions for pure only reducers
 *
 *************************************************************/

// TEMPLATE API - DETAILS GET - Get a specific template
// => providerPage.templateDetails
export const templateDetailsGet = (request: IContractTemplateRequestGet) => async () => {
  store.dispatch(applicationTransactionUpdate(t('Loading Contract Template')))

  const response = await api.getContractTemplateById(request)

  if (response.data) {
    store.dispatch(templateDetailsUpdate(response.data))
  }

  store.dispatch(applicationTransactionReset())
}

// TEMPLATE API - LIST GET - Get a list of templates
// => providerPage.templateList
export const templateListGet = (request: IContractTemplateRequestGet) => async () => {
  store.dispatch(applicationTransactionUpdate(t('Loading Contract Templates')))

  const response = await api.getContractTemplates(request)

  if (response.data) {
    store.dispatch(templateListUpdate(response.data))
  }

  store.dispatch(applicationTransactionReset())
}

// TEMPLATE API - PATCH - Update a specific template
// => providerPage.templateDetails
export const templatePatch = (request: IGenericContractTemplateRequestPatch) => async () => {
  store.dispatch(applicationTransactionUpdate(`${t('Updating Contract Template')} ${request.description}`))

  const response = request.isProductTemplate
    ? await api.patchProductContractTemplate(request as IProductContractTemplateRequestPatch)
    : await api.patchContractTemplate(request)

  if (response.data) {
    showSuccess('Saved successfully')
    store.dispatch(templateDetailsUpdate(response.data))
  } else if (response.errorData) {
    showError(response.errorData.message)
  }

  store.dispatch(applicationTransactionReset())
}

// TEMPLATE API - POST - Create a new template
// => providerPage.templateDetails
export const templatePost = (request: IGenericContractTemplateRequestPost) => async () => {
  store.dispatch(applicationTransactionUpdate(`${t('Creating Contract Template')} ${request.description}`))

  const response =
    request.isProductTemplate || 'defaultHours' in request
      ? await api.postProductContractTemplate(request as IProductContractTemplateRequestPatch)
      : await api.postContractTemplate(request as IContractTemplateRequestPatch)

  if (!response || !response.data) {
    store.dispatch(templateDetailsReset())
  } else if (response.errorData) {
    showError(response.errorData.message)
  } else {
    showSuccess('Saved successfully')
  }

  store.dispatch(providerPageRouteUpdate('template'))
  store.dispatch(applicationTransactionReset())
}

// TEMPLATE API - POST DELETE - Delete a template
// => providerPage.templateDetails
// @TODO - need a proper path (is being worked on)
export const templateDelete = (request: IContractTemplateResponse) => async () => {
  store.dispatch(applicationTransactionUpdate(`Archiving Contract Template ${request.description}`))

  const response = await api.deleteContractTemplate(request)

  if (response.data) {
    store.dispatch(providerPageRouteUpdate('template'))
  } else {
    store.dispatch(templateDetailsReset())
  }

  store.dispatch(applicationTransactionReset())
}

// TEMPLATE DETAILS RESET - Reset template details
// => providerPage.templateDetails
export interface IProviderPageTemplateDetailsReset {
  readonly type: ActionTypeKeys.CONTRACT_TEMPLATE_DETAILS_RESET
}
export const templateDetailsReset = (): IProviderPageTemplateDetailsReset => {
  return {
    type: ActionTypeKeys.CONTRACT_TEMPLATE_DETAILS_RESET,
  }
}

// TEMPLATE DETAILS UPDATE - Update template details
// => providerPage.templateDetails
export interface IProviderPageTemplateDetailsUpdate {
  readonly type: ActionTypeKeys.CONTRACT_TEMPLATE_DETAILS_UPDATE
  readonly templateDetails: IContractTemplateResponse | IProductContractTemplateResponse | null
}
export const templateDetailsUpdate = (
  templateDetails: IContractTemplateResponse | IProductContractTemplateResponse,
): IProviderPageTemplateDetailsUpdate => {
  return {
    type: ActionTypeKeys.CONTRACT_TEMPLATE_DETAILS_UPDATE,
    templateDetails,
  }
}

// TEMPLATE ID - Currently selected template
// => providerPage.templateId) - default is
export interface IProviderPageTemplateIdUpdate {
  readonly type: ActionTypeKeys.CONTRACT_TEMPLATE_ID_UPDATE
  readonly templateId: number
}
export const templateIdUpdate = (templateId: number): IProviderPageTemplateIdUpdate => {
  return {
    type: ActionTypeKeys.CONTRACT_TEMPLATE_ID_UPDATE,
    templateId,
  }
}

// TEMPLATE LIST RESET - Reset the reducer
// => providerPage.templateList
export interface IProviderPageTemplateListReset {
  readonly type: ActionTypeKeys.CONTRACT_TEMPLATE_LIST_RESET
}
export const templateListReset = (): IProviderPageTemplateListReset => {
  return {
    type: ActionTypeKeys.CONTRACT_TEMPLATE_LIST_RESET,
  }
}

// TEMPLATE LIST UPDATE - Update the reducer
// => providerPage.templateList
export interface IProviderPageTemplateListUpdate {
  readonly type: ActionTypeKeys.CONTRACT_TEMPLATE_LIST_UPDATE
  readonly templateList: IContractTemplateResponse[]
}
export const templateListUpdate = (templateList: IContractTemplateResponse[]): IProviderPageTemplateListUpdate => {
  return {
    type: ActionTypeKeys.CONTRACT_TEMPLATE_LIST_UPDATE,
    templateList,
  }
}

/*************************************************************
 * OPTION ACTIONS
 * First API actions, then actions for pure only reducers
 *
 *************************************************************/

// OPTION API - DETAILS GET - Get a specific option
// => providerPage.optionDetails
export const optionDetailsGet = (request: IContractOptionRequestGet) => async () => {
  store.dispatch(applicationTransactionUpdate(t('Loading Contract Option')))

  const response = await api.getContractOptionById(request)

  if (response.data) {
    store.dispatch(optionDetailsUpdate(response.data))
  }

  store.dispatch(applicationTransactionReset())
}

// OPTION API - LIST GET - Get a list of options
// => providerPage.optionList
export const optionListGet = (request: IContractOptionRequestGet) => async () => {
  store.dispatch(applicationTransactionUpdate(t('Loading Contract Options')))

  api.getContractOptions(request).then((resp) => {
    if (resp && resp.data) {
      store.dispatch(optionListUpdate(resp.data))
    }

    store.dispatch(applicationTransactionReset())
  })
}

// OPTION API - PATCH - Update a specific option
// => providerPage.optionDetails
export const optionPatch = (request: IContractOptionRequestPatch) => async () => {
  // @TODO different text when archiving
  if (request.archived) {
    store.dispatch(applicationTransactionUpdate(`Archiving Contract Option ${request.description}`))
  } else {
    store.dispatch(applicationTransactionUpdate(`Updating Contract Option ${request.description}`))
  }

  // NOTE: priceWith3Decimals overrides price (in cents/ören).
  if (request.price.priceWith3Decimals) {
    request.price.price = 0
  }

  const response = await api.patchContractOption(request)

  if (response.data) {
    showSuccess('Saved successfully')
    if (request.archived) {
      store.dispatch(providerPageRouteUpdate('option'))
    } else {
      store.dispatch(optionDetailsUpdate(response.data))
    }
  } else if (response.errorData) {
    if (response.errorData.message === 'GENERIC_CONTRACT_OPTION_ABBREVIATION_ALREADY_EXISTS') {
      showError('Abbreviation needs to be unique')
    } else {
      showError(response.errorData.message)
    }
    optionDetailsReset()
  }

  store.dispatch(applicationTransactionReset())
}

// OPTION API - POST - Create a new option
// => providerPage.optionDetails
export const optionPost = (request: IContractOptionRequestPost) => async () => {
  store.dispatch(applicationTransactionUpdate(`Creating Contract Option ${request.description}`))

  const response = await api.postContractOption(request)

  if (response.data) {
    showSuccess('Saved successfully')
    store.dispatch(
      providerPageUpdate({
        optionDetails: response.data,
        optionId: response.data.id,
        route: 'option-details',
      }),
    )
  } else if (response.errorData) {
    if (response.errorData.message === 'GENERIC_CONTRACT_OPTION_ABBREVIATION_ALREADY_EXISTS') {
      showError('Abbreviation needs to be unique')
    } else {
      showError(response.errorData.message)
    }
  }
  store.dispatch(applicationTransactionReset())
}

// OPTION DETAILS RESET - Reset option details
// => providerPage.optionDetails
export interface IProviderPageOptionDetailsReset {
  readonly type: ActionTypeKeys.CONTRACT_OPTION_DETAILS_RESET
}
export const optionDetailsReset = (): IProviderPageOptionDetailsReset => {
  return {
    type: ActionTypeKeys.CONTRACT_OPTION_DETAILS_RESET,
  }
}

// OPTION DETAILS UPDATE - Update option details
// => providerPage.optionDetails
export interface IProviderPageOptionDetailsUpdate {
  readonly type: ActionTypeKeys.CONTRACT_OPTION_DETAILS_UPDATE
  readonly optionDetails: IContractOptionResponse | null
}
export const optionDetailsUpdate = (optionDetails: IContractOptionResponse): IProviderPageOptionDetailsUpdate => {
  return {
    type: ActionTypeKeys.CONTRACT_OPTION_DETAILS_UPDATE,
    optionDetails,
  }
}

// OPTION ID - Currently selected option
// => providerPage.optionId
export interface IProviderPageOptionIdUpdate {
  readonly type: ActionTypeKeys.CONTRACT_OPTION_ID_UPDATE
  readonly optionId: number
}
export const optionIdUpdate = (optionId: number): IProviderPageOptionIdUpdate => {
  return {
    type: ActionTypeKeys.CONTRACT_OPTION_ID_UPDATE,
    optionId,
  }
}

// OPTION LIST RESET - Reset the reducer
// => providerPage.optionList
export interface IProviderPageOptionListReset {
  readonly type: ActionTypeKeys.CONTRACT_OPTION_LIST_RESET
}
export const optionListReset = (): IProviderPageOptionListReset => {
  return {
    type: ActionTypeKeys.CONTRACT_OPTION_LIST_RESET,
  }
}

// OPTION LIST UPDATE - Update the reducer
// => providerPage.optionList
export interface IProviderPageOptionListUpdate {
  readonly type: ActionTypeKeys.CONTRACT_OPTION_LIST_UPDATE
  readonly optionList: IContractOptionResponse[]
}
export const optionListUpdate = (optionList: IContractOptionResponse[]): IProviderPageOptionListUpdate => {
  return {
    type: ActionTypeKeys.CONTRACT_OPTION_LIST_UPDATE,
    optionList,
  }
}

/*************************************************************
 * USER ACTIONS
 * First API actions, then actions for pure only reducers
 *
 *************************************************************/

// USER API - LIST GET - Get a list of users
// => providerPage.userList
export const userListGet = (request: IUserRequestGet) => async () => {
  store.dispatch(applicationTransactionUpdate(t('Loading Users')))

  const response = await api.getUsersForProviderById(request)

  if (response.data) {
    store.dispatch(userListUpdate(response.data))
  }

  store.dispatch(applicationTransactionReset())
}

// USER LIST UPDATE - Update the reducer
// => providerPage.userList
export interface IProviderPageUserListUpdate {
  readonly type: ActionTypeKeys.USER_LIST_UPDATE
  readonly userList: IUserResponse[]
}
export const userListUpdate = (userList: IUserResponse[]): IProviderPageUserListUpdate => {
  return {
    type: ActionTypeKeys.USER_LIST_UPDATE,
    userList,
  }
}
/*************************************************************
 * CONTRACT ACTIONS
 * First API actions, then actions for pure only reducers
 *
 *************************************************************/

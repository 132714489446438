import { IsoLocale, ITranslationApplication, ITranslationResponse } from '@fragus/sam-types'
import {
  deleteTranslationApplication,
  getTranslationApplications,
  getTranslationKeys,
  getTranslationsWithMissedValues,
  translationsExportUrl,
  translationsImport,
} from 'api/api'
import TranslationForm from 'pages/TranslationPage/Form/TranslationForm'
import TranslationsList from 'pages/TranslationPage/Lists/TranslationsList'
import React from 'react'
import SearchForm from './Form/SearchForm'
import './Translation.css'
import { Typography } from '@material-ui/core'
import { connect } from 'react-redux'
import Select, { ValueType } from 'react-select'
import { IRootState } from 'reducers/initialState'
import { selectIsUserDev } from 'selectors/user'
import { BaseOption } from 'types'
import { showError, showSuccess, showWarning } from 'utils/toastify'

interface IReducerProps {
  isDev: boolean
}

interface IState {
  translations: ITranslationResponse[]
  filteredTranslations: ITranslationResponse[]
  showCreationForm: boolean
  currentTranslation: ITranslationResponse | undefined
  applications: ITranslationApplication[]
  localeForImport: IsoLocale | undefined
}

const newLocalesForImport: IsoLocale[] = ['en']
const mapLocaleToOption = (locale: IsoLocale): BaseOption<IsoLocale> => ({ label: locale, value: locale })
const optionsForImport: BaseOption<IsoLocale>[] = newLocalesForImport.map((l) => mapLocaleToOption(l))

class TranslationPage extends React.Component<IReducerProps, IState> {
  constructor(props: IReducerProps) {
    super(props)

    this.state = {
      translations: [],
      applications: [],
      filteredTranslations: [],
      showCreationForm: false,
      currentTranslation: undefined,
      localeForImport: undefined,
    }
  }

  public componentDidMount() {
    this.fetchTranslationApplications()
    this.fetchTranslationKeys()
  }

  render() {
    const { filteredTranslations, showCreationForm, applications, currentTranslation, localeForImport } = this.state
    const { isDev } = this.props
    const disableImport = !optionsForImport.length && isDev

    return (
      <div className="Translations__inner">
        <header className="Translations__header">
          <div className="Translations__header-info">
            <h1 className="Translations__title">{showCreationForm ? 'Translation form' : 'Translations list'}</h1>
          </div>
        </header>
        {!showCreationForm && (
          <div className="Translations__navigation">
            <div className="Translations__import-export-container">
              {isDev &&
                (disableImport ? (
                  <Typography variant="h6" className="Translations__import-title">
                    There are no locales for import
                  </Typography>
                ) : (
                  <Select
                    className="Translations__import-locale-select"
                    options={optionsForImport}
                    placeholder="Import locale"
                    value={localeForImport && mapLocaleToOption(localeForImport)}
                    onChange={this.handleSelectChange}
                    clearable={false}
                  />
                ))}
              <button className="Translations__exportbutton" onClick={this.downloadTranslationsClick}>
                Export translations
              </button>
              {isDev && (
                <label
                  htmlFor="translations-import"
                  className={`Translations__importbutton ${
                    disableImport || !localeForImport ? 'Translations__disabled' : ''
                  }`}
                >
                  Import translations
                  <input
                    id="translations-import"
                    hidden={true}
                    accept={'.xlsx'}
                    onChange={this.handleFileChange}
                    type="file"
                    disabled={disableImport || !localeForImport}
                  />
                </label>
              )}
            </div>
            <div className="Translations__management-container">
              <button className="Translations__createbutton" onClick={this.handleCreate}>
                Create translation key
              </button>
              <button className="Translations__checkbutton" onClick={this.handleCheckMissed}>
                Check missed values
              </button>
            </div>
            <SearchForm onSuccess={this.handleSearch} handleEmptyValue={this.fetchTranslationKeys} />
          </div>
        )}
        <TranslationsList
          translations={filteredTranslations}
          show={!showCreationForm}
          onEdit={this.handleEdit}
          onDelete={this.handleDelete}
        />
        {showCreationForm && (
          <>
            <button className="Translations__backbutton" onClick={this.handleFormReset}>
              Back to list
            </button>
            <button className="Translations__createbutton" onClick={this.handleCreate}>
              Create new
            </button>
            <TranslationForm
              translation={currentTranslation}
              applications={applications}
              updateTranslationsList={this.handleTranslationsUpdate}
              onReset={this.handleFormReset}
            />
          </>
        )}
      </div>
    )
  }

  private handleDelete = async (translation: ITranslationResponse) => {
    const { statusCode } = await deleteTranslationApplication(translation.id)

    if (statusCode === 200) {
      this.setState(({ translations, filteredTranslations }) => ({
        filteredTranslations: filteredTranslations.filter((t) => t.id !== translation.id),
        translations: translations.filter((t) => t.id !== translation.id),
      }))
      showSuccess(`Translation "${translation.key}" DELETED!`)
    } else {
      showWarning('Cannot delete translation')
    }
  }

  private handleSelectChange = (value: ValueType<BaseOption, any>) => {
    const locale = value && ((value as BaseOption).value as IsoLocale)
    this.setState({ localeForImport: locale || undefined })
  }

  private downloadTranslationsClick = async () => {
    var a = document.createElement('a')
    a.href = translationsExportUrl()
    a.click()
  }

  private handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files
    const { localeForImport } = this.state

    if (fileList && fileList.length === 1 && localeForImport) {
      const formData = new FormData()
      const file: File = fileList[0]

      formData.append('translations', file)

      try {
        const response = await translationsImport(localeForImport, formData)

        if (response.status === 200) {
          const numberOfValues = await response.json()

          showSuccess(`Imported ${numberOfValues} translations`)
        } else {
          const error = await response.json()
          showError(`An error occured: ${error.message}`)
        }
      } catch (error) {
        //showError(`An error occured: ${(error as Error).message}`)
        showError((error as Error).toString())
      }
    }
  }

  handleTranslationsUpdate = (translation: ITranslationResponse) =>
    this.setState(({ translations, currentTranslation }) => {
      const newTranslations = currentTranslation
        ? translations.map((t) => (t.id === translation.id ? translation : t))
        : [translation, ...translations]

      return {
        translations: newTranslations,
        filteredTranslations: newTranslations,
        currentTranslation: translation,
      }
    })

  handleCheckMissed = async () => {
    const { statusCode, data } = await getTranslationsWithMissedValues()
    const translations = (statusCode === 200 && data) || []

    if (translations.length) {
      showWarning(`There are missed translations for ${translations.length} keys. The list is in the table`)
      this.setState({ translations, filteredTranslations: translations })
    } else {
      await this.fetchTranslationKeys()
      showSuccess('There is no missed translations')
    }
  }

  handleEdit = (translation: ITranslationResponse) =>
    this.setState({
      currentTranslation: translation,
      showCreationForm: true,
    })

  handleFormReset = async () =>
    this.setState({
      showCreationForm: false,
      currentTranslation: undefined,
    })

  handleCreate = () => this.setState({ showCreationForm: true, currentTranslation: undefined })

  handleSearch = (translations: ITranslationResponse[]) =>
    this.setState({ translations, filteredTranslations: translations })

  fetchTranslationKeys = async () => {
    const { statusCode, data } = await getTranslationKeys()
    const translations = (statusCode === 200 && data) || []
    this.setState({ translations, filteredTranslations: translations })
  }

  fetchTranslationApplications = async () => {
    const { statusCode, data } = await getTranslationApplications()
    const applications = (statusCode === 200 && data) || []

    this.setState({ applications })
  }
}
const mapStateToProps = (state: IRootState) => ({
  isDev: selectIsUserDev(state),
})

export default connect(mapStateToProps)(TranslationPage)

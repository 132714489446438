import {
  IContractOptionResponse,
  IContractProviderResponse,
  IGenericContractTemplateResponse,
  IProductContractTemplateResponse,
} from '@fragus/sam-types'
import equal from 'fast-deep-equal'
import { Form } from 'formik'
import React from 'react'
import { connect, Dispatch } from 'react-redux'
import ActionTypes from '../../../actions/ActionTypes'
import {
  IProviderPageRouteUpdate,
  IProviderPageTemplateDetailsReset,
  optionListGet,
  providerPageRouteUpdate,
  templateDetailsGet,
  templateDetailsReset,
  templateListGet,
  templatePatch,
  templatePost,
} from '../../../actions/providerPageActions'
import { IApiErrors } from '../../../apiModels/apiModel'
import { IContractOptionRequestGet } from '../../../apiModels/contractOption'
import {
  contractTemplateModel,
  contractTemplateValueDefaults,
  IContractTemplateRequestGet,
  IGenericContractTemplateRequestPatch,
  IGenericContractTemplateRequestPost,
  productContractTemplateValueDefaults,
} from '../../../apiModels/contractTemplate'
import ActionButton from '../../../components/ActionButton/ActionButton'
import AlertDialog from '../../../components/Dialog/AlertDialog'
import ContractTemplate from '../../../components/Form/ContractTemplate/ContractTemplate'
import ProductContractTemplate from '../../../components/Form/ProductContractTemplate/ProductContractTemplate'
import Omnik from '../../../components/Omnik/Omnik'
import { IRootState } from '../../../reducers/initialState'
import { getScroll, IScroll } from '../../../utils/getScroll'
import './PPTemplateDetails.css'
import { autoexpertenContractTemplateModel } from 'apiModels/autoexpertenContractTemplate'
import scrollDelta from '../scrollDelta'

interface IProps {
  create?: boolean
  product?: boolean
}

interface IReducerProps {
  optionList: IContractOptionResponse[]
  providerId: number
  templateDetails: IGenericContractTemplateResponse
  templateId: number
  providerDetails: IContractProviderResponse
  isActiveOutage: boolean
}

interface IActionProps {
  optionListGet: (request: IContractOptionRequestGet) => Promise<void>
  providerPageRouteUpdate: (route: string) => IProviderPageRouteUpdate
  templateDetailsGet: (request: IContractTemplateRequestGet) => Promise<void>
  templateDetailsReset: () => IProviderPageTemplateDetailsReset
  templatePatch: (request: IGenericContractTemplateRequestPatch) => Promise<void>
  templatePost: (request: IGenericContractTemplateRequestPost) => Promise<void>
  templateListGet: (request: IContractTemplateRequestGet) => Promise<void>
}

interface IState {
  edit: boolean
  archive: boolean
  templateDetails: IGenericContractTemplateResponse
  scroll: IScroll
  dirty?: boolean
}

const standardModel = contractTemplateModel()
const autoexpertenModel = autoexpertenContractTemplateModel()

// tslint:disable-next-line interface-name Because of a lint error we need to investigate
class PPTemplateDetails extends React.Component<IProps & IReducerProps & IActionProps, IState> {
  constructor(props: IProps & IReducerProps & IActionProps) {
    super(props)

    const { create, templateDetails, providerId } = props
    const edit = create ? true : false

    const defaultValues = this.decideDefaultValues()

    const currentTemplateDetails = create ? defaultValues : templateDetails

    // We need to attach the contractProviderId value when we are creating a new record
    if (create) {
      currentTemplateDetails.providerId = providerId
    }

    this.state = {
      edit,
      templateDetails: currentTemplateDetails,
      scroll: { x: 0, y: 0 },
      archive: false,
    }
  }

  public async componentDidMount() {
    const { templateId, providerId, templateDetailsGet, optionListGet, create } = this.props

    if (templateId && providerId && !create) {
      templateDetailsGet({ id: templateId })
      optionListGet({
        contractProviderId: providerId,
      })
    }

    document.addEventListener('scroll', this.handleScroll)
    this.handleScroll()
  }

  public componentWillUnmount() {
    document.removeEventListener('scroll', this.handleScroll)
  }

  static getDerivedStateFromProps(nextProps: IProps & IReducerProps & IActionProps, prevState: IState) {
    const { create, templateDetails } = nextProps

    let state = null

    if (!create && !equal(templateDetails, prevState.templateDetails)) {
      state = {
        edit: false,
        templateDetails,
      }
    }

    return state
  }

  // tslint:disable jsx-no-lambda
  public render() {
    const self = this
    const { handleEdit, handleFormSubmit, handleFormReset, handleOpenAlertDialog, handleTemplateTypeChange } = this
    const templateDetails = this.initValues(this.state.templateDetails)
    const isChildProvider = this.props.providerDetails.parentProviderId
    const { product, providerDetails, isActiveOutage } = this.props

    return templateDetails != null ? (
      <>
        <Omnik
          initialValues={templateDetails}
          onSubmit={handleFormSubmit}
          onReset={handleFormReset}
          validate={(values) => {
            let errors: IApiErrors | {} = {}

            let validationModel: any

            switch (values.priceSource) {
              case 'Autoexperten':
                validationModel = autoexpertenModel
                break
              default:
                validationModel = standardModel
                break
            }

            if (validationModel) {
              errors = validationModel.getErrors(values)
            }

            return errors
          }}
        >
          {({
            dirty,
            errors,
            handleBlur,
            handleChange,
            handleReset,
            handleSubmit,
            isValid,
            values,
            setValues,
            setFieldValue,
          }) => {
            const { edit, scroll } = self.state
            const { optionList, create } = self.props
            const title = self.state.templateDetails.name

            dirty = dirty || !!this.state.dirty // Use the dirty from this component or from the state.

            return (
              <React.Fragment>
                <Form className="PPTemplateDetails" onSubmit={handleSubmit} onReset={handleReset}>
                  <header
                    className={`PPTemplateDetails__header ${scroll.y > scrollDelta ? 'PPT..sticky' : ''}
                    ${isActiveOutage && scroll.y > scrollDelta ? 'PPTemplateDetails__header-padding-top' : ''}`}
                  >
                    <div className="PPTemplateDetails__info">
                      <h1 className="PPTemplateDetails__title">{title}</h1>
                    </div>
                    {!isChildProvider && (
                      <div className="PPTemplateDetails__actions">
                        {!edit && !create ? (
                          <>
                            <ActionButton
                              className="PPTemplateDetails__deletebutton"
                              onClick={handleOpenAlertDialog}
                              text="Delete"
                            />
                            <ActionButton className="PPTemplateDetails__editbutton" onClick={handleEdit} text="Edit" />
                          </>
                        ) : (
                          <>
                            <button className="PPTemplateDetails__resetbutton" type="reset">
                              Cancel
                            </button>
                            <button
                              className="PPTemplateDetails__submitbutton"
                              disabled={!dirty || !isValid}
                              type="submit"
                            >
                              Save
                            </button>
                          </>
                        )}
                      </div>
                    )}
                  </header>
                  {(providerDetails.productsEnabled || !this.props.create) &&
                  (product || 'defaultHours' in templateDetails) ? (
                    <main className="PPTemplateDetails__content">
                      <ProductContractTemplate
                        edit={edit}
                        errors={errors}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values as IProductContractTemplateResponse}
                        options={optionList}
                        templateDetails={templateDetails as IProductContractTemplateResponse}
                      />
                    </main>
                  ) : (
                    <main className="PPTemplateDetails__content">
                      <ContractTemplate
                        edit={edit}
                        errors={errors}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        onChangeTemplateType={(newValues: IGenericContractTemplateResponse) =>
                          handleTemplateTypeChange(newValues, values.name, setFieldValue, setValues)
                        }
                        value={values}
                        options={optionList}
                        templateDetails={templateDetails}
                        currency={providerDetails.currency}
                        vatProcentage={providerDetails.country.vatPct}
                      />
                    </main>
                  )}
                </Form>
              </React.Fragment>
            )
          }}
        </Omnik>
        <AlertDialog
          open={this.state.archive}
          onCancel={this.handleCloseAlertDialog}
          onOk={this.handleDelete}
          titleText="Archive Contract Template?"
          mainText="Are you sure you want to delete this template?"
          okText="Archive"
          cancelText="Close"
        />
      </>
    ) : (
      <div />
    )
  }

  private decideDefaultValues = () => {
    let defaultValues: IGenericContractTemplateResponse = contractTemplateValueDefaults

    if (this.props.product) {
      defaultValues = productContractTemplateValueDefaults
    }

    return defaultValues
  }

  private handleTemplateTypeChange = (
    newInitialValues: IGenericContractTemplateResponse,
    name: string,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
    setValues: (values: IGenericContractTemplateResponse, shouldValidate?: boolean | undefined) => void,
  ) => {
    setFieldValue(name, newInitialValues, false)
    setValues(newInitialValues, false)

    this.setState({ dirty: true })
  }

  // Since field with images and files has a path we need to scrape
  // the path off
  // This is really bad practise!!
  private initValues = (initialValues: IGenericContractTemplateResponse) => {
    let values = initialValues
    if (initialValues) {
      values = { ...initialValues }

      // Clean the image and terms of service paths
      const image = initialValues.image.split('/').pop() || ''
      const ref = initialValues.termsOfService.ref.split('/').pop() || ''

      values.image = image
      values.termsOfService.ref = ref
    }

    return values
  }

  private handleEdit = () => {
    const { edit } = this.state
    this.setState({ edit: !edit })
  }

  private handleFormReset = () => {
    const { create, providerPageRouteUpdate } = this.props

    if (create) {
      providerPageRouteUpdate('template')
    } else {
      this.setState({ edit: false })
    }
  }

  private handleFormSubmit = (values: IGenericContractTemplateResponse, { resetForm, encodeValues }: any) => {
    const { create } = this.props
    const edit = create ? true : !this.state.edit

    //issue with decoding minimumPaymentsCount to null
    const minimumPaymentsCount = typeof values.minimumPaymentsCount === 'string' ? null : values.minimumPaymentsCount
    const updatedValues = { ...values, minimumPaymentsCount }

    this.setState({ edit })

    if (create) {
      this.props.templatePost(updatedValues)
    } else {
      this.props.templatePatch(updatedValues)
      const newValues = encodeValues(updatedValues)
      resetForm({ values: newValues })
    }

    // @TODO Make the actions above async so we can await - and get outcome
  }

  private handleScroll = () => {
    const scroll = getScroll()

    this.setState({ scroll })
  }

  private handleCloseAlertDialog = () => {
    this.setState({ archive: false })
  }

  private handleOpenAlertDialog = () => {
    this.setState({ archive: true })
  }

  private handleDelete = async () => {
    const { templateDetails } = this.state
    const { providerId } = this.props

    await this.props.templatePatch({
      ...this.initValues(templateDetails),
      archived: true,
    })

    await this.props.templateListGet({ providerId })

    this.props.providerPageRouteUpdate('template')
  }
}

const mapStateToProps = (state: IRootState) => ({
  optionList: state.providerPage.optionList,
  providerId: state.providerPage.providerId,
  templateDetails: state.providerPage.templateDetails,
  templateId: state.providerPage.templateId,
  providerDetails: state.providerPage.providerDetails,
  isActiveOutage: !!state.outage,
})

const mapDispatchToProps = (dispatch: Dispatch<ActionTypes>) => ({
  optionListGet: (request: IContractOptionRequestGet) => dispatch(optionListGet(request)),
  providerPageRouteUpdate: (route: string) => dispatch(providerPageRouteUpdate(route)),
  templateDetailsGet: (request: IContractTemplateRequestGet) => dispatch(templateDetailsGet(request)),
  templateDetailsReset: () => dispatch(templateDetailsReset()),
  templatePatch: (request: IGenericContractTemplateRequestPatch) => dispatch(templatePatch(request)),
  templatePost: (request: IGenericContractTemplateRequestPost) => dispatch(templatePost(request)),
  templateListGet: (request: IContractTemplateRequestGet) => dispatch(templateListGet(request)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PPTemplateDetails)

import React from 'react'
import BooleanField from 'components/Field/BooleanField/BooleanField'
import { ITranslationApplication } from '@fragus/sam-types'

interface IProps {
  applications: ITranslationApplication[]
  currentApplications: ITranslationApplication[]
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const ApplicationSelector: React.SFC<IProps> = (props: IProps) => {
  const { applications, currentApplications, onChange } = props
  return (
    <div className="Translations__applications">
      {applications.map((app) => (
        <BooleanField
          className="TranslationApplication"
          name={app.name}
          onChange={onChange}
          title={app.name}
          key={app.name}
          value={currentApplications.some((ca) => ca.id === app.id)}
        />
      ))}
    </div>
  )
}

export default ApplicationSelector

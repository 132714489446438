import { Config } from '../config'
import {
  TIsoCountry,
  IStripeResponse,
  StripeCurrencyType,
  StripeCountryType,
  StripeEntityType,
  StripeGenderType,
  ICountryResponse,
} from '@fragus/sam-types'
import IApiModel, { IApiField, createModel } from './apiModel'
import { stripeDeclineChargeOnDefaults, stripeDeclineChargeOnModel } from './stripeDeclineChargeOn'
import { stripeExternalAccountModel } from './stripeExternalAccount'
import { stripeLegalEntityDefaults, stripeLegalEntityModel } from './stripeLegalEntity'
import { stripeVerificationDefaults, stripeVerificationModel } from './stripeVerification'
import { requiredString } from './validations'
import { BaseOption as Option } from 'types'

const t = (s: string) => s

export interface IStripeRequestGet {
  contractProviderId: number
}

// Default Values
export const stripeDefaults: IStripeResponse = {
  contractProviderId: -1,
  primaryColor: '',
  icon: { id: '', fileName: '' },
  logo: { id: '', fileName: '' },
  businessName: '',
  businessUrl: '',
  debitNegativeBalances: true,
  declineChargeOn: stripeDeclineChargeOnDefaults,
  defaultCurrency: 'dkk',
  externalAccounts: [],
  legalEntity: stripeLegalEntityDefaults,
  payoutStatementDescriptor: '',
  statementDescriptor: '', // Max 22 characters!!
  supportEmail: '',
  supportPhone: '',
  supportUrl: '',
  verification: stripeVerificationDefaults,
}

/**
 * @returns Return only UI-options for supported countries in SuperAdmin (Config file in "src/config").
 */
export const toCountryOptions = (countries: ICountryResponse[]): null | Option<StripeCountryType>[] => {
  const options: Option<StripeCountryType>[] = []

  countries.forEach((country: ICountryResponse) => {
    const countryName: string = '' + country.countryName
    const isoName: TIsoCountry = country.isoName as TIsoCountry

    if (Config.supportedCountries.includes(isoName)) {
      options.push({ label: t(countryName), value: isoName })
    }
  })

  return options
}

export const entityOptions: Option<StripeEntityType>[] = [
  {
    label: 'Company',
    value: 'company',
  },
  {
    label: 'Individual',
    value: 'individual',
  },
]

// @TODO: Refactor this method and related code (in ExternalAccount etc) to work in
// similar manner as to toCountryOptions(..) and related code so that the data is
// fetched from the database table country, and that this functoin is using the local
// config file. /ms
export const currencyOptions: Option<StripeCurrencyType>[] = [
  {
    label: 'Danish Kroner',
    value: 'dkk',
  },
  {
    label: 'Swedish Kroner',
    value: 'sek',
  },
  {
    label: 'Euro',
    value: 'eur',
  },
  {
    label: 'Norwegian krone',
    value: 'nok',
  },
]

export const genderOptions: Option<StripeGenderType>[] = [
  {
    label: 'Male',
    value: 'male',
  },
  {
    label: 'Female',
    value: 'female',
  },
]

// API Model
const model: IApiModel = {
  name: 'countryModel',
  fields: [
    {
      name: 'businessName',
      uiType: 'string',
      title: 'Business Name',
    },
    {
      name: 'primaryColor',
      uiType: 'string',
      title: 'Primary Color',
    },
    {
      name: 'icon',
      uiType: 'image',
      title: 'Icon',
    },
    {
      name: 'logo',
      uiType: 'image',
      title: 'Logo',
    },
    {
      name: 'businessUrl',
      uiType: 'string',
      title: 'Business URL',
    },
    {
      name: 'debitNegativeBalances',
      uiType: 'boolean',
      title: 'Debit Negative Balances',
    },
    {
      name: 'declineChargeOn',
      uiType: 'string',
      title: 'Decline Charge On',
      relation: 'StripeDeclineChargeOn',
    },
    {
      name: 'defaultCurrency',
      uiType: 'string',
      title: 'Default Currency',
    },
    {
      name: 'externalAccounts',
      uiType: 'string',
      title: 'External Account',
      relation: 'StripeExternalAccount',
    },
    {
      name: 'legalEntity',
      uiType: 'string',
      title: 'Legal Entity',
      relation: 'StripeLegalEntity',
    },
    {
      name: 'payoutStatementDescriptor',
      uiType: 'string',
      title: 'Payout Statement Descriptor',
      validation: [requiredString],
    },
    {
      name: 'statementDescriptor',
      uiType: 'string',
      title: 'Statement Descriptor',
      validation: [requiredString],
    },
    {
      name: 'supportEmail',
      uiType: 'string',
      title: 'Support E-mail',
    },
    {
      name: 'supportPhone',
      uiType: 'string',
      title: 'Support Phone',
    },
    {
      name: 'supportUrl',
      uiType: 'string',
      title: 'Support URL',
    },
    {
      name: 'verfication',
      uiType: 'string',
      title: 'Stripe Verification',
      relation: 'StripeVerification',
    },
  ],
  view: {},
}

const _declineChargeOnModel = stripeDeclineChargeOnModel()
const _legalEntityModel = stripeLegalEntityModel()
const _externalAccountModel = stripeExternalAccountModel()
const _stripeVerificationModel = stripeVerificationModel()

export const stripeModel = (view?: string | IApiField[], viewContext?: string) =>
  createModel<IStripeResponse>(view, viewContext, model, stripeDefaults, {
    StripeDeclineChargeOn: { name: 'StripeDeclineChargeOn', model: _declineChargeOnModel },
    StripeLegalEntity: { name: 'StripeLegalEntity', model: _legalEntityModel },
    StripeExternalAccount: { name: 'StripeExternalAccount', model: _externalAccountModel },
    StripeVerification: { name: 'StripeVerification', model: _stripeVerificationModel },
  })

import React from 'react'
import { TApiResponseType } from 'api/api'
import { IReleaseNotesResponse, IReleaseVersionResponse } from '@fragus/sam-types'
import './ReleaseVersions.css'

type TVersionType = IReleaseNotesResponse | IReleaseVersionResponse

export interface IWithReleaseVersionsProps<T> {
  versions: T[]
  updateList?: (version: IReleaseVersionResponse, isNew?: boolean) => void
  showLoadMore?: (show: boolean) => void
}

interface IState<T> {
  releaseVersions: T[]
  disableLoadMore: boolean
  showLoadMore: boolean
}

export const withReleaseVersions = <T extends TVersionType>(
  Component: React.ComponentType<IWithReleaseVersionsProps<T>>,
  fetchVersions: (offset: number) => Promise<TApiResponseType<T[]>>,
) =>
  class WithReleaseVersions extends React.Component<{}, IState<T>> {
    constructor(props: {}) {
      super(props)
      this.state = { releaseVersions: [], disableLoadMore: false, showLoadMore: true }
    }

    public componentDidMount() {
      this.fetchReleaseVersions()
    }

    render() {
      const { releaseVersions, disableLoadMore, showLoadMore } = this.state

      return (
        <>
          <Component versions={releaseVersions} updateList={this.updateList} showLoadMore={this.showLoadMore} />
          {showLoadMore && (
            <div className="ReleaseVersions__buttonwrapper">
              <button className="ReleaseVersions__loadbutton" onClick={this.handleLoadMore} disabled={disableLoadMore}>
                <div className="ReleaseVersions__buttoncontent">
                  <span>View more</span>
                  <span className="ReleaseVersions__downarrow"> </span>
                </div>
              </button>
            </div>
          )}
        </>
      )
    }

    handleLoadMore = () => this.fetchReleaseVersions()

    fetchReleaseVersions = async () => {
      const { statusCode, data } = await fetchVersions(this.state.releaseVersions.length)

      this.setState(({ releaseVersions }) => {
        const versions = (statusCode === 200 && data) || []
        return { releaseVersions: [...releaseVersions, ...versions], disableLoadMore: !versions.length }
      })
    }

    updateList = (version: IReleaseVersionResponse, isNew?: boolean) =>
      this.setState(({ releaseVersions }) => ({
        releaseVersions: isNew
          ? [...releaseVersions, version]
          : releaseVersions.map((v) => (v.releaseVersionId === version.releaseVersionId ? version : v)),
      }))

    showLoadMore = (showLoadMore: boolean) => this.setState({ showLoadMore })
  }

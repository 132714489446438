import React, { ChangeEvent } from 'react'
import { Field } from 'formik'
import { IStripeResponse } from '@fragus/sam-types'
import { IApiErrors } from '../../../apiModels/apiModel'
import { currencyOptions } from '../../../apiModels/stripe'
import BooleanField from '../../Field/BooleanField/BooleanField'
import EmailField from '../../Field/EmailField/EmailField'
import SelectField from '../../Field/SelectField/SelectField'
import StringField from '../../Field/StringField/StringField'
import FormSection from '../../FormSection/FormSection'
import DeclineChargeOn from './DeclineChargeOn/DeclineChargeOn'
import { StripeFileUploadField } from '../../Field/StripeFileUploadField/StripeFileUploadField'

interface IProps {
  edit?: boolean
  errors?: IApiErrors
  name?: string
  onBlur: (e: ChangeEvent<any>) => void
  onChange: (e: ChangeEvent<any>) => void
  title?: boolean | string
  value: IStripeResponse
}

// tslint:disable jsx-no-lambda
const Stripe: React.SFC<IProps> = ({ edit, errors = {}, name, title, value, onBlur, onChange }) => (
  <div className="Stripe">
    <FormSection
      name="general"
      classNamePrefix="Stripe"
      title={title ? (title === true ? 'General Information' : title) : 'General Information'}
    >
      <StringField
        className="Stripe__businessName"
        disabled={!edit}
        errors={errors.businessName}
        locked={!edit}
        name={name ? `${name}.businessName` : 'businessName'}
        onBlur={onBlur}
        onChange={onChange}
        size={55}
        subtitle="The publicly visible name of the business"
        title="Business Name"
        value={value.businessName}
      />
      <StringField
        className="Stripe__primaryColor"
        disabled={!edit}
        errors={errors.primaryColor}
        locked={!edit}
        name={name ? `${name}.primaryColor` : 'primaryColor'}
        onBlur={onBlur}
        onChange={onChange}
        size={55}
        subtitle="The color used for branding in Stripe checkout and onboarding"
        title={'Primary Color'}
        value={value.primaryColor || 'Enter Color here'}
      />
      <Field name={name ? `${name}.icon` : 'icon'} value={(value.icon && value.icon.id) || ''}>
        {({ field, form }: any) => (
          <StripeFileUploadField
            accept=".png, .jpg, .jpeg"
            className="Stripe__icon"
            contextParent={form}
            disabled={!edit}
            errors={errors.icon}
            locked={!edit}
            name={field.name}
            onBlur={onBlur}
            onChange={onChange}
            title="Icon"
            subtitle="The icon used by stripe"
            url={`contractproviders/${value.contractProviderId}/stripe/file/business_icon`}
            stripeFileObj={value.icon}
            value={field.value.icon}
          />
        )}
      </Field>
      <Field name={name ? `${name}.logo` : 'logo'} value={(value.logo && value.logo.id) || ''}>
        {({ field, form }: any) => (
          <StripeFileUploadField
            accept=".png, .jpg, .jpeg"
            className="Stripe__logo"
            contextParent={form}
            disabled={!edit}
            errors={errors.logo}
            locked={!edit}
            name={field.name}
            onBlur={onBlur}
            onChange={onChange}
            title="Logo"
            subtitle="The logo used by stripe"
            url={`contractproviders/${value.contractProviderId}/stripe/file/business_logo`}
            stripeFileObj={value.logo}
            value={field.value.logo}
          />
        )}
      </Field>
      <StringField
        className="Stripe__businessUrl"
        disabled={!edit}
        errors={errors.businessUrl}
        locked={!edit}
        name={name ? `${name}.businessUrl` : 'businessUrl'}
        onBlur={onBlur}
        onChange={onChange}
        size={55}
        subtitle="The publicly visible website of the business - Remember http(s)://"
        title="Business URL"
        value={value.businessUrl}
      />
      <StringField
        className="Stripe__supportUrl"
        disabled={!edit}
        errors={errors.supportUrl}
        locked={!edit}
        name={name ? `${name}.supportUrl` : 'supportUrl'}
        size={55}
        subtitle="Remember http(s)://"
        onBlur={onBlur}
        onChange={onChange}
        title="Support URL"
        value={value.supportUrl}
      />
      <EmailField
        className="Stripe__supportEmail"
        disabled={!edit}
        errors={errors.supportEmail}
        locked={!edit}
        name={name ? `${name}.supportEmail` : 'supportEmail'}
        onBlur={onBlur}
        onChange={onChange}
        subtitle="A publicly shareable support email address for the business"
        size={35}
        title="Support E-mail"
        value={value.supportEmail}
      />
      <StringField
        className="Stripe__supportPhone"
        disabled={!edit}
        errors={errors.supportPhone}
        locked={!edit}
        name={name ? `${name}.supportPhone` : 'supportPhone'}
        onBlur={onBlur}
        onChange={onChange}
        subtitle="A publicly shareable support phone number for the business"
        title="Support Phone Number"
        value={value.supportPhone}
      />
      <StringField
        className="Stripe__payoutStatementDescriptor"
        disabled={!edit}
        errors={errors.payoutStatementDescriptor}
        locked={!edit}
        name={name ? `${name}.payoutStatementDescriptor` : 'payoutStatementDescriptor'}
        onBlur={onBlur}
        onChange={onChange}
        maxLength={22}
        subtitle="The text that appears on the bank account statement for payouts"
        size={22}
        title="Payout Statement Descriptor"
        value={value.payoutStatementDescriptor}
      />
      <StringField
        className="Stripe__statementDescriptor"
        disabled={!edit}
        errors={errors.statementDescriptor}
        locked={!edit}
        name={name ? `${name}.statementDescriptor` : 'statementDescriptor'}
        onBlur={onBlur}
        onChange={onChange}
        maxLength={22}
        subtitle="The text that appears on the consumers bank account statement for subscription charges"
        size={22}
        title="Statement Descriptor"
        value={value.statementDescriptor}
      />
      <DeclineChargeOn
        edit={edit}
        errors={errors.declineChargeOn}
        name={name ? `${name}.declineChargeOn` : 'declineChargeOn'}
        onBlur={onBlur}
        onChange={onChange}
        title={true}
        value={value.declineChargeOn}
      />
      <BooleanField
        className="Stripe__debitNegativeBalances"
        disabled={!edit}
        errors={errors.debitNegativeBalances}
        locked={!edit}
        name={name ? `${name}.debitNegativeBalances` : 'debitNegativeBalances'}
        onBlur={onBlur}
        onChange={onChange}
        subtitle="Should stripe try to reclaim negative balances from an attached bank account."
        title="Debit Negative Balances"
        value={value.debitNegativeBalances}
      />
      <SelectField
        className="Stripe__defaultCurrency"
        disabled={!edit}
        defaultValue="dkk"
        errors={errors.defaultCurrency}
        locked={!edit}
        name={name ? `${name}.defaultCurrency` : 'defaultCurrency'}
        onBlur={onBlur}
        onChange={onChange}
        subtitle="The currency this account has chosen to use as the default."
        title="Default Currency"
        value={value.defaultCurrency}
        options={currencyOptions}
      />
    </FormSection>
  </div>
)

export default Stripe

import IApiModel, { IApiField, createModel } from './apiModel'
import { requiredNumber } from './validations'
import { IStripeDateOfBirth } from '@fragus/sam-types'

// Default Values
export const stripeDateOfBirthDefaults: IStripeDateOfBirth = {
  day: 1,
  month: 1,
  year: 1972,
}

// API Model
const model: IApiModel = {
  name: 'stripeDateOfBirthModel',
  fields: [
    {
      name: 'day',
      uiType: 'number',
      title: 'Day',
      validation: [requiredNumber],
    },
    {
      name: 'month',
      uiType: 'number',
      title: 'Month',
      validation: [requiredNumber],
    },
    {
      name: 'year',
      uiType: 'number',
      title: 'Year',
      validation: [requiredNumber],
    },
  ],
  view: {},
}

export const stripeDateOfBirthModel = (view?: string | IApiField[], viewContext?: string) =>
  createModel<IStripeDateOfBirth>(view, viewContext, model, stripeDateOfBirthDefaults)

import { IStripeExternalAccountResponse } from '@fragus/sam-types'
import IApiModel, { IApiField, createModel } from './apiModel'
import { requiredString } from './validations'

// Default Values
export const stripeExternalAccountDefaults: IStripeExternalAccountResponse = {
  id: '',
  accountHolderName: '',
  bankName: '',
  defaultForCurrency: true,
  last4: '',
}

// API Model
const model: IApiModel = {
  name: 'stripeExternalAccountModel',
  fields: [
    {
      name: 'id',
      uiType: 'string',
      title: 'External Account ID',
      validation: [requiredString],
    },
    {
      name: 'accountHolderName',
      uiType: 'string',
      title: 'Account holder name',
      validation: [requiredString],
    },
    {
      name: 'bankName',
      uiType: 'string',
      title: 'Bank Name',
      validation: [requiredString],
    },
    {
      name: 'defaultForCurrency',
      uiType: 'boolean',
      title: 'Default For Currency',
      validation: [requiredString],
    },
    {
      name: 'last4',
      uiType: 'string',
      title: 'Last 4',
      validation: [requiredString],
    },
  ],
  view: {},
}

export const stripeExternalAccountModel = (view?: string | IApiField[], viewContext?: string) =>
  createModel<IStripeExternalAccountResponse>(view, viewContext, model, stripeExternalAccountDefaults)

import React from 'react'
import { releaseNoteValueDefaults } from 'apiModels/releaseNote'
import { getReleaseNotes, postReleaseNote, patchReleaseNote, deleteReleaseNote } from 'api/api'
import ReleaseNote from 'components/Form/ReleaseNote/ReleaseNote'
import { IReleaseNoteResponse, IReleaseDescription } from '@fragus/sam-types'
import { showWarning } from 'utils/toastify'

interface IProps {
  versionId: number
}

interface IState {
  releaseNotes: IReleaseNoteResponse[]
  // need for descriptions
  currentNotes: IReleaseNoteResponse[]
}

const stateKeys = ['currentNotes', 'releaseNotes'] as (keyof IState)[]

type TApiRequest = typeof postReleaseNote | typeof patchReleaseNote

const handleApiRequest = (requestApi: TApiRequest) => async (
  releaseNote: IReleaseNoteResponse,
  releaseVersionId: number,
): Promise<IReleaseNoteResponse | undefined> => {
  const { statusCode, data } = await requestApi({ ...releaseNote, releaseVersionId })
  return statusCode === 200 && data ? data : undefined
}

class ReleaseNotes extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = { releaseNotes: [], currentNotes: [] }
  }

  public componentDidMount() {
    this.fetchReleaseNotes()
  }

  render() {
    const { releaseNotes } = this.state
    const unsavedNoteExist = releaseNotes.some((note) => note.releaseNoteId <= 0)

    return (
      <div className="ReleaseNotes_notes-list">
        {releaseNotes.map((note) => (
          <ReleaseNote
            key={note.releaseNoteId}
            releaseNote={note}
            handleDescriptions={this.handleDescriptions(note.releaseNoteId)}
            onDelete={this.handleDelete}
            onSubmit={this.handleSubmit}
            descriptions={this.getDescriptions(note.releaseNoteId) || []}
          />
        ))}
        <div className="ReleaseNotes__createbutton-container">
          <button className="ReleaseNotes__createbutton" onClick={this.handleAddNew} disabled={unsavedNoteExist}>
            Add New Release Note
          </button>
        </div>
      </div>
    )
  }

  handleAddNew = () =>
    this.setState((state) =>
      stateKeys.reduce(
        (acc, key) => ({
          ...acc,
          [key]: [...state[key], releaseNoteValueDefaults(this.props.versionId)],
        }),
        {} as IState,
      ),
    )

  fetchReleaseNotes = async () => {
    const { statusCode, data } = await getReleaseNotes(this.props.versionId)
    const releaseNotes = (statusCode === 200 && data) || []

    this.setState({
      releaseNotes,
      currentNotes: releaseNotes,
    })
  }

  handleDescriptions = (releaseNoteId: number) => (descriptions: IReleaseDescription[]) =>
    this.setState(({ currentNotes }) => ({
      currentNotes: currentNotes.map((note) =>
        note.releaseNoteId === releaseNoteId ? { ...note, descriptions } : note,
      ),
    }))

  updateState = (releaseNote: IReleaseNoteResponse, isNew: boolean) =>
    this.setState((state) =>
      stateKeys.reduce(
        (acc, key) => ({
          ...acc,
          [key]: isNew
            ? [...state[key], releaseNote].filter((i) => i.releaseNoteId > 0)
            : state[key].map((i) => (i.releaseNoteId === releaseNote.releaseNoteId ? releaseNote : i)),
        }),
        {} as IState,
      ),
    )

  removeFromState = (releaseNoteId: number): void =>
    this.setState((state) =>
      stateKeys.reduce(
        (acc, key) => ({
          ...acc,
          [key]: state[key].filter((i) => i.releaseNoteId !== releaseNoteId),
        }),
        {} as IState,
      ),
    )

  handleSubmit = async (releaseNote: IReleaseNoteResponse) => {
    const { currentNotes } = this.state
    const descriptions = currentNotes.find((d) => d.releaseNoteId === releaseNote.releaseNoteId)?.descriptions
    const enDescription = descriptions?.find((d) => d.locale === 'en-GB')
    const isNew = releaseNote.releaseNoteId <= 0

    if (!enDescription || (enDescription && enDescription.description.length <= 0)) {
      showWarning('en-GB description required')
    } else {
      isNew && delete releaseNote.releaseNoteId

      const apiFunction = isNew ? handleApiRequest(postReleaseNote) : handleApiRequest(patchReleaseNote)
      const newValues: IReleaseNoteResponse = { ...releaseNote, descriptions: descriptions! }
      const result = await apiFunction(newValues, this.props.versionId)

      result && this.updateState(result, isNew)
    }
  }

  handleDelete = async (releaseNoteId: number) => {
    const { statusCode } = await deleteReleaseNote(releaseNoteId)
    statusCode === 200 && this.removeFromState(releaseNoteId)
  }

  getDescriptions = (releaseNoteId: number) =>
    this.state.currentNotes.find((d) => d.releaseNoteId === releaseNoteId)?.descriptions
}
export default ReleaseNotes

import React, { KeyboardEvent } from 'react'
import StringField from 'components/Field/StringField/StringField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { searchTranslationKeys } from 'api/api'
import { ITranslationResponse } from '@fragus/sam-types'
import Checkbox from '@material-ui/core/Checkbox'

interface IProps {
  onSuccess: (translations: ITranslationResponse[]) => void
  handleEmptyValue: () => Promise<void>
}

interface IState {
  searchValue: string | undefined
  isSearchValuesToo: boolean
}

export default class SearchForm extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      searchValue: undefined,
      isSearchValuesToo: false,
    }
  }

  render() {
    const { searchValue } = this.state

    return (
      <div className="Translations__search">
        <div>
          <StringField
            className="Translations__searchfield"
            name="search"
            onChange={this.handleChange}
            onKeyDown={this.handleKeyPress}
            size={55}
            title="Search by Key:"
            value={searchValue}
          />
          <button className="Translations__searchbutton" onClick={this.handleSubmit}>
            Search
          </button>
        </div>
        <div className="Translations__search-options">
          <FormControlLabel
            value="isSearchValuesToo"
            control={
              <Checkbox disableRipple color="default" style={{ paddingRight: '6px' }} onClick={this.handleCheckbox} />
            }
            label="Search Values too"
            title="Search translation values as well"
            labelPlacement="end"
          />
        </div>
      </div>
    )
  }

  private handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    this.setState({ searchValue: e.target.value })

  private handleCheckbox = () => {
    this.setState({ isSearchValuesToo: !this.state.isSearchValuesToo })
  }

  private handleSubmit = async () => {
    const { handleEmptyValue, onSuccess } = this.props
    const { searchValue, isSearchValuesToo } = this.state

    if (!searchValue) {
      handleEmptyValue()
      return
    }

    const { statusCode, data } = await searchTranslationKeys(searchValue, isSearchValuesToo)
    const result = (statusCode === 200 && data) || undefined

    result && onSuccess(result)
  }

  private handleKeyPress = async (event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const KEY_CODE = { enter: 13 }

    if (event.charCode === KEY_CODE.enter) {
      await this.handleSubmit()
    }
  }
}

import React from 'react'
import FormSection from 'components/FormSection/FormSection'
import ReleaseDescription from 'components/Form/ReleaseDescription'
import { IReleaseNoteResponse, IReleaseDescription } from '@fragus/sam-types'
import { releaseTagOptions, releaseNoteModel } from 'apiModels/releaseNote'
import SelectField from 'components/Field/SelectField/SelectField'
import { isEqual } from 'lodash'
import { Form, Formik } from 'formik'
import { Divider } from '@material-ui/core'

interface IProps {
  releaseNote: IReleaseNoteResponse
  onSubmit: (releaseNote: IReleaseNoteResponse) => void
  onDelete: (releaseNoteId: number) => void
  handleDescriptions: (descriptions: IReleaseDescription[]) => void
  descriptions: IReleaseDescription[]
}

const descriptionsDirty = (
  releaseNote: IReleaseNoteResponse,
  currentDescriptions: IReleaseDescription[],
  isNew: boolean,
): boolean => {
  const createDirty = isNew && currentDescriptions.some((d) => d.description.length)
  const editDirty = !isEqual(releaseNote.descriptions, currentDescriptions)

  return createDirty || editDirty
}
const model = releaseNoteModel()

const ReleaseNote: React.SFC<IProps> = ({ handleDescriptions, descriptions, releaseNote, onSubmit, onDelete }) => (
  <div className="ReleaseNote" key={releaseNote.releaseNoteId}>
    <Divider />
    <div className="ReleaseNote-container" key={releaseNote.releaseNoteId + '-container'}>
      <Formik
        onSubmit={onSubmit}
        initialValues={releaseNote}
        enableReinitialize
        validate={(values) => model.getErrors(values)}
      >
        {({ dirty, isValid, handleBlur, handleChange, handleReset, handleSubmit, values }) => {
          const isNew = releaseNote.releaseNoteId <= 0
          const validDescriptions = descriptions.find((d) => d.description.length && d.locale === 'en-GB')
          const disableSubmit = !(
            isValid &&
            validDescriptions &&
            (dirty || descriptionsDirty(releaseNote, descriptions, isNew))
          )

          return (
            <>
              <Form className="ReleaseNotes" onSubmit={handleSubmit} onReset={handleReset}>
                <button className="ReleaseNotes__createbutton" disabled={disableSubmit} type="submit">
                  Save Release Note
                </button>
                {!isNew && (
                  <button
                    className="ReleaseNotes__deletebutton"
                    onClick={() => onDelete(releaseNote.releaseNoteId)}
                    type="button"
                  >
                    Delete Note
                  </button>
                )}
                <FormSection name="general" classNamePrefix="ReleaseNote">
                  <span title="Release System">
                    <SelectField
                      defaultValue={releaseTagOptions[0].value}
                      className="ReleaseNote__release-tag"
                      name="releaseTag"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      title="Release Tag"
                      placeholder="Select a tag"
                      options={releaseTagOptions}
                      value={values.releaseTag}
                    />
                  </span>
                </FormSection>
              </Form>
            </>
          )
        }}
      </Formik>
      <ReleaseDescription onChange={handleDescriptions} values={descriptions} />
    </div>
    <Divider />
  </div>
)

export default ReleaseNote

import React, { ChangeEvent, KeyboardEvent /*MouseEvent */ } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import { createDefaultContractProvider } from '../../api/api'
import store from '../../utils/store'
import { providerPageUpdate } from '../../actions/providerPageActions'
import { ICountryResponse } from '@fragus/sam-types'

const KeyCode = {
  Enter: 13,
  Cancel: 27,
}

interface IOwnProps {
  onClose: () => void
  open: boolean
  countryList: ICountryResponse[]
}

type TProps = IOwnProps

interface IState {
  PopupWaiting: boolean
  Value: string
  CountryCode: string
}

class CreateDefaultProviderDialog extends React.Component<TProps, IState> {
  private stateDefault: IState = {
    PopupWaiting: false,
    Value: '',
    CountryCode: this.props.countryList[0]?.isoName || '',
  }

  constructor(props: TProps) {
    super(props)
    this.state = { ...this.stateDefault }
  }

  public render() {
    const { open, onClose } = this.props
    const Value = this.state.Value.trim()
    const confirmEnabled = Value && this.state.CountryCode
    const { PopupWaiting } = this.state

    return (
      <Dialog style={{ visibility: 'visible' }} open={open} onClose={onClose} onKeyUp={this.handleKeyboard}>
        <DialogTitle>Create Provider with default values</DialogTitle>
        <DialogContent>{this.renderContent()}</DialogContent>
        <DialogActions>
          <label hidden={!PopupWaiting}>Creating provider, this might take several minutes...</label>
          <span hidden={PopupWaiting}>
            <Button disabled={PopupWaiting} onClick={this.handleCancel} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleConfirm} disabled={!confirmEnabled || PopupWaiting} color="primary">
              Create
            </Button>
          </span>
        </DialogActions>
      </Dialog>
    )
  }

  private renderContent = () => {
    const { countryList } = this.props
    const Value = this.state.Value.trim()
    return (
      <div>
        <select onChange={this.handleCountryChange} value={this.state.CountryCode}>
          {countryList.map((c) => (
            <option key={`country-option-${c.countryId}`} value={c.isoName}>
              {c.countryName || c.isoName}
            </option>
          ))}
        </select>
        <div>
          <input size={40} placeholder="Provider Name" value={Value} onChange={this.handleChangeText} />
        </div>
      </div>
    )
  }

  private handleCountryChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value as string
    this.setState({
      CountryCode: value,
    })
  }

  private handleChangeText = (event: ChangeEvent<HTMLInputElement>) => {
    const Value = (event.target && event.target.value) || ''
    this.setState({ Value })
  }

  private handleKeyboard = (event: KeyboardEvent<HTMLDivElement>) => {
    event.stopPropagation()
    event.preventDefault()
    switch (event.keyCode) {
      case KeyCode.Enter:
        return this.handleConfirm()
      case KeyCode.Cancel:
        return this.handleCancel()
    }
  }

  private handleConfirm = async (event?: any /*MouseEvent<HTMLElement>*/) => {
    event?.stopPropagation()
    event?.preventDefault()
    if (!this.state.Value.trim() || !this.state.CountryCode) return
    this.setState({ PopupWaiting: true })
    try {
      const req = {
        administrativeName: this.state.Value,
        countryIsoCode: this.state.CountryCode,
      }
      const response = await createDefaultContractProvider(req)
      if (response && response.data) {
        store.dispatch(
          providerPageUpdate({
            providerDetails: response.data,
            providerId: response.data.contractProviderId,
            route: 'provider-details',
          }),
        )
      }
    } finally {
      this.setState({
        PopupWaiting: false,
        Value: '',
      })
      this.props.onClose()
    }
  }

  private handleCancel = (event?: any /*MouseEvent<HTMLElement>*/) => {
    event?.stopPropagation()
    event?.preventDefault()
    this.props.onClose()
  }
}

export default CreateDefaultProviderDialog

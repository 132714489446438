import { IStripeLegalEntity } from '@fragus/sam-types'
import IApiModel, { IApiField, createModel } from './apiModel'
import { stripeAddressDefaults, stripeAddressModel } from './stripeAddress'
import { stripeDateOfBirthDefaults, stripeDateOfBirthModel } from './stripeDateOfBirth'
import { requiredString } from './validations'

// Default Values
export const stripeLegalEntityDefaults: IStripeLegalEntity = {
  type: 'company', // Either "individual" or "company", for what kind of legal entity the account owner is for
  dob: stripeDateOfBirthDefaults, // Date of Birth for primary owner
  firstName: '',
  lastName: '',
  gender: 'male',
  personalAddress: stripeAddressDefaults,
  address: stripeAddressDefaults,
  phoneNumber: '',
  additionalOwners: [],
}

// API Model
const model: IApiModel = {
  name: 'stripeLegalEntityModel',
  fields: [
    {
      name: 'type',
      uiType: 'string',
      title: 'Entity Type',
      validation: [requiredString],
    },
    {
      name: 'dob',
      uiType: 'string',
      title: 'Business Tax ID',
      relation: 'StripeDateOfBirth',
    },
    {
      name: 'firstName',
      uiType: 'string',
      title: 'First Name',
      validation: [requiredString],
    },
    {
      name: 'lastName',
      uiType: 'string',
      title: 'Last Name',
      validation: [requiredString],
    },
    {
      name: 'gender',
      uiType: 'string',
      title: 'Gender',
      validation: [requiredString],
    },
    {
      name: 'personalAddress',
      uiType: 'string',
      title: 'Personal Address',
      relation: 'StripeAddress',
    },
    {
      name: 'address',
      uiType: 'string',
      title: 'Address',
      relation: 'StripeAddress',
    },
    {
      name: 'phonenumber',
      uiType: 'string',
      title: 'Phone Nr.',
      validation: [requiredString],
    },
    {
      name: 'additionalOwners',
      uiType: 'string',
      title: 'Additional Owners',
    },
  ],
  view: {},
}

const dobModel = stripeDateOfBirthModel()
const addressModel = stripeAddressModel()

export const stripeLegalEntityModel = (view?: string | IApiField[], viewContext?: string) =>
  createModel<IStripeLegalEntity>(view, viewContext, model, stripeLegalEntityDefaults, {
    StripeDateOfBirth: { name: 'StripeDateOfBirth', model: dobModel },
    StripeAddress: { name: 'StripeAddress', model: addressModel },
  })

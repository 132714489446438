import IApiModel, { createModel, IApiField } from './apiModel'
import { requiredPrimaryKey, requiredNumber, requiredString } from './validations'
import { BaseOption } from 'types'
import { IReleaseVersionResponse } from '@fragus/sam-types'

export const model: IApiModel = {
  name: 'releaseVersion',
  fields: [
    {
      name: 'releaseVersionId',
      title: 'Version ID',
      uiType: 'number',
      validation: [requiredPrimaryKey],
    },
    {
      name: 'version',
      title: 'Version',
      uiType: 'string',
      validation: [requiredString],
    },
    {
      name: 'releaseSystem',
      title: 'Release System',
      uiType: 'number',
      validation: [requiredNumber],
    },
    {
      name: 'releaseDate',
      uiType: 'date',
      title: 'Release Date',
      validation: [
        {
          message: 'Required field',
          type: 'required',
          valid: ({ value }) => value !== undefined,
        },
      ],
    },
  ],
  view: {},
}

export const releaseVersionValueDefaults: IReleaseVersionResponse = {
  releaseVersionId: -1,
  version: '',
  releaseSystem: 1,
  releaseDate: new Date().toDateString(),
  published: false,
}

export const releaseVersionModel = (view?: string | IApiField[], viewContext?: string) =>
  createModel<IReleaseVersionResponse>(view, viewContext, model, releaseVersionValueDefaults, {})

export const releaseSystemOptions: BaseOption<number>[] = [
  { value: 1, label: 'Admin' },
  { value: 2, label: 'Superadmin' },
  { value: 3, label: 'API' },
  { value: 4, label: 'WebShop' },
]

import React, { ChangeEvent } from 'react'
import { IStripeDeclineChargeOn } from '@fragus/sam-types'
import { IApiErrors } from '../../../../apiModels/apiModel'
import BooleanField from '../../../Field/BooleanField/BooleanField'
import FormSection from '../../../FormSection/FormSection'

interface IProps {
  edit?: boolean
  errors?: IApiErrors
  name?: string
  onBlur: (e: ChangeEvent<any>) => void
  onChange: (e: ChangeEvent<any>) => void
  title?: boolean | string
  value: IStripeDeclineChargeOn
}

const DeclineChargeOn: React.SFC<IProps> = ({ edit, errors = {}, name, onBlur, onChange, title, value }) => (
  <div className="DeclineChargeOn">
    <FormSection
      name="general"
      classNamePrefix="DeclineChargeOn"
      title={title ? (title === true ? 'Decline Charge on ' : title) : ''}
    >
      <BooleanField
        className="DeclineChargeOn__avsFailure"
        disabled={!edit}
        errors={errors.avsFailure}
        locked={!edit}
        name={name ? `${name}.avsFailure` : 'avsFailure'}
        onBlur={onBlur}
        onChange={onChange}
        subtitle="Whether Stripe should automatically decline charges with an incorrect ZIP or postal code. This setting only applies when a ZIP or postal code is provided and the bank specifically marks it as failed."
        title="AVS Failure"
        value={value.avsFailure}
      />
      <BooleanField
        className="DeclineChargeOn__cvcFailure"
        disabled={!edit}
        errors={errors.cvcFailure}
        locked={!edit}
        name={name ? `${name}.cvcFailure` : 'cvcFailure'}
        onBlur={onBlur}
        onChange={onChange}
        subtitle="Whether Stripe should automatically decline charges with an incorrect CVC. This setting only applies when a CVC is provided and the bank specifically marks it as failed."
        title="CVC Failure"
        value={value.cvcFailure}
      />
    </FormSection>
  </div>
)

export default DeclineChargeOn

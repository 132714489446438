import { Form } from 'formik'
import React from 'react'
import { Dispatch, connect } from 'react-redux'
import {
  IStripeResponse,
  IStripeRequest,
  StripeVerificationReason,
  StripeVerificationField,
  IContractProviderResponse,
} from '@fragus/sam-types'
import ActionTypes from '../../../actions/ActionTypes'
import {
  IProviderPageRouteUpdate,
  IProviderPageStripeDetailsReset,
  IProviderPageStripeDetailsUpdate,
  providerPageRouteUpdate,
  stripeDetailsGet,
  stripeDetailsReset,
  stripeDetailsUpdate,
  stripePatch,
} from '../../../actions/providerPageActions'
import { IApiErrors } from '../../../apiModels/apiModel'
import { IStripeRequestGet, stripeModel } from '../../../apiModels/stripe'
import { getScroll, IScroll } from '../../../utils/getScroll'
import { stripeExternalAccountModel } from '../../../apiModels/stripeExternalAccount'
import ActionButton from '../../../components/ActionButton/ActionButton'
import DataList from '../../../components/DataList/DataList'
import Stripe from '../../../components/Form/Stripe/Stripe'
import Omnik from '../../../components/Omnik/Omnik'
import { IRootState } from '../../../reducers/initialState'
import './PPStripeDetails.css'
import scrollDelta from '../scrollDelta'

const reasonTypeMap: { [T in StripeVerificationReason]: string } = {
  'rejected.fraud': 'Rejected on suspicion of fraud',
  'rejected.terms_of_service': 'Rejected due to Terms of Service violation',
  'rejected.listed': 'Rejected due to reason listed',
  'rejected.other': 'Rejected for other reason',
  fields_needed: 'Fields are needed',
  listed: 'Listed',
  under_review: 'Under Review',
  other: 'Other',
}

const fieldTypeMap: { [T in StripeVerificationField]: string } = {
  business_url: 'Business URL is missing',
  external_account: 'No External Accounts created',
  'legal_entity.address.city': 'City in address for Legal Entity is missing',
  'legal_entity.address.line1': 'First line in address for Legal Entity is missing',
  'legal_entity.address.postal_code': 'Postal code in address for Legal Entity is missing',
  'legal_entity.address.state': 'State in address for Legal Entity is missing',
  'legal_entity.dob.day': 'Day in Date of Birth for Legal Entity is missing',
  'legal_entity.dob.month': 'Month in Date of Birth for Legal Entity is missing',
  'legal_entity.dob.year': 'Year in Date of Birth for Legal Entity is missing',
  'legal_entity.first_name': 'First Name for Legal Entity is missing',
  'legal_entity.last_name': 'Last Name for Legal Entity is missing',
  'legal_entity.type': 'Type for Legal Entity is missing',
  product_description: 'Product Description is missing',
  support_phone: 'Support Phone number is missing',
  'tos_acceptance.date': 'Terms of Service Acceptance Date is missing',
  'tos_acceptance.ip': 'Terms of Service Acceptance IP is missing',
}

interface IReducerProps {
  providerId: number
  stripeDetails: IStripeResponse
  providerDetails: IContractProviderResponse
  isActiveOutage: boolean
}

interface IActionProps {
  providerPageRouteUpdate: (route: string) => IProviderPageRouteUpdate
  stripeDetailsGet: (request: IStripeRequestGet) => Promise<void>
  stripeDetailsReset: () => IProviderPageStripeDetailsReset
  stripeDetailsUpdate: (details: IStripeResponse) => IProviderPageStripeDetailsUpdate
  stripePatch: (request: IStripeRequest) => Promise<void>
}

interface IState {
  edit: boolean
  scroll: IScroll
  providerId?: number
}

const model = stripeModel()
const externalAccountModel = stripeExternalAccountModel()

// tslint:disable-next-line interface-name Because of a lint error we need to investigate
class PPStripeDetails extends React.Component<IReducerProps & IActionProps, IState> {
  constructor(props: IReducerProps & IActionProps) {
    super(props)

    this.state = {
      edit: false,
      // stripeDetails: this.props.stripeDetails,
      // @TODO remove this hack when we can get actual data
      scroll: { x: 0, y: 0 },
    }
  }

  public componentDidMount() {
    const { providerId } = this.props

    if (providerId) {
      this.props.stripeDetailsGet({ contractProviderId: providerId })
    }

    document.addEventListener('scroll', this.handleScroll)

    this.handleScroll()
  }

  static getDerivedStateFromProps(nextProps: IReducerProps & IActionProps, prevState: IState) {
    const { providerId } = prevState

    let state = null

    if (!nextProps.providerId && nextProps.stripeDetails) {
      nextProps.stripeDetailsReset()
    } else if (nextProps.providerId !== providerId) {
      nextProps.stripeDetailsGet({ contractProviderId: nextProps.providerId })
      state = { edit: false, providerId: nextProps.providerId }
    }

    return state
  }

  public componentWillUnmount() {
    document.removeEventListener('scroll', this.handleScroll)
  }

  // tslint:disable jsx-no-lambda
  public render() {
    const self = this
    const { handleFormSubmit, handleEdit, handleFormReset } = this
    const { stripeDetails, isActiveOutage } = this.props
    const isChildProvider = this.props.providerDetails.parentProviderId

    return stripeDetails !== null ? (
      <Omnik
        initialValues={stripeDetails}
        onSubmit={handleFormSubmit}
        onReset={handleFormReset}
        validate={(values) => {
          let errors: IApiErrors | {} = {}

          if (model) {
            errors = model.getErrors(values)
          }

          return errors
        }}
      >
        {({ dirty, errors, handleBlur, handleChange, handleReset, handleSubmit, isValid, values }) => {
          values.contractProviderId = this.props.providerId

          const { edit, scroll } = self.state
          return (
            <Form className="PPStripeDetails" onSubmit={handleSubmit} onReset={handleReset}>
              <header
                className={`PPStripeDetails__header ${scroll.y > scrollDelta ? 'PPStripeDetails__header--sticky' : ''}
                  ${isActiveOutage ? 'PPStripeDetails__header-padding-top' : ''}`}
              >
                <div className="PPStripeDetails__info">
                  <h1 className="PPStripeDetails__title">Stripe Account Details</h1>
                </div>
                {!isChildProvider && (
                  <div className="PPStripeDetails__actions">
                    {!edit ? (
                      <>
                        <ActionButton className="PPStripeDetails__editbutton" onClick={handleEdit} text="Edit" />
                      </>
                    ) : (
                      <>
                        <button className="PPStripeDetails__resetbutton" type="reset">
                          Cancel
                        </button>
                        <button className="PPStripeDetails__submitbutton" disabled={!dirty || !isValid} type="submit">
                          Save
                        </button>
                      </>
                    )}
                  </div>
                )}
              </header>
              {stripeDetails && stripeDetails.verification && stripeDetails.verification.disabled_reason && (
                <section className="PPStripeDetails__notification">
                  <header className="PPStripeDetails__notification-header">
                    <h2 className="PPStripeDetails__notification-title">Stripe encoutered a problem</h2>
                    <h3 className="PPStripeDetails__notification-subtitle">
                      {reasonTypeMap[stripeDetails.verification.disabled_reason]}
                    </h3>
                  </header>
                  <main className="PPStripeDetails__notification-content">
                    <ul className="PPStripeDetails__notification-list">
                      {stripeDetails.verification.fields_needed &&
                        stripeDetails.verification.fields_needed.map((name) => {
                          return (
                            <li className="PPStripeDetails__notification-list-item" key={name}>
                              {fieldTypeMap[name]}
                            </li>
                          )
                        })}
                    </ul>
                  </main>
                </section>
              )}
              <main className="PPStripeDetails__content">
                <Stripe edit={edit} errors={errors} onBlur={handleBlur} onChange={handleChange} value={values} />
                <div className="PPStripeDetails__external-accounts">
                  <header className="PPStripeDetails__external-accounts-header">
                    <div className="PPStripeDetails__external-accounts-title">External Accounts</div>
                  </header>
                  <main className="PPStripeDetails__external-accounts-content">
                    {edit && (
                      <div className="PPStripeDetails__external-accounts-action">
                        <ActionButton
                          className="PPStripeDetails__external-accounts-createbutton"
                          onClick={this.handleCreateExternalAccount}
                          text="Add New External Account"
                        />
                      </div>
                    )}
                    <DataList primaryKey="id" data={stripeDetails.externalAccounts} model={externalAccountModel} />
                  </main>
                </div>
              </main>
            </Form>
          )
        }}
      </Omnik>
    ) : (
      <div />
    )
  }
  // tslint:enable jsx-no-lambda

  private handleEdit = () => {
    const { edit } = this.state

    this.setState({ edit: !edit })
  }

  private handleFormReset = () => {
    this.setState({ edit: false })
  }

  private handleFormSubmit = (values: IStripeResponse, { resetForm, encodeValues }: any) => {
    const { edit } = this.state

    const patchValues = { ...values, ...{ contractProviderId: this.props.providerId } }

    delete patchValues.externalAccounts

    this.setState({ edit: !edit })

    this.props.stripePatch(patchValues)

    // @TODO Make the actions above async so we can await - and get outcome

    const newValues = encodeValues(values)
    resetForm({ values: newValues })
  }

  private handleCreateExternalAccount = () => {
    this.props.providerPageRouteUpdate('external-account-create')
  }

  private handleScroll = () => {
    const scroll = getScroll()

    this.setState({ scroll })
  }
}

const mapStateToProps = (state: IRootState) => ({
  stripeDetails: state.providerPage.stripeDetails,
  providerId: state.providerPage.providerId,
  providerDetails: state.providerPage.providerDetails,
  isActiveOutage: !!state.outage,
})

const mapDispatchToProps = (dispatch: Dispatch<ActionTypes>) => ({
  stripeDetailsGet: (request: IStripeRequestGet) => dispatch(stripeDetailsGet(request)),
  stripeDetailsReset: () => dispatch(stripeDetailsReset()),
  stripeDetailsUpdate: (details: IStripeResponse) => dispatch(stripeDetailsUpdate(details)),
  stripePatch: (request: IStripeRequest) => dispatch(stripePatch(request)),
  providerPageRouteUpdate: (route: string) => dispatch(providerPageRouteUpdate(route)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PPStripeDetails)
